import http from './requestType';

/** Searches for a list of accounts that the user has access to.
 * @return {Promise} - Promise
 */
export function getAccountsList({
  page,
  size,
  search,
  searchByShortcodeOnly,
  sort,
  descending,
  filters,
  subscriptions,
  agents
}) {
  return http._get(`Accounts`, {
    page,
    size,
    search,
    shortcodeOnly: searchByShortcodeOnly,
    sort,
    descending,
    // cast query param as a string
    filters: filters.join(','),
    subscriptions,
    agents
  });
}

/** Create a new account
 * @param  {String} obj - the account object
 * @return {Promise} - Promise
 */
export function postNewAccount(obj) {
  return http._post(`Accounts`, obj);
}

/** Returns the Account associated with the accountKey
 * @param  {String} key - the Account key
 * @return {Promise} - Promise
 */
export function getAccount(key) {
  return http._get(`Accounts/${key}`, { includeIntegrations: true });
}

/** Updates the Account associated with the accountKey
 * @param  {String} key - the Account key
 * @param  {String} obj - the account object
 * @return {Promise} - Promise
 */
export function putAccount(key, obj) {
  return http._put(`Accounts/${key}`, obj);
}

/**
 * Get the list of active consents for this account
 *
 * @param  {String} key - the Account Key
 * @return {Promise}     - the response
 */
export function getAccountActiveConsents(key) {
  return http._get(`Accounts/${key}/consents`);
}

/**
 * Add consents or rejections an account
 *
 * @param  {String} key    - the Account Key
 * @param  {Array}  emails - the emails to mark as consented or rejected
 * @param  {String} status - consented or rejected
 * @param  {String} type   - how this came about
 * @return {Promise}       - the response
 */
export function postAccountActiveConsents(key, emails, status, type) {
  return http._post(`Accounts/${key}/consents`, { emails, status, type });
}

/**
 * Delete consents from an account
 *
 * @param  {String} key    - the Account Key
 * @param  {Array}  emails - the emails to delete
 * @return {Promise}       - the response
 */
export function deleteAccountConsents(key, emails) {
  return http._post(`Accounts/${key}/consents/delete`, { emails });
}

/**
 * Get the list of service agreements for this account
 *
 * @param  {String} key - the Account Key
 * @return {Promise}     - the response
 */
export function getAccountAgreements(key) {
  return http._get(`Accounts/${key}/agreements`);
}

/**
 * Gets the list of links to the user resources that have access to view this account’s campaigns
 *
 * @param  {String} key - the Account Key
 * @return {Promise}     - the response
 */
export function getAccountUsers(key) {
  return http._get(`Accounts/${key}/users`);
}

/**
 * Gets the list of agents associated with the accountKey
 *
 * @param  {String} key - the Account Key
 * @return {Promise}     - the response
 */
export function getAccountAgents(key) {
  return http._get(`Accounts/${key}/agents`);
}

/**
 * Deletes the agent account membership associated with accountkey
 *
 * @param  {String} agentKey    - the agent key
 * @param  {String} accountKey  - the account Key
 * @return {Promise}            - the response
 */
export function deleteAgentAccount(agentKey, accountKey) {
  return http._delete(`Accounts/${accountKey}/agents/${agentKey}`);
}

/**
 * Create an agent memership for an account
 *
 * @param  {Object} params
 * @param  {String} params.accountKey       - Account key
 * @param  {String} params.agentAccountList - List of agents to create a membership with
 * @return {Promise}     - the response
 */
export function createAgentAccount({ accountKey, agentAccountList }) {
  return http._post(`Accounts/${accountKey}/agents`, {
    accountKey,
    agentAccountList
  });
}

/**
 * Find a specific account user
 *
 * @param  {Object} params
 * @param  {String} params.key    - Account Key
 * @param  {String} params.email  - email
 * @return {Promise}              - the response
 */
export function getAccountUser({ key, email }) {
  return http._get(`Accounts/${key}/users/${email}`);
}

/**
 * Add a user to an account or update it
 *
 * @param  {Object} params
 * @param  {String} params.key          - Account Key
 * @param  {String} params.email        - email
 * @param  {String} [params.firstName]  - first name
 * @param  {String} [params.lastName]   - last name
 * @return {Promise}                    - the response
 */
export function addAccountUser({ key, email, firstName, lastName }) {
  return http._put(`Accounts/${key}/users/${email}`, { firstName, lastName });
}

/**
 * Remove user from an account
 *
 * @param  {Object} params
 * @param  {String} params.key    - Account Key
 * @param  {String} params.email  - email
 * @return {Promise}              - the response
 */
export function deleteAccountUser({ key, email }) {
  return http._delete(`Accounts/${key}/users/${email}`);
}

/**
 * Gets the list of campaigns belonging to this account
 *
 * @param  {String} key       - the Account Key
 * @param  {String} sortDir   - meeting date sort direction (eg. 'asc', 'desc')
 * @return {Promise}     - the response
 */
export function getAccountMeetings({ key, sortDir }) {
  return http._get(`Accounts/${key}/meetings`, { sortDir });
}

/**
 * Gets the list of preferred managers belonging to this account
 *
 * @param  {String} key  - the Account Key
 * @return {Promise}     - the response
 */
export function getAccountPreferredManagers(key) {
  return http._get(`Accounts/${key}/preferred-managers`);
}

/**
 * Add a preferred manager from this account
 *
 * @param  {String} accountKey  - the Account Key
 * @param  {String} userKey     - the User Key
 * @param  {String} userType    - manager type to add (CSM, VMM)
 * @return {Promise}            - the response
 */
export function addAccountPreferredManager({ accountKey, userKey, userType }) {
  return http._post(`Accounts/${accountKey}/preferred-managers`, {
    accountKey,
    userKey,
    userType
  });
}

/**
 * Remove a preferred manager from this account
 *
 * @param  {String} accountKey  - the Account Key
 * @param  {String} userType    - manager type to remove (CSM, VMM)
 * @return {Promise}            - the response
 */
export function deleteAccountPreferredManager({ accountKey, userType }) {
  return http._delete(`Accounts/${accountKey}/preferred-managers/${userType}`);
}

/**
 * Gets the list of unassigned checklists belonging to this account
 *
 * @param  {String} accountKey  - the Account Key
 * @return {Promise}            - the response
 */
export function getUnassignedChecklists({ accountKey }) {
  return http._get(`Accounts/${accountKey}/unassigned-checklists`);
}

/**
 * Gets the list of incomplete checklists belonging to this account
 *
 * @param  {String} accountKey  - the Account Key
 * @return {Promise}            - the response
 */
export function getIncompleteChecklists({ accountKey }) {
  return http._get(`Accounts/${accountKey}/incomplete-checklists`);
}

/**
 * Gets the subscription associated with the accountKey
 *
 * @param  {String}  accountKey - the Account Key
 * @param  {Boolean} clearCache - if true, upsert the cached subscription record (regardless of expiry time)
 * @return {Promise}            - the response
 */
export function getAccountSubscription(accountKey, clearCache) {
  return http._get(`Accounts/${accountKey}/subscriptions`, { clearCache });
}

/**
 * Gets the invoices associated with the accountKey
 *
 * @param  {String}  accountKey - the Account Key
 * @return {Promise}            - the response
 */
export function getAccountInvoices(accountKey) {
  return http._get(`Accounts/${accountKey}/invoices`);
}

/**
 * Update notes for an account
 *
 * @param  {Object}   params
 * @param  {String}   params.key       - Account key
 * @param  {Object[]} params.notes            - notes
 * @return {Promise}                          - the response
 */
export function updateAccountNotes({ key, notes }) {
  return http._put(`Accounts/${key}/notes/`, notes);
}

/**
 * Add the subscription proof
 *
 * @param  {String} accountKey        - the Account Key
 * @param  {Object} subscriptionProof - the subscription proof
 * @return {Promise}                  - the response
 */
export function addAccountSubscriptionProof(accountKey, subscriptionProof) {
  return http._post(`Accounts/${accountKey}/subscriptions/proof`, {
    subscriptionProof
  });
}

/**
 * Gets the subscription usage summary
 *
 * @param  {String}  accountKey - the Account Key
 * @return {Promise}            - the response
 */
export function getAccountSubscriptionUsageSummary(accountKey) {
  return http._get(`Accounts/${accountKey}/subscriptions/summary`);
}

/**
 * Retrieves the account's meetings by subscription period.
 *
 * @param {Object} options - The options for retrieving the account meeting.
 * @param {string} options.accountKey - The account key.
 * @param {string} options.type - The meeting type.
 * @param {Date} options.startDate - The start date of the subscription period.
 * @param {Date} options.endDate - The end date of the subscription period.
 * @return {Promise} A Promise that resolves to the account meeting details.
 */
export function getAccountMeetingBySubscritionPeriod({
  accountKey,
  type,
  startDate,
  endDate
}) {
  return http._get(`Accounts/${accountKey}/subscriptions/summary/details`, {
    type,
    startDate,
    endDate
  });
}

/**
 * get account contacts
 *
 * @param  {String} key    - the Account Key
 * @return {Promise}       - the response
 */
export function getAccountContacts(key) {
  return http._get(`Accounts/${key}/contacts`);
}

/**
 * associate contacts to account
 *
 * @param  {String} key    - the Account Key
 * @param  {String[]} contactIds - the contact ids
 * @return {Promise}       - the response
 */
export function associateContacts({ key, contactIds }) {
  return http._post(`Accounts/${key}/contacts`, { contactIds });
}

/**
 * remove association
 *
 * @param  {String} key    - the Account Key
 * @param  {String} contactId - the contact id
 * @return {Promise}       - the response
 */
export function removeAssociation({ key, contactId }) {
  return http._delete(`Accounts/${key}/contacts/${contactId}`);
}

/**
 * update association
 *
 * @param  {String} key    - the Account Key
 * @param  {String} contactIds - the contact ids
 * @param  {String} types   - association types
 * @return {Promise}       - the response
 */
export function updateAssociation({ key, contactIds, types }) {
  return http._put(`Accounts/${key}/contacts`, { contactIds, types });
}

/**
 * link company
 *
 * @param  {String} accountKey - the Account Key
 * @param  {String} companyId - company id
 * @return {Promise}       - the response
 */
export function linkCompany({ accountKey, companyId }) {
  return http._put(`Accounts/${accountKey}/link-company`, { companyId });
}

/**
 * unlink company
 *
 * @param  {String} accountKey - the Account Key
 * @param  {String} companyId - company id
 * @return {Promise}       - the response
 */
export function unlinkCompany({ accountKey }) {
  return http._put(`Accounts/${accountKey}/unlink-company`);
}

/**
 * Gets the memberships of an association
 *
 * @param  {String}  accountKey     - the Account Key
 * @param  {String}  integrationKey - the account integration key
 * @return {Promise}                - the response
 */
export function getAssociationMemberships({ accountKey, integrationKey }) {
  return http._get(`Accounts/${accountKey}/integrations/${integrationKey}`);
}

/**
 * Upsert an association
 *
 * @param  {String}  accountKey     - the Account Key
 * @param  {String}  integrationKey - the integration key
 * @param  {String}  code           - the association code
 * @param  {String}  company        - vantaca company
 * @return {Promise}                - the response
 */
export function upsertIntegration({
  accountKey,
  integrationKey,
  code,
  company
}) {
  return http._put(`Accounts/${accountKey}/integrations`, {
    integrationKey,
    code,
    company
  });
}

/**
 * remove an account integration
 *
 * @param  {String}  accountKey             - the Account Key
 * @param  {String}  accountIntegrationKey  - the integration key
 * @return {Promise}                        - the response
 */
export function removeAccIntegration({ accountKey, accountIntegrationKey }) {
  return http._delete(
    `Accounts/${accountKey}/integrations/${accountIntegrationKey}`
  );
}
