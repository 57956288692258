<template>
  <div>
    <div v-if="displayLastStatsUpdate" class="gq-cell--section-title">
      <span>The following metrics are updated every 20 seconds.</span>
      <br />
      <span
        >Last updated: {{ lastStatsUpdate | dateFormatReadable(true) }}</span
      >
    </div>

    <v-layout row wrap>
      <v-flex
        xs12
        sm12
        md4
        :lg3="!displaySecondaryStats"
        :lg4="displaySecondaryStats"
      >
        <stat-card
          icon-name="home"
          :primary-stat="primaryStat"
          :secondary-stat="currentMeeting.options.secondaryAttendanceMetric"
          :tertiary-stat="currentMeeting.options.tertiaryAttendanceMetric"
          :display-secondary-stat="displaySecondaryStats"
          :units-stat-number="
            currentMeeting.stats ? currentMeeting.stats.totalUnits : -1
          "
          units-label="Total Units"
          :shares-stat-number="
            currentMeeting.stats ? currentMeeting.stats.totalUnitsShares : -1
          "
          shares-label="Total Shares"
          :groups-stat-number="
            currentMeeting.stats ? currentMeeting.stats.totalUnitGroups : -1
          "
          groups-label="Total Groups"
          :display-custom-groups="displayCustomGroups"
          :display-shares-as-percentages="
            currentMeeting.options.sharesAsPercentages
          "
          :display-rounded-results="currentMeeting.options.roundedResults"
          :rounded-results-precision="
            currentMeeting.options.roundedResultsPrecision
          "
          :testVoters="
            currentMeeting.stats
              ? currentMeeting.stats.testVoterStats.totalTestVoterUnits
              : -1
          "
          :loading-stats="loadingStatuses.stats"
          color="primary"
        />
      </v-flex>

      <v-flex
        v-if="isMultiConsent"
        xs12
        sm12
        md4
        :lg3="!displaySecondaryStats"
        :lg4="displaySecondaryStats"
      >
        <!-- Notice multi-consent count -->
        <stat-card
          icon-name="mail"
          :primary-stat="primaryStat"
          :displayInLineLabel="displaySecondaryStats && displayCustomGroups"
          :not-show-stats="displaySecondaryStats || displayCustomGroups"
          :units-stat-number="
            currentMeeting.stats
              ? currentMeeting.stats.totalMultiConsentNotice
              : -1
          "
          units-label="Total Notice Consents"
          :shares-stat-number="
            currentMeeting.stats
              ? currentMeeting.stats.totalMultiConsentNotice
              : -1
          "
          shares-label="Total Notice Consents"
          :groups-stat-number="
            currentMeeting.stats
              ? currentMeeting.stats.totalMultiConsentNotice
              : -1
          "
          groups-label="Total Notice Consents"
          :has-action="hasMultiConsentNotice && canExportConsents"
          :loading-stats="loadingStatuses.stats"
          action-text="Export"
          @run-action="
            handleExport({
              type: 'consent-export-v2',
              mode: 'email-only',
              filters: { noticeAndVoting: true, noticeOnly: true },
              version: 2
            })
          "
          color="primary"
        />
      </v-flex>

      <!-- Voting multi-consent count -->
      <v-flex
        v-if="isMultiConsent"
        xs12
        sm12
        md4
        :lg3="!displaySecondaryStats"
        :lg4="displaySecondaryStats"
      >
        <stat-card
          icon-name="mail"
          :primary-stat="primaryStat"
          :displayInLineLabel="displaySecondaryStats && displayCustomGroups"
          :not-show-stats="displaySecondaryStats || displayCustomGroups"
          :units-stat-number="
            currentMeeting.stats
              ? currentMeeting.stats.totalMultiConsentVoting
              : -1
          "
          units-label="Total Voting Consents"
          :shares-stat-number="
            currentMeeting.stats
              ? currentMeeting.stats.totalMultiConsentVoting
              : -1
          "
          shares-label="Total Voting Consents"
          :groups-stat-number="
            currentMeeting.stats
              ? currentMeeting.stats.totalMultiConsentVoting
              : -1
          "
          groups-label="Total Voting Consents"
          :has-action="hasMultiConsentVoting && canExportConsents"
          :loading-stats="loadingStatuses.stats"
          action-text="Export"
          @run-action="
            handleExport({
              type: 'consent-export-v2',
              mode: 'email-only',
              filters: { noticeAndVoting: true, votingOnly: true },
              version: 2
            })
          "
          color="primary"
        />
      </v-flex>

      <!-- Single consent count -->
      <v-flex
        v-if="!isMultiConsent"
        xs12
        sm12
        md4
        :lg3="!displaySecondaryStats"
        :lg4="displaySecondaryStats"
      >
        <stat-card
          icon-name="mail"
          :primary-stat="primaryStat"
          :displayInLineLabel="displaySecondaryStats && displayCustomGroups"
          :not-show-stats="displaySecondaryStats || displayCustomGroups"
          :units-stat-number="
            currentMeeting.stats ? currentMeeting.stats.totalConsents : -1
          "
          units-label="Total E-Consents"
          :shares-stat-number="
            currentMeeting.stats ? currentMeeting.stats.totalConsents : -1
          "
          shares-label="Total E-Consents"
          :groups-stat-number="
            currentMeeting.stats ? currentMeeting.stats.totalConsents : -1
          "
          groups-label="Total E-Consents"
          :has-action="hasConsents && canExportConsents"
          :loading-stats="loadingStatuses.stats"
          action-text="Export"
          @run-action="handleExport({ type: 'consents', mode: 'email-only' })"
          color="primary"
        />
      </v-flex>
      <v-flex
        v-if="currentMeeting.options.liveVotingEnabled !== false"
        xs12
        sm12
        md4
        :lg3="!displaySecondaryStats"
        :lg4="displaySecondaryStats"
      >
        <stat-card
          icon-name="how_to_reg"
          :primary-stat="primaryStat"
          :secondary-stat="currentMeeting.options.secondaryAttendanceMetric"
          :tertiary-stat="currentMeeting.options.tertiaryAttendanceMetric"
          :display-secondary-stat="displaySecondaryStats"
          :units-stat-number="
            currentMeeting.stats ? currentMeeting.stats.attendingCount : -1
          "
          units-label="Total RSVPs"
          :shares-stat-number="
            currentMeeting.stats
              ? currentMeeting.stats.attendingSharesCount
              : -1
          "
          shares-label="Total RSVPs by Shares"
          :groups-stat-number="
            currentMeeting.stats
              ? currentMeeting.stats.attendingCountGroups
              : -1
          "
          groups-label="Total RSVPs by Groups"
          :display-custom-groups="displayCustomGroups"
          :display-shares-as-percentages="
            currentMeeting.options.sharesAsPercentages
          "
          :display-rounded-results="currentMeeting.options.roundedResults"
          :rounded-results-precision="
            currentMeeting.options.roundedResultsPrecision
          "
          :has-action="hasRsvp"
          :loading-stats="loadingStatuses.stats"
          action-text="View"
          @run-action="handleReport('rsvp')"
          color="primary"
        />
      </v-flex>
      <v-flex
        v-if="currentMeeting.options.liveVotingEnabled !== false"
        xs12
        sm12
        md4
        :lg3="!displaySecondaryStats"
        :lg4="displaySecondaryStats"
      >
        <stat-card
          icon-name="event_available"
          tooltip-text="No advance vote, no ineligible_reason flag, shares value greater than 0"
          :primary-stat="primaryStat"
          :secondary-stat="currentMeeting.options.secondaryAttendanceMetric"
          :tertiary-stat="currentMeeting.options.tertiaryAttendanceMetric"
          :display-secondary-stat="displaySecondaryStats"
          :units-stat-number="
            currentMeeting.stats
              ? currentMeeting.stats.attendingNotVotedCount
              : -1
          "
          units-label="Eligible RSVPs"
          :shares-stat-number="
            currentMeeting.stats
              ? currentMeeting.stats.attendingNotVotedSharesCount
              : -1
          "
          shares-label="Eligible RSVPs by Shares"
          :groups-stat-number="
            currentMeeting.stats
              ? currentMeeting.stats.attendingNotVotedCountGroups
              : -1
          "
          groups-label="Eligible RSVPs by Groups"
          :display-custom-groups="displayCustomGroups"
          :display-shares-as-percentages="
            currentMeeting.options.sharesAsPercentages
          "
          :display-rounded-results="currentMeeting.options.roundedResults"
          :rounded-results-precision="
            currentMeeting.options.roundedResultsPrecision
          "
          :has-action="hasEligibleRsvps"
          :loading-stats="loadingStatuses.stats"
          action-text="View"
          @run-action="handleReport('eligible-rsvp')"
          color="primary"
        />
      </v-flex>
      <v-flex
        xs12
        sm12
        md4
        :lg3="!displaySecondaryStats"
        :lg4="displaySecondaryStats"
      >
        <stat-card
          icon-name="person_pin_circle"
          :primary-stat="primaryStat"
          :secondary-stat="currentMeeting.options.secondaryAttendanceMetric"
          :tertiary-stat="currentMeeting.options.tertiaryAttendanceMetric"
          :display-secondary-stat="displaySecondaryStats"
          :units-stat-number="
            currentMeeting.stats ? currentMeeting.stats.attendanceCount : -1
          "
          units-label="Units in Attendance"
          :shares-stat-number="
            currentMeeting.stats
              ? currentMeeting.stats.attendanceSharesCount
              : -1
          "
          shares-label="Shares in Attendance"
          :groups-stat-number="
            currentMeeting.stats
              ? currentMeeting.stats.attendanceCountGroups
              : -1
          "
          groups-label="Groups in Attendance"
          :display-custom-groups="displayCustomGroups"
          :display-shares-as-percentages="
            currentMeeting.options.sharesAsPercentages
          "
          :display-rounded-results="currentMeeting.options.roundedResults"
          :rounded-results-precision="
            currentMeeting.options.roundedResultsPrecision
          "
          :testVoters="
            currentMeeting.stats
              ? currentMeeting.stats.testVoterStats.totalTestVotersInAttendance
              : -1
          "
          :loading-stats="loadingStatuses.stats"
          color="primary"
        />
      </v-flex>
      <v-flex
        xs12
        sm12
        md4
        :lg3="!displaySecondaryStats"
        :lg4="displaySecondaryStats"
      >
        <stat-card
          icon-name="local_activity"
          tooltip-text="No advance vote, no ineligible_reason flag, shares value greater than 0"
          :primary-stat="primaryStat"
          :secondary-stat="currentMeeting.options.secondaryAttendanceMetric"
          :tertiary-stat="currentMeeting.options.tertiaryAttendanceMetric"
          :display-secondary-stat="displaySecondaryStats"
          :units-stat-number="
            currentMeeting.stats
              ? currentMeeting.stats.attendedNotVotedCount
              : -1
          "
          units-label="Eligible Units in Attendance"
          :shares-stat-number="
            currentMeeting.stats
              ? currentMeeting.stats.attendedNotVotedSharesCount
              : -1
          "
          shares-label="Eligible Shares in Attendance"
          :groups-stat-number="
            currentMeeting.stats
              ? currentMeeting.stats.attendedNotVotedCountGroups
              : -1
          "
          groups-label="Eligible Groups in Attendance"
          :display-custom-groups="displayCustomGroups"
          :display-shares-as-percentages="
            currentMeeting.options.sharesAsPercentages
          "
          :display-rounded-results="currentMeeting.options.roundedResults"
          :rounded-results-precision="
            currentMeeting.options.roundedResultsPrecision
          "
          :loading-stats="loadingStatuses.stats"
          color="primary"
        />
      </v-flex>

      <v-flex
        xs12
        sm12
        md4
        :lg3="!displaySecondaryStats"
        :lg4="displaySecondaryStats"
      >
        <stat-card
          icon-name="tap_and_play"
          :primary-stat="primaryStat"
          :displayInLineLabel="displaySecondaryStats && displayCustomGroups"
          :not-show-stats="displaySecondaryStats || displayCustomGroups"
          :units-stat-number="
            currentMeeting.stats
              ? currentMeeting.stats.rtvPresence.numUnits
              : -1
          "
          :shares-stat-number="
            currentMeeting.stats
              ? currentMeeting.stats.rtvPresence.numUnits
              : -1
          "
          :groups-stat-number="
            currentMeeting.stats
              ? currentMeeting.stats.rtvPresence.numUnits
              : -1
          "
          :loading-stats="loadingStatuses.stats"
          units-label="Units Online (Live App)"
          shares-label="Units Online (Live App)"
          groups-label="Units Online (Live App)"
          color="primary"
        />
      </v-flex>
      <v-flex
        xs12
        sm12
        md4
        :lg3="!displaySecondaryStats"
        :lg4="displaySecondaryStats"
      >
        <stat-card
          icon-name="group"
          :primary-stat="primaryStat"
          :displayInLineLabel="displaySecondaryStats && displayCustomGroups"
          :not-show-stats="displaySecondaryStats || displayCustomGroups"
          :units-stat-number="currentMeeting.quorumMinimum"
          :groups-stat-number="currentMeeting.quorumMinimum"
          :shares-stat-number="currentMeeting.quorumMinimum"
          :loading-stats="loadingStatuses.stats"
          units-label="Total Needed for Quorum"
          shares-label="Total Needed for Quorum"
          groups-label="Total Needed for Quorum"
          color="primary"
        />
      </v-flex>
      <v-flex
        xs12
        sm12
        md4
        :lg3="!displaySecondaryStats"
        :lg4="displaySecondaryStats"
      >
        <!-- Total attendance for Quorum is calculated by: units in attendance + submissions
        TEMPORARY: group-stat-number uses shares for the time being as a placeholder -->

        <stat-card
          icon-name="group"
          :tooltip-text="inAttendanceQuorumTooltipText"
          :primary-stat="primaryStat"
          :displayInLineLabel="displaySecondaryStats && displayCustomGroups"
          :secondary-stat="currentMeeting.options.secondaryAttendanceMetric"
          :tertiary-stat="currentMeeting.options.tertiaryAttendanceMetric"
          :display-secondary-stat="displaySecondaryStats"
          :units-stat-number="inAttendanceQuorumCount"
          units-label="Total Units in Attendance for Quorum"
          :shares-stat-number="inAttendanceQuorumSharesCount"
          shares-label="Total Shares in Attendance for Quorum"
          :groups-stat-number="inAttendanceQuorumGroupsCount"
          :loading-stats="loadingStatuses.stats"
          groups-label="Total Shares in Attendance for Quorum"
          color="primary"
        />
      </v-flex>
      <v-flex
        xs12
        sm12
        md4
        :lg3="!displaySecondaryStats"
        :lg4="displaySecondaryStats"
      >
        <stat-card
          icon-name="money_off"
          :primary-stat="primaryStat"
          :secondary-stat="currentMeeting.options.secondaryAttendanceMetric"
          :tertiary-stat="currentMeeting.options.tertiaryAttendanceMetric"
          :display-secondary-stat="displaySecondaryStats"
          :display-custom-groups="displayCustomGroups"
          :units-stat-number="
            currentMeeting.stats
              ? currentMeeting.stats.totalArrearsUnitAttendance
              : -1
          "
          units-label="Total Eligible Arrears Units in Attendance"
          :shares-stat-number="
            currentMeeting.stats
              ? currentMeeting.stats.totalArrearsShareAttendance
              : -1
          "
          :loading-stats="loadingStatuses.stats"
          shares-label="Total Eligible Arrears Shares in Attendance"
          groups-label="Total Eligible Arrears Groups in Attendance"
          color="primary"
        />
      </v-flex>

      <!-- TEMPORARY: group-stat-number uses shares for the time being as a placeholder -->
      <v-flex
        xs12
        sm12
        md4
        :lg3="!displaySecondaryStats"
        :lg4="displaySecondaryStats"
        v-if="isOntarioMeeting"
      >
        <stat-card
          icon-name="business"
          :primary-stat="primaryStat"
          :secondary-stat="currentMeeting.options.secondaryAttendanceMetric"
          :displayInLineLabel="displaySecondaryStats && displayCustomGroups"
          :display-secondary-stat="displaySecondaryStats"
          :units-stat-number="
            currentMeeting.stats
              ? currentMeeting.stats.totalOwnerOccupiedUnits
              : -1
          "
          units-label="Total Owner-Occupied Units"
          :shares-stat-number="
            currentMeeting.stats
              ? currentMeeting.stats.totalOwnerOccupiedShares
              : -1
          "
          :groups-stat-number="
            currentMeeting.stats
              ? currentMeeting.stats.totalOwnerOccupiedShares
              : -1
          "
          :loading-stats="loadingStatuses.stats"
          shares-label="Total Owner-Occupied Shares"
          groups-label="Total Owner-Occupied Shares"
          color="primary"
        />
      </v-flex>

      <!-- TEMPORARY: group-stat-number uses shares for the time being as a placeholder -->
      <v-flex
        xs12
        sm12
        md4
        :lg3="!displaySecondaryStats"
        :lg4="displaySecondaryStats"
      >
        <stat-card
          icon-name="edit_off"
          :primary-stat="primaryStat"
          :secondary-stat="currentMeeting.options.secondaryAttendanceMetric"
          :displayInLineLabel="displaySecondaryStats && displayCustomGroups"
          :display-secondary-stat="displaySecondaryStats"
          :units-stat-number="
            currentMeeting.stats ? currentMeeting.stats.totalInPersonUnits : -1
          "
          units-label="Eligible In-Person Units"
          :shares-stat-number="
            currentMeeting.stats ? currentMeeting.stats.totalInPersonShares : -1
          "
          :groups-stat-number="
            currentMeeting.stats ? currentMeeting.stats.totalInPersonShares : -1
          "
          :loading-stats="loadingStatuses.stats"
          shares-label="Total Eligible In-Person Shares"
          groups-label="Total Eligible In-Person Shares"
          color="primary"
        />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import _ from 'lodash';
import { dateFormatReadable } from '@/filters';
import StatCard from '@/components/StatCard';

export default {
  name: 'MeetingStatsGrid',
  components: {
    StatCard
  },
  filters: {
    dateFormatReadable
  },
  props: {
    currentMeeting: {
      type: Object,
      required: true,
      default: () => ({})
    },
    loadingStatuses: {
      type: Object,
      required: true,
      default: () => ({})
    },
    canExportConsents: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    displayLastStatsUpdate() {
      return this.currentMeeting.options?.enableGetStatsV2;
    },
    lastStatsUpdate() {
      return this.currentMeeting?.stats?.cachedAt;
    },
    displaySecondaryStats() {
      return this.currentMeeting.options.displaySecondaryMetric;
    },
    displayCustomGroups() {
      return this.currentMeeting.options.displayCustomGroups;
    },
    primaryStat() {
      if (
        (!this.displaySecondaryStats && !this.displayCustomGroups) ||
        !this.currentMeeting.options.primaryAttendanceMetric
      ) {
        return 'Units';
      }
      return this.currentMeeting.options.primaryAttendanceMetric;
    },
    isMultiConsent() {
      return this.currentMeeting.options?.consentFormat === 'multi-consent';
    },
    hasConsents() {
      return _.get(this.currentMeeting, 'stats.totalConsents', 0) > 0;
    },
    hasMultiConsentNotice() {
      return _.get(this.currentMeeting, 'stats.totalMultiConsentNotice', 0) > 0;
    },
    hasMultiConsentVoting() {
      return _.get(this.currentMeeting, 'stats.totalMultiConsentVoting', 0) > 0;
    },
    hasRsvp() {
      return _.get(this.currentMeeting, 'stats.attendingCount', 0) > 0;
    },
    hasEligibleRsvps() {
      return _.get(this.currentMeeting, 'stats.attendingNotVotedCount', 0) > 0;
    },
    inAttendanceQuorumCount() {
      if (this.currentMeeting.options?.votingOption === 'Proxy-Ballot') {
        return _.isNumber(this.currentMeeting.stats?.onlineQuorumUnitCount) &&
          _.isNumber(this.currentMeeting.stats?.inPersonNotVotedCount)
          ? this.currentMeeting.stats?.onlineQuorumUnitCount +
              this.currentMeeting.stats?.inPersonNotVotedCount
          : -1;
      }
      return (
        Number(
          this.currentMeeting.stats
            ? this.currentMeeting.stats.totalProxies
            : -1
        ) +
        Number(
          this.currentMeeting.stats
            ? this.currentMeeting.stats.attendedNotVotedCount
            : -1
        ) +
        Number(
          this.currentMeeting.stats
            ? this.currentMeeting.stats.totalInPersonUnits
            : -1
        )
      );
    },
    inAttendanceQuorumSharesCount() {
      if (this.currentMeeting.options?.votingOption === 'Proxy-Ballot') {
        return (
          Number(
            this.currentMeeting.stats
              ? this.currentMeeting.stats.onlineQuorumUnitShareCount
              : -1
          ) +
          Number(
            this.currentMeeting.stats
              ? this.currentMeeting.stats.inPersonNotVotedShareCount
              : -1
          )
        );
      }
      return (
        Number(
          this.currentMeeting.stats
            ? this.currentMeeting.stats.totalProxiesShares
            : -1
        ) +
        Number(
          this.currentMeeting.stats
            ? this.currentMeeting.stats.attendedNotVotedSharesCount
            : -1
        ) +
        Number(
          this.currentMeeting.stats
            ? this.currentMeeting.stats.totalInPersonShares
            : -1
        )
      );
    },
    inAttendanceQuorumGroupsCount() {
      if (this.currentMeeting.options?.votingOption === 'Proxy-Ballot') {
        return (
          Number(
            this.currentMeeting.stats
              ? this.currentMeeting.stats.onlineQuorumUnitShareCount
              : -1
          ) +
          Number(
            this.currentMeeting.stats
              ? this.currentMeeting.stats.inPersonNotVotedShareCount
              : -1
          )
        );
      }
      return (
        Number(
          this.currentMeeting.stats
            ? this.currentMeeting.stats.totalProxiesShares
            : -1
        ) +
        Number(
          this.currentMeeting.stats
            ? this.currentMeeting.stats.attendedNotVotedSharesCount
            : -1
        ) +
        Number(
          this.currentMeeting.stats
            ? this.currentMeeting.stats.totalInPersonShares
            : -1
        )
      );
    },
    isOntarioMeeting() {
      return this.currentMeeting.region === 'ON';
    },
    inAttendanceQuorumTooltipText() {
      if (this.currentMeeting.options?.votingOption === 'Proxy-Ballot') {
        return this.displaySecondaryStats
          ? `${
              this.currentMeeting.stats
                ? this.currentMeeting.stats.onlineQuorumUnitCount
                : '?'
            } total units (${
              this.currentMeeting.stats
                ? this.currentMeeting.stats.onlineQuorumUnitShareCount
                : '?'
            } shares) + ${
              this.currentMeeting.stats
                ? this.currentMeeting.stats.inPersonNotVotedCount
                : '?'
            } eligible in-person units (${
              this.currentMeeting.stats
                ? this.currentMeeting.stats.inPersonNotVotedShareCount
                : '?'
            } shares)`
          : `${
              this.currentMeeting.stats
                ? this.currentMeeting.stats.onlineQuorumUnitCount
                : '?'
            } total units + ${
              this.currentMeeting.stats
                ? this.currentMeeting.stats.inPersonNotVotedCount
                : '?'
            } eligible in-person units`;
      }

      return this.displaySecondaryStats
        ? `${
            this.currentMeeting.stats
              ? this.currentMeeting.stats.totalProxies
              : '?'
          } total submissions (${
            this.currentMeeting.stats
              ? this.currentMeeting.stats.totalProxiesShares
              : '?'
          } shares) + ${
            this.currentMeeting.stats
              ? this.currentMeeting.stats.attendedNotVotedCount
              : '?'
          } eligible units in attendance (${
            this.currentMeeting.stats
              ? this.currentMeeting.stats.attendedNotVotedSharesCount
              : '?'
          } shares) + ${
            this.currentMeeting.stats
              ? this.currentMeeting.stats.totalInPersonUnits
              : '?'
          } eligible in-person units (${
            this.currentMeeting.stats
              ? this.currentMeeting.stats.totalInPersonShares
              : '?'
          } shares)`
        : `${
            this.currentMeeting.stats
              ? this.currentMeeting.stats.totalProxies
              : '?'
          } total submissions + ${
            this.currentMeeting.stats
              ? this.currentMeeting.stats.attendedNotVotedCount
              : '?'
          } eligible units in attendance + ${
            this.currentMeeting.stats
              ? this.currentMeeting.stats.totalInPersonUnits
              : '?'
          } eligible in-person units`;
    }
  },
  methods: {
    handleExport({ type, mode, filters, version = null }) {
      this.$emit('export', {
        type,
        mode,
        filters,
        version
      });
    },
    handleReport(reportType) {
      this.$emit('run-report', reportType);
    }
  }
};
</script>

<style scoped>
.gq-cell--section-title {
  position: relative;
  left: 15px;
  top: 16px;
}
</style>
