<template>
  <v-container>
    <v-layout :class="disableProxyHolder ? 'disabled' : ''">
      <v-flex xs12>
        <h4 class="title grey--text text--darken-4 mb-1">
          Default Proxy Holders
        </h4>
        <div class="body-1 grey--text text--darken-2 mb-4">
          <li>One name per line, no commas</li>
          <li>The first entry is the primary proxy holder.</li>
          <li>
            All other names are fall-back proxy holders on final PDF proxy form
          </li>
          <li>
            By default, the first entry will be the only option presented to
            voters (see advanced options to change this).
          </li>
        </div>

        <div class="codemirror-container">
          <code-mirror
            :is-disabled="disableProxyHolder"
            :code-array="defaultHolders"
            :key="renderKey"
            @update="onCustomEmailChange"
          />
        </div>
      </v-flex>
    </v-layout>

    <v-divider class="gq-divider" />

    <v-layout>
      <v-flex xs12>
        <h4 class="title grey--text text--darken-4 mb-1">
          Advanced Settings
        </h4>
        <p class="body-1 grey--text text--darken-2 mb-4">
          Additional settings to customize or disable the proxy holder question
          on the voting tool.
        </p>
      </v-flex>
    </v-layout>

    <v-layout mb-2 :class="isBill106 ? 'disabled' : ''">
      <div class="pr-3">
        <v-checkbox
          :disabled="isBill106"
          :true-value="true"
          :false-value="false"
          v-model="disableProxyHolder"
          @change="onChange('options.disableProxyHolder', $event)"
        />
      </div>
      <div>
        <h5 class="subheading">
          Disable the proxy holder question (Custom Template)
        </h5>
        <p class="grey--text text--darken-2">
          Check this to skip the proxy holder question on voting tool (ie. if
          running e-ballot, amendment, or survey type campaigns). Cannot be
          disabled if <code>proxy_template='Bill 106'</code>
        </p>
      </div>
    </v-layout>

    <v-layout :class="disableProxyHolder ? 'disabled' : ''">
      <div class="pr-3">
        <v-checkbox
          :disabled="disableProxyHolder"
          :true-value="true"
          :false-value="false"
          v-model="listAllDefaultHolders"
          @change="
            onChange(
              'options.proxyAppDisplayOptions.proxy.listAllDefaultHolders',
              $event
            )
          "
        />
      </div>
      <div>
        <h5 class="subheading">
          List all default proxy holders as selectable options
        </h5>
        <p class="grey--text text--darken-2">
          Check this to list all default proxy holders specified above as
          selectable options on voting tool. By default, only the first default
          proxy holder is shown.
        </p>
      </div>
    </v-layout>

    <v-layout :class="disableProxyHolder ? 'disabled' : ''">
      <div class="pr-3">
        <v-checkbox
          :disabled="disableProxyHolder"
          :true-value="true"
          :false-value="false"
          v-model="shouldAskFirst"
          @change="
            onChange(
              'options.proxyAppDisplayOptions.proxy.shouldAskFirst',
              $event
            )
          "
        />
      </div>
      <div>
        <h5 class="subheading">
          Ask proxy holder question before all other matters
        </h5>
        <p class="grey--text text--darken-2">
          Check this to re-order the voting tool so that the proxy holder
          question is asked BEFORE all other matters.
        </p>
      </div>
    </v-layout>

    <v-layout>
      <div class="pr-3">
        <v-checkbox
          :true-value="true"
          :false-value="false"
          v-model="disableFallbackHolder"
          @change="
            onChange(
              'options.proxyAppDisplayOptions.proxy.disableFallbackHolder',
              $event
            )
          "
        />
      </div>
      <div>
        <h5 class="subheading">
          Disable Fallback Proxy Holders
        </h5>
        <p class="grey--text text--darken-2">
          Check this to disable fallback proxy holders. This will remove the
          fallback proxy holder checkbox and all mention of fallback proxy
          holders from the proxy holder page.
        </p>
      </div>
    </v-layout>

    <v-layout>
      <div class="pr-3">
        <v-checkbox
          v-model="restrictProxyInput"
          @change="onChange('options.restrictProxyInput', $event)"
        />
      </div>
      <div>
        <h5 class="subheading">
          Restrict Proxy Input?
        </h5>
        <p class="grey--text text--darken-2">
          Restricts proxy input if no questions have been added to the meeting.
        </p>
      </div>
    </v-layout>

    <v-divider class="gq-divider" />

    <v-layout :class="disableProxyHolder ? 'disabled' : ''">
      <v-flex xs12>
        <h5 class="subheading">Snippet text customization</h5>
        <p class="grey--text text--darken-2">
          Optionally, change the snippet text, directly the title, on the voting
          tool's proxy holder question page.
        </p>
        <v-textarea
          label="Snippet text (HTML-enabled)"
          placeholder="(default) Your proxy holder will attend the meeting and cast your vote on your behalf. Enter their name below:"
          v-model="snippet"
          box
          @input="
            onChange('options.proxyAppDisplayOptions.proxy.snippet', $event)
          "
        />
      </v-flex>
    </v-layout>

    <v-layout :class="disableProxyHolder ? 'disabled' : ''">
      <v-flex xs12>
        <h5 class="subheading">Write-in box label customization</h5>
        <p class="grey--text text--darken-2">
          Optionally, change the label text displayed on the write-in proxy
          holder textbox
        </p>
        <v-text-field
          label="Write-in box label (Text only)"
          placeholder="(default) Enter the name of your Proxy Holder below:"
          v-model="writeInLabel"
          box
          @input="
            onChange(
              'options.proxyAppDisplayOptions.proxy.writeInLabel',
              $event
            )
          "
        />
      </v-flex>
    </v-layout>

    <v-layout :class="disableProxyHolder ? 'disabled' : ''">
      <v-flex xs12>
        <h5 class="subheading">Write-in box placeholder customization</h5>
        <p class="grey--text text--darken-2">
          Optionally, change the placeholder text displayed
          <i>inside</i> write-in proxy holder textbox when the field is empty.
        </p>
        <v-text-field
          label="Write-in box placeholder (Text only)"
          placeholder="(default) eg. John Smith"
          v-model="writeInPlaceholder"
          box
          @input="
            onChange(
              'options.proxyAppDisplayOptions.proxy.writeInPlaceholder',
              $event
            )
          "
        />
      </v-flex>
    </v-layout>

    <v-layout :class="disableProxyHolder ? 'disabled' : ''">
      <v-flex xs12>
        <h5 class="subheading">Checkbox label customization</h5>
        <p class="grey--text text--darken-2">
          Optionally, change the checkbox label on the proxy holder page.
        </p>
        <v-text-field
          label="Checkbox label (Text only)"
          placeholder="(default) Should my designated proxy holder fail to attend the meeting, I agree that <default holder> shall be appointed as my fallback proxy holder."
          v-model="writeInCheckbox"
          box
          @input="
            onChange(
              'options.proxyAppDisplayOptions.proxy.writeInCheckbox',
              $event
            )
          "
        />
      </v-flex>
    </v-layout>

    <v-divider class="gq-divider" />

    <v-layout :class="disableProxyHolder ? 'disabled' : ''">
      <div class="pr-3">
        <v-checkbox
          :disabled="disableProxyHolder"
          :true-value="true"
          :false-value="false"
          v-model="experimentalMode"
          @change="
            onChange(
              'options.proxyAppDisplayOptions.proxy.experimentalMode',
              $event
            )
          "
        />
      </div>
      <div>
        <h5 class="subheading">Turn on Experimental Mode</h5>
        <p class="grey--text text--darken-2">
          Check this to enable the Experimental version of the proxy holder
          question on voting tool (ie. for Del Turnovers).
        </p>
      </div>
    </v-layout>

    <v-layout
      :class="disableProxyHolder || !experimentalMode ? 'disabled' : ''"
    >
      <div class="pr-3">
        <v-checkbox
          :disabled="disableProxyHolder || !experimentalMode"
          :true-value="true"
          :false-value="false"
          v-model="experimentalModeHideChips"
          @change="
            onChange(
              'options.proxyAppDisplayOptions.proxy.experimentalModeHideChips',
              $event
            )
          "
        />
      </div>
      <div>
        <h5 class="subheading">Hide the proxy selection chips</h5>
        <p class="grey--text text--darken-2">
          On experimental mode, choose to hide the proxy holder selection chips
          and only allow for write-in candidates
        </p>
      </div>
    </v-layout>

    <v-layout
      :class="disableProxyHolder || !experimentalMode ? 'disabled' : ''"
    >
      <div class="mb-3">
        <h5 class="subheading">Additional proxy holders (Experimental)</h5>
        <p class="grey--text text--darken-2">
          Specify additional (non-default) proxy holders. These will be
          displayed as selectable options on the voting tool. They will NOT
          appear as fall-backs on the PDF proxy
        </p>
        <code-mirror
          :is-disabled="disableProxyHolder || !experimentalMode"
          class="mt-1"
          :code-array="options.otherProxyHolders"
          :key="renderKey"
          @update="onChange('options.otherProxyHolders', $event)"
        />
      </div>
    </v-layout>

    <v-layout :class="disableProxyHolder ? 'disabled' : ''">
      <div>
        <v-checkbox
          :disabled="disableProxyHolder"
          :true-value="true"
          :false-value="false"
          v-model="displayCustomDropdown"
          @change="
            onChange(
              'options.proxyAppDisplayOptions.proxy.displayCustomDropdown',
              $event
            )
          "
        />
      </div>

      <div>
        <h5 class="subheading">Enable custom dropdown menu (Experimental)</h5>
        <p class="grey--text text--darken-2">
          Check this to add a custom dropdown menu to the proxy page. Add a
          label and a list of options/selections below. The option selected will
          be appended to the proxy holder's name: "Proxy Holder, Dropdown
          Selection".
        </p>
      </div>
    </v-layout>

    <v-layout
      :class="disableProxyHolder || !displayCustomDropdown ? 'disabled' : ''"
    >
      <v-flex xs-12>
        <v-text-field
          label="Dropdown label/title"
          placeholder="eg. Choose your school board"
          v-model="customDropdownLabel"
          box
          @input="
            onChange(
              'options.proxyAppDisplayOptions.proxy.customProxyDropdown.label',
              $event
            )
          "
        />
      </v-flex>
    </v-layout>

    <v-layout
      :class="disableProxyHolder || !displayCustomDropdown ? 'disabled' : ''"
    >
      <v-flex xs12>
        <div class="codemirror-container">
          <code-mirror
            :is-disabled="disableProxyHolder || !experimentalMode"
            class="mt-1"
            :code-array="customDropdownOptions"
            @update="
              onChange(
                'options.proxyAppDisplayOptions.proxy.customProxyDropdown.options',
                $event
              )
            "
          />
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import _ from 'lodash';
import RichTextEditor from '@/components/RichTextEditor/RichTextEditor';
import CodeMirror from '@/components/CodeMirror';
export default {
  name: 'ProxyInputSettings',
  components: {
    CodeMirror,
    RichTextEditor
  },
  props: {
    defaultHolders: {
      type: Array,
      default() {
        return [];
      }
    },
    options: {
      type: Object,
      required: true
    },
    renderKey: {
      type: Number,
      default: 0
    }
  },
  mounted() {
    this.initState();
  },
  data() {
    return {
      disableProxyHolder: false,
      disableFallbackHolder: false,
      restrictProxyInput: false,
      experimentalMode: false,
      shouldAskFirst: false,
      listAllDefaultHolders: false,
      experimentalModeHideChips: false,
      writeInLabel: '',
      writeInPlaceholder: '',
      snippet: '',
      writeInCheckbox: '',
      displayCustomDropdown: false,
      customDropdownLabel: '',
      customDropdownOptions: []
    };
  },
  computed: {
    isBill106() {
      // Can only be disabled if it's not an ontario proxy
      return this.options.proxyTemplate === 'on-bill-106';
    }
  },
  methods: {
    initState() {
      this.disableProxyHolder = this.options.disableProxyHolder;
      const key = 'proxyAppDisplayOptions.proxy';
      this.snippet = _.get(this.options, `${key}.snippet`, '');
      this.writeInLabel = _.get(this.options, `${key}.writeInLabel`, '');
      this.writeInPlaceholder = _.get(
        this.options,
        `${key}.writeInPlaceholder`,
        ''
      );
      this.writeInCheckbox = _.get(this.options, `${key}.writeInCheckbox`, '');
      this.shouldAskFirst = _.get(this.options, `${key}.shouldAskFirst`, false);
      this.experimentalMode = _.get(
        this.options,
        `${key}.experimentalMode`,
        false
      );
      this.experimentalModeHideChips = _.get(
        this.options,
        `${key}.experimentalModeHideChips`,
        false
      );
      this.listAllDefaultHolders = _.get(
        this.options,
        `${key}.listAllDefaultHolders`,
        false
      );
      this.displayCustomDropdown = _.get(
        this.options,
        `${key}.displayCustomDropdown`,
        false
      );
      this.customDropdownLabel = _.get(
        this.options,
        `${key}.customProxyDropdown.label`,
        ''
      );
      this.customDropdownOptions = _.get(
        this.options,
        `${key}.customProxyDropdown.options`,
        []
      );
      this.disableFallbackHolder = _.get(
        this.options,
        `${key}.disableFallbackHolder`,
        false
      );
      this.restrictProxyInput = _.get(
        this.options,
        'restrictProxyInput',
        false
      );
    },
    onChange(key, value) {
      this.$emit('update', key, value);
    },
    onCustomEmailChange(array) {
      this.onChange('defaultHolders', array);
    }
  }
};
</script>

<style lang="scss" scoped>
.disabled {
  opacity: 0.5;
}
</style>
