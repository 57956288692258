import http from './requestType';

/**
 * Returns all meetings the user has access to view
 * @param  {Object}   [params]                  - params
 * @param  {boolean}  [params.includeStats]     - whether to include stats for each mtg
 * @param  {Date}     [params.dateRangeStart]   - meeting date start range
 * @param  {Date}     [params.dateRangeEnd]     - meeting date end range
 * @param  {String}   [params.searchText]       - name or shortcode text to search on
 * @param  {Number}   [params.limit]            - limit page size to number of records
 * @return {Promise}
 */
export function getMeetings({
  includeStats = true,
  dateRangeStart,
  dateRangeEnd,
  searchText,
  limit
} = {}) {
  return http._get('Meetings', {
    includeStats,
    dateRangeStart,
    dateRangeEnd,
    searchText,
    limit
  });
}

/**
 * Create a new meeting
 * @param {object} meeting - new meeting to create
 * @return {Promise} - Promise
 */
export function postNewMeeting(meeting) {
  return http._post(`Meetings`, meeting);
}

/**
 * Get details for a single meeting
 * @param {string} shortCode - The meeting shortCode
 * @return {Promise} - Promise
 */
export function getMeetingDetails(shortCode) {
  return http._get(`Meetings/${shortCode}`);
}

/**
 * Patch a meeting
 * @param {String} shortCode - The meeting shortCode
 * @param {object} updates - Meeting properties to be updated
 * @return {Promise} - Promise
 */
export function patchMeeting(shortCode, updates) {
  return http._patch(`Meetings/${shortCode}`, updates);
}

/**
 * Update a meeting
 * @param {string} shortCode - The meeting shortCode
 * @param {object} formData - formData object to update meeting
 * @return {Promise} - Promise
 */
export function putMeetingDetails(shortCode, formData) {
  return http._put(`Meetings/${shortCode}`, formData);
}

/**
 * Update meeting stage
 *
 * @param  {string} shortCode shortcode
 * @param  {string} stage     meeting stage
 * @return {Promise}          Promise
 */
export function updateMeetingStage(shortCode, stage) {
  return http._put(`Meetings/${shortCode}/stage`, { stage });
}

/**
 * Returns meeting stats
 * @param  {String} shortCode - the meeting shortcode
 * @return {Promise} - Promise
 */
export function getMeetingStats(shortCode, clearCache) {
  return http._get(`Meetings/${shortCode}/stats`, { clearCache });
}

/**
 * Returns meeting stats through subscription
 * @param  {String} shortCode - the meeting shortcode
 * @return {Promise} - Promise
 */
export function getMeetingStatsQueue(shortCode) {
  return http._get(`Meetings/${shortCode}/stats/queue`);
}

/**
 * Check if a shortcode is valid for creating a new meeting
 * @param {string} shortCode - shortcode to validate
 * @return {Promise} - Promise
 */
export function getValidateShortcode(shortCode) {
  return http._get(`Meetings/actions/check-shortcode?shortCode=${shortCode}`);
}

/**
 * Gets a list of recipients that belong to the specified meeting segment.
 * @param  {String}   shortCode      - the meeting shortcode
 * @param  {String}   segment        - the segment name
 * @param  {Array}    [emails]       - query string for email array
 * @param  {Boolean}  [urgent]       - Whether to deem this segment to be urgent
 * @param  {Object}   [range]        - custom email range
 * @param  {String}   [range.start]  - custom email range start
 * @param  {String}   [range.end]    - custom email range end
 * @param  {String[]} recips         - the notice's recipients
 * @return {Promise}                - Promise
 */
export function getRecipientsBySegment({
  shortCode,
  segment,
  emails,
  range,
  customData,
  urgent = null,
  recips = []
}) {
  return http._get(`Meetings/${shortCode}/recipients`, {
    segment,
    emails,
    urgent,
    range,
    customData,
    recips
  });
}

/**
 * List the notices that belong to this meeting
 * @param  {string}  shortCode    - the meeting shortcode
 * @return {Promise}              - Promise
 */
export function getMeetingNotices(shortCode) {
  return http._get(`Meetings/${shortCode}/notices`);
}

/**
 * Get preview of a notice to be sent to a voter.
 * Optionally specify a `voterKey` to preview what the voter would be sent.
 * When no voterKey is provided, it will utilize a sample test voter.
 * @param  {string}  shortCode    - the meeting shortcode
 * @param  {string}  noticeKey    - the notice key
 * @param  {string}  voterKey     - the voter key (optional)
 * @return {Promise}              - Promise
 */
export function getNoticePreview(shortCode, noticeKey, voterKey) {
  return http._get(
    `Meetings/${shortCode}/notices/${noticeKey}/preview?voterKey=${voterKey}`
  );
}

/**
 * Resend an existing notice to a set of voters.
 *
 * @param  {string}   shortCode    - the meeting shortcode
 * @param  {string}   noticeKey    - the notice key, to resend
 * @param  {string[]} recipients   - list of voter keys to resend to
 * @return {Promise}               - Promise
 */
export function postResendMeetingNotice({ shortCode, noticeKey, recipients }) {
  return http._post(`Meetings/${shortCode}/notices/${noticeKey}/resend`, {
    recipients
  });
}

/**
 * Get preview of consent template to be sent to a voter.
 * @param  {string}  shortCode     - the meeting shortcode
 * @param  {string}  template      - (optional) provide specific template region (ie. 'florida', 'alberta') to preview, otherwise determined by meeting shortCode
 * @param {boolean}  noticeConsent - (optional) multi-consent flag for notice consent
 * @param {boolean}  votingConsent - (optional) multi-consent flag for voting consent
 * @param {string}   consentFormat - (optional) preview consent format
 * @return {Promise}               - Promise
 */
export function getConsentTemplatePreview(
  shortCode,
  template,
  noticeConsent,
  votingConsent,
  consentFormat
) {
  return http._get(`Meetings/${shortCode}/consent-template/preview`, {
    template,
    noticeConsent,
    votingConsent,
    consentFormat
  });
}

/**
 * Get stats of a notice.
 * @param  {string}  shortCode    - the meeting shortcode
 * @param  {string}  noticeKey    - the notice key
 * @return {Promise}              - Promise
 */
export function getMeetingNoticeStats({ shortCode, noticeKey }) {
  return http._get(`Meetings/${shortCode}/notices/${noticeKey}/stats`);
}

/**
 * List collaborators for a meeting
 * @param  {string}  shortCode    - the meeting shortcode
 * @return {Promise}              - Promise
 */
export function listCollaborators({ shortCode }) {
  return http._get(`Meetings/${shortCode}/collaborators`);
}

/**
 * Add a collaborator to a meeting or update if it exists
 * @param  {String}  shortCode    - the meeting shortcode
 * @param  {String}  email        - the collaborator's email
 * @param  {Object}  payload      - the payload
 * @return {Promise}              - Promise
 */
export function putCollaborator({ shortCode, email, payload }) {
  return http._put(`Meetings/${shortCode}/collaborators/${email}`, payload);
}

/**
 * Remove a collaborator from a meeting
 * @param  {String}  shortCode    - the meeting shortcode
 * @param  {String}  email        - the collaborator's email
 * @return {Promise}              - Promise
 */
export function deleteCollaborator({ shortCode, email }) {
  return http._delete(`Meetings/${shortCode}/collaborators/${email}`);
}

/**
 * Resend welcome email to a collaborator
 * @param  {String}  shortCode    - the meeting shortcode
 * @param  {String}  email        - the collaborator's email
 * @return {Promise}              - Promise
 */
export function postCollaboratorWelcome({ shortCode, email }) {
  return http._post(`Meetings/${shortCode}/collaborators/${email}/welcome`);
}

/**
 * Returns a list of meeting documents
 * @param  {String} shortCode - the meeting shortcode
 * @return {Promise} - Promise
 */
export function getMeetingDocuments(shortCode) {
  return http._get(`Meetings/${shortCode}/documents`);
}

/**
 * Create a Meeting Document
 * @param  {String}  shortCode    - the meeting shortcode
 * @param  {Object}  document     - the document (package) to add
 * @return {Promise}              - Promise
 */
export function postMeetingDocument(shortCode, document) {
  return http._post(`Meetings/${shortCode}/documents`, document);
}

/**
 * Remove a Meeting Document
 * @param  {String}  shortCode    - the meeting shortcode
 * @param  {String}  documentKey  - the document key, to delete
 * @return {Promise}              - Promise
 */
export function deleteMeetingDocument(shortCode, documentKey) {
  return http._delete(`Meetings/${shortCode}/documents/${documentKey}`);
}

/**
 * List a meeting's proxy holders.
 *
 * @param  {String} shortCode    - meeting shortcode
 * @return {Promise}
 */
export function listMeetingProxyHolders({ shortCode }) {
  return http._get(`Meetings/${shortCode}/proxy-holders`);
}

/**
 * Open or close portal revocations for a meeting.
 *
 * @param  {String} shortCode         - meeting shortcode
 * @param  {String} revocationStatus  - whether revocations are being opened or closed
 * @return {Promise}
 */
export function updateMeetingPortalRevocations(shortCode, revocationStatus) {
  return http._put(`Meetings/${shortCode}/portal-revocations`, {
    revocationStatus
  });
}

/**
 *
 * @param  {String} shortCode           - meeting shortcode
 * @param  {String} advanceVotingStatus - whether advance voting is being opened or closed
 * @return {Promise}
 */
export function updateMeetingAdvanceVoting(shortCode, advanceVotingStatus) {
  return http._put(`Meetings/${shortCode}/advance-voting`, {
    advanceVotingStatus
  });
}

/**
 * update status for a meeting.
 *
 * @param  {String} shortCode         - meeting shortcode
 * @param  {String} status            - update status
 * @return {Promise}
 */
export function updateMeetingStatus(shortCode, status) {
  return http._put(`Meetings/${shortCode}/meeting-status`, {
    status
  });
}

/**
 * update status for a VBM.
 *
 * @param  {String} shortCode         - meeting shortcode
 * @param  {String} status            - update status
 * @return {Promise}
 */
export function updateVBMStatus(shortCode, status) {
  return http._put(`Meetings/${shortCode}/meeting-status/vbm`, {
    status
  });
}

/**
 * Get a meeting's reminder settings.
 *
 * @param  {String}  shortCode                  - meeting shortcode
 * @param  {Object}  payload                    - updated meeting reminder settings
 * @return {Promise}
 */
export function getReminderSettings(shortCode) {
  return http._get(`Meetings/${shortCode}/reminder-settings`);
}

/**
 * Update a meeting's reminder settings.
 *
 * @param  {String}  shortCode                  - meeting shortcode
 * @param  {Object}  payload                    - updated meeting reminder settings
 * @return {Promise}
 */
export function updateReminderSettings(shortCode, payload) {
  return http._put(`Meetings/${shortCode}/reminder-settings`, payload);
}

/**
 * Get preview of either the automated Reminder template
 * or automated Join Portal template.
 *
 * @param  {String} shortCode         - meeting shortcode
 * @param  {String} type              - template type ('reminder' or 'join')
 * @param  {String} voterKey          - optional voter key
 * @return {Promise}
 */
export function getReminderTemplatePreview({ shortCode, type, voterKey }) {
  return http._get(`Meetings/${shortCode}/reminder-template/preview`, {
    type,
    voterKey
  });
}

/**
 * Get preview of the meeting invite template.
 *
 * @param  {String} shortCode         - meeting shortcode
 * @return {Promise}
 */
export function getInviteTemplatePreview({
  shortCode,
  troubleshooting = false
}) {
  return http._get(
    `/Meetings/${shortCode}/invite-template/preview?trouble_shooting=${troubleshooting}`
  );
}

/**
 * get meta data
 *
 * @param  {String} shortCode         - meeting shortcode
 * @param  {String} type              - meta type
 * @return {Promise}
 */
export function getMeetingMeta({ shortCode, type }) {
  return http._get(`Meetings/${shortCode}/meta-data`, {
    type
  });
}

/**
 * Returns a list of tagging users
 * @return {Promise} - Promise
 */
export function getTaggingUsers({ shortCode }) {
  return http._get(`Meetings/${shortCode}/tagging-users`);
}

/**
 * Get preview of the meeting dial in information.
 *
 * @param  {String} shortCode         - meeting shortcode
 * @return {Promise}
 */
export function getDialInInformation({ shortCode }) {
  return http._get(`/Meetings/${shortCode}/webcast/invite/dial-in-preview`);
}

/**
 * Get meeting logs
 *
 * @param  {String} shortCode         - meeting shortcode
 * @return {Promise}
 */
export function getMeetingLogs({ shortCode }) {
  return http._get(`Meetings/${shortCode}/logs`);
}
