'use strict';

/**
 * @fileoverview Vuex module for VOTERS
 */

import { getVoters } from '@/api/voters';

const state = {
  votersList: {},
  searchQuery: ''
};

const getters = {
  votersListData: state => Object.values(state.votersList),
  searchQuery: state => state.searchQuery
};

const actions = {
  async getVoters(
    { commit },
    {
      searchText,
      type,
      meetingDate,
      meetingStatus,
      includeDeletedVoters,
      pastMonths,
      limitTo
    }
  ) {
    try {
      const res = await getVoters({
        searchText,
        type,
        meetingDate,
        meetingStatus,
        includeDeletedVoters,
        pastMonths,
        limitTo
      });
      if (res.status !== 200) {
        throw new Error(res.text);
      }
      return commit('SET_VOTERS_LIST_DATA', res.data);
    } catch (err) {
      throw err;
    }
  },
  cleanVoters({ commit }) {
    return commit('SET_VOTERS_LIST_DATA', []);
  },
  setSearchQuery({ commit }, searchQuery) {
    return commit('SET_VOTERS_SEARCH_QUERY', searchQuery);
  }
};

const mutations = {
  SET_VOTERS_LIST_DATA(state, data) {
    state.votersList = data;
  },
  SET_VOTERS_SEARCH_QUERY(state, searchQuery) {
    state.searchQuery = searchQuery;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
