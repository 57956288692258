<template>
  <v-container>
    <v-layout align-center>
      <div class="title text--darken-4">
        Advance voting
      </div>

      <v-btn
        v-if="advanceVotingEnabled"
        color="success"
        small
        @click="handleAdvanceVoting(false)"
        >Enabled</v-btn
      >
      <v-btn v-else color="error" small @click="handleAdvanceVoting(true)"
        >Disabled</v-btn
      >
    </v-layout>

    <template v-if="advanceVotingEnabled">
      <v-tabs
        v-model="activeTab"
        color="#039be5"
        dark
        slider-color="white"
        class="mt-3"
      >
        <v-tab key="1">
          Config
        </v-tab>
        <v-tab-item key="1">
          <div class="px-3 mt-4">
            <div
              class="grid-btn"
              :class="{
                'grid-btn--active': isProxyOnly
              }"
            >
              <div
                class="d-flex justify-center align-center cursor-pointer"
                @click="changeUIVotingOption('limited-proxy')"
              >
                <v-icon :color="isProxyOnly ? 'blue' : 'gray'">{{
                  isProxyOnly
                    ? 'radio_button_checked'
                    : 'radio_button_unchecked'
                }}</v-icon>
                <div class="title ml-2">Proxy only</div>
              </div>

              <div class="d-flex text-sm-left my-1 justify-center align-center">
                <div class="d-flex mr-2">
                  <v-btn
                    :disabled="isNotProxyOnly"
                    :color="UIVotingOption === 'limited-proxy' ? 'primary' : ''"
                    @click="changeUIVotingOption('limited-proxy')"
                    class="mx-0"
                    ><span class="">Limited</span></v-btn
                  >
                  <general-proxy-dropdown-button
                    :option="UIVotingOption"
                    :is-button-disabled="isNotProxyOnly"
                    option-type="general-proxy"
                    @change="changeUIVotingOption"
                  />
                </div>
              </div>
            </div>

            <div
              class="grid-btn my-4"
              :class="{
                'grid-btn--active': UIVotingOption === 'advance-ballot'
              }"
            >
              <div
                class="d-flex justify-center align-center cursor-pointer"
                @click="changeUIVotingOption('advance-ballot')"
              >
                <v-icon
                  :color="UIVotingOption === 'advance-ballot' ? 'blue' : 'gray'"
                  >{{
                    UIVotingOption === 'advance-ballot'
                      ? 'radio_button_checked'
                      : 'radio_button_unchecked'
                  }}</v-icon
                >
                <div class="title ml-2">Ballot only</div>
              </div>

              <div class="d-flex my-1 justify-center align-center">
                <div class="d-flex justify-center align-center">
                  <v-checkbox
                    v-model="openBallot"
                    @change="onChange('options.openBallot', $event)"
                    :disabled="UIVotingOption !== 'advance-ballot'"
                  />
                  <div class="mr-2">
                    Open Ballot?
                  </div>
                </div>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">info</v-icon>
                  </template>
                  <span
                    >The Open ballot checkbox will update Meeting Settings,
                    custom Proxy Template, and all future Business to serve as
                    an Open Ballot. Note: disabling this checkbox will confirm
                    settings that will be reset before proceeding.</span
                  >
                </v-tooltip>
              </div>
            </div>

            <div
              class="grid-btn my-4"
              :class="{
                'grid-btn--active': isProxyAndBallot
              }"
            >
              <div
                class="d-flex justify-center align-center cursor-pointer"
                @click="changeUIVotingOption('ballot-limited-proxy')"
              >
                <v-icon :color="isProxyAndBallot ? 'blue' : 'gray'">{{
                  isProxyAndBallot
                    ? 'radio_button_checked'
                    : 'radio_button_unchecked'
                }}</v-icon>
                <div class="title ml-2">Proxy and Ballot</div>
              </div>

              <div class="d-flex text-sm-left my-1 justify-center align-center">
                <div class="d-flex mr-2">
                  <v-btn
                    :disabled="isNotProxyAndBallot"
                    :color="
                      UIVotingOption === 'ballot-limited-proxy' ? 'primary' : ''
                    "
                    @click="changeUIVotingOption('ballot-limited-proxy')"
                    class="mx-0"
                    ><span>Limited</span></v-btn
                  >

                  <general-proxy-dropdown-button
                    :option="UIVotingOption"
                    :is-button-disabled="isNotProxyAndBallot"
                    option-type="ballot-general-proxy"
                    @change="changeUIVotingOption"
                  />
                </div>
              </div>
            </div>

            <h4 class="title grey--text text--darken-4 mt-5 mb-1">
              Quorum Settings
            </h4>

            <v-select
              :items="quorumSelections"
              item-value="value"
              item-text="label"
              chips
              class="mt-3"
              :value="quorumDecidedBy"
              :disabled="isNotProxyAndBallot"
              label="Quorum decided by?"
              @change="onChange('options.quorumDecidedBy', $event)"
            />

            <v-text-field
              label="Quorum Minimum"
              :value="quorumMinimum"
              type="number"
              class="mt-1"
              @input="onChange('quorumMinimum', $event)"
            />
          </div>
        </v-tab-item>

        <v-tab key="2">
          Template
        </v-tab>
        <v-tab-item key="2">
          <template-settings
            :template="template"
            :options="options"
            :UIVotingOption="UIVotingOption"
            :proxy-template-value="proxyTemplateValue"
            :proxy-sub-template-value="proxySubTemplateValue"
            :show-editor="showEditor"
            :template-content="templateContent"
            :render-key="renderKey"
            :custom-content="customContent"
            @onChange="onChange"
            @selectChange="selectChange"
            @updateTemplate="updateTemplate"
            @openConfirmDialog="dialog.confirm = true"
          />
        </v-tab-item>

        <v-tab
          v-if="
            UIVotingOption !== 'general-proxy-v2' &&
              UIVotingOption !== 'ballot-general-proxy-v2'
          "
          key="3"
        >
          Proxy Holders
        </v-tab>
        <v-tab-item key="3">
          <proxy-input-settings
            :default-holders="defaultHolders"
            :options="options"
            :render-key="renderKey"
            @update="onChange"
          />
        </v-tab-item>

        <v-tab
          v-if="
            UIVotingOption !== 'general-proxy-v2' &&
              UIVotingOption !== 'ballot-general-proxy-v2'
          "
          key="4"
        >
          Settings
        </v-tab>
        <v-tab-item key="4">
          <voting-settings
            :template="template"
            :options="options"
            :UIVotingOption="UIVotingOption"
            @onChange="onChange"
          />
        </v-tab-item>
      </v-tabs>
    </template>

    <open-ballot-checked-dialog
      @confirm="openBallotCheckedAction()"
      @close="
        dialog.openBallotChecked = false;
        openBallot = !openBallot;
      "
      :dialog="dialog.openBallotChecked"
    />

    <open-ballot-unchecked-dialog
      @confirm="openBallotUncheckedAction()"
      @close="
        dialog.openBallotUnchecked = false;
        openBallot = !openBallot;
      "
      :dialog="dialog.openBallotUnchecked"
    />

    <confirm-dialog
      title="Are you sure?"
      text="The action (Fill sample form) will overwrite the existing changes."
      :dialog="dialog.confirm"
      confirm-text="Yes"
      decline-text="No"
      @confirm="confirmFillSampleAction($event)"
      @close="dialog.confirm = false"
    />
  </v-container>
</template>

<script>
import _ from 'lodash';
import { form } from '@/lib/default-proxy-templates.js';
import RichTextEditor from '@/components/RichTextEditor/RichTextEditor';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog';
import OpenBallotCheckedDialog from '@/components/dialogs/OpenBallotCheckedDialog';
import OpenBallotUncheckedDialog from '@/components/dialogs/OpenBallotUncheckedDialog';

import ProxyInputSettings from '@/components/ProxyInputSettings';
import VotingSettings from '@/components/VotingSettings';
import TemplateSettings from '@/components/TemplateSettings';
import GeneralProxyDropdownButton from '@/components/GeneralProxyDropdownButton';

import defaultContent from '@/lib/default-general-proxy-content';

import { getRecipe } from '@/api/recipes';

export default {
  name: 'AdvanceVotingV2',
  components: {
    RichTextEditor,
    ConfirmDialog,
    OpenBallotCheckedDialog,
    OpenBallotUncheckedDialog,
    VotingSettings,
    ProxyInputSettings,
    TemplateSettings,
    GeneralProxyDropdownButton
  },
  props: {
    template: {
      type: Object,
      default() {
        return {};
      }
    },
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    defaultHolders: {
      type: Array,
      default() {
        return [];
      }
    },
    quorumMinimum: {
      type: [String, Number],
      default: 0
    }
  },
  data() {
    return {
      openBallot: false,
      templateContent: '',
      renderKey: 0,
      showEditor: false,
      proxyTemplateValue: null,
      proxySubTemplateValue: null,
      dialog: {
        confirm: false,
        openBallotChecked: false,
        openBallotUnchecked: false
      },
      UIVotingOption: null,
      advanceVotingEnabled: false,
      customContent: '',
      activeTab: null,
      quorumSelections: [
        { label: 'Proxy', value: 'proxy' },
        { label: 'Ballot', value: 'ballot' },
        { label: 'Proxy and Ballot', value: 'proxy-ballot' }
      ],
      quorumDecidedBy: null
    };
  },
  async mounted() {
    this.openBallot = this.options.openBallot;
    this.advanceVotingEnabled =
      this.options.advanceVotingEnabled === false ? false : true;

    this.customContent = _.get(
      this.options,
      'customGeneralProxyContent',
      defaultContent
    );

    this.UIVotingOption = this.handleUIVotingOption();

    this.proxyTemplateValue = this.getProxyTemplateValue();
    this.proxySubTemplateValue = this.options.proxySubTemplate || 'general';
    this.templateContent = this.customProxyTemplate;
    this.showEditor =
      this.proxyTemplateValue === 'custom' ||
      this.proxyTemplateValue === 'alberta';

    this.quorumDecidedBy = this.options.quorumDecidedBy;
  },
  watch: {
    options() {
      this.UIVotingOption = this.handleUIVotingOption();
    },
    UIVotingOption() {
      this.quorumDecidedBy = this.handleQuorumDecidedBy();
    },
    activeTab() {
      if (this.activeTab === 2) {
        this.renderKey++;
      }
    }
  },
  computed: {
    customProxyTemplate() {
      return this.proxyTemplateValue
        ? this.template.content
          ? this.template.content
          : `${this.template.header}${this.template.to}${this.template.proxyBody}${this.template.terms}${this.template.termsEmail}${this.template.signature}${this.template.footer}`
        : '';
    },
    rtvBallotsNotionDoc() {
      return process.env.VUE_APP_NOTION_DOC_RTV_BALLOTS || '';
    },
    isNotProxyAndBallot() {
      return (
        this.UIVotingOption !== 'ballot-limited-proxy' &&
        this.UIVotingOption !== 'ballot-general-proxy-v1' &&
        this.UIVotingOption !== 'ballot-general-proxy-v2'
      );
    },
    isProxyAndBallot() {
      return (
        this.UIVotingOption === 'ballot-limited-proxy' ||
        this.UIVotingOption === 'ballot-general-proxy-v1' ||
        this.UIVotingOption === 'ballot-general-proxy-v2'
      );
    },
    isProxyOnly() {
      return (
        this.UIVotingOption === 'limited-proxy' ||
        this.UIVotingOption === 'general-proxy-v1' ||
        this.UIVotingOption === 'general-proxy-v2'
      );
    },
    isNotProxyOnly() {
      return (
        this.UIVotingOption !== 'limited-proxy' &&
        this.UIVotingOption !== 'general-proxy-v1' &&
        this.UIVotingOption !== 'general-proxy-v2'
      );
    }
  },
  methods: {
    getProxyTemplateValue() {
      if (_.has(this.options, 'proxyTemplate')) {
        return this.options.proxyTemplate;
      }
      return null;
    },
    selectChange(value) {
      this.proxyTemplateValue = value;
      switch (value) {
        case 'alberta':
          this.showEditor = true;
          this.updateTemplate('general');
          break;
        case 'custom':
          this.templateContent = this.customProxyTemplate;
          this.renderKey++;
          this.showEditor = true;
          break;
        default:
          this.showEditor = false;
          break;
      }
      this.onChange('options.proxyTemplate', value);
    },
    async updateTemplate(value) {
      try {
        this.onChange('options.proxySubTemplate', value);
        const recipe = await getRecipe(
          `meetings.settings.proxy_template.${this.proxyTemplateValue}_${value}`
        );
        const recipeData = recipe.data.data;

        this.templateContent = recipeData.default;
        this.onChange('proxyTemplate.content', this.templateContent);
        this.renderKey++;
      } catch (err) {
        console.error('Error getting alberta templates', err);
      }
    },
    onChange(key, value) {
      if (key === 'options.displayCustomGeneralProxyContent' && value) {
        this.$emit('update', {
          key: 'options.customGeneralProxyContent',
          value: this.customContent
        });
      }

      if (key === 'options.openBallot' && value === true) {
        this.dialog.openBallotChecked = true;
        return;
      } else if (key === 'options.openBallot' && value === false) {
        this.dialog.openBallotUnchecked = true;
      }

      this.$emit('update', { key, value });
    },
    getFormObj(formNum) {
      let formObj = _.find(form, function(o) {
        return o.formNum === formNum;
      });
      return formObj;
    },
    fillFormDefault(num) {
      let formObj = this.getFormObj(num);

      let template = '';
      for (let prop in formObj) {
        if (formObj.hasOwnProperty(prop)) {
          if (prop === 'formNum') {
            continue;
          }
          template += formObj[prop];
        }
      }
      this.onChange('proxyTemplate.content', template);
      this.templateContent = template;
      this.renderKey++;
    },
    setProxyTemplate(value) {
      this.onChange('options.proxyTemplate', value);
    },

    confirmFillSampleAction(value) {
      if (value) {
        this.fillFormDefault(8);
      }

      this.dialog.confirm = false;
    },
    async openBallotCheckedAction() {
      try {
        this.openBallot = true;
        const recipe = await getRecipe('meetings.settings.open_ballot');
        const template = recipe.data.data.Meeting.proxyTemplate;

        this.proxyTemplateValue = 'custom';
        this.onChange('options.proxyTemplate', 'custom');

        const templateContent = `${template.header}${template.to}${template.proxyBody}${template.terms}${template.termsEmail}${template.signature}${template.footer}`;
        this.renderKey++;

        this.templateContent = templateContent;
        this.onChange('proxyTemplate.content', templateContent);

        this.showEditor = true;

        this.$emit('update', { key: 'options.openBallot', value: true });
        this.dialog.openBallotChecked = false;
      } catch (err) {
        console.log(err);
      }
    },
    openBallotUncheckedAction() {
      this.openBallot = false;
      this.selectChange('on-bill-106');

      this.$emit('update', { key: 'options.openBallot', value: false });
      this.dialog.openBallotUnchecked = false;
    },

    handleQuorumDecidedBy() {
      let value = '';
      if (
        this.UIVotingOption === 'limited-proxy' ||
        this.UIVotingOption === 'general-proxy-v1' ||
        this.UIVotingOption === 'general-proxy-v2'
      ) {
        value = 'proxy';
      } else if (this.UIVotingOption === 'advance-ballot') {
        value = 'ballot';
      } else {
        value = this.options.quorumDecidedBy;
      }
      this.onChange('options.quorumDecidedBy', value);

      return value;
    },

    handleUIVotingOption() {
      let option = 'none';
      if (this.options.votingOption === 'Ballot') {
        option = 'advance-ballot';
      } else if (this.options.votingOption === 'Proxy') {
        if (this.options.useGeneralProxyApp === true) {
          option = 'general-proxy-v2';
        } else if (this.options.useGeneralProxyApp === false) {
          option = 'general-proxy-v1';
        } else {
          option = 'limited-proxy';
        }
      } else if (this.options.votingOption === 'Proxy-Ballot') {
        if (this.options.useGeneralProxyApp === true) {
          option = 'ballot-general-proxy-v2';
        } else if (this.options.useGeneralProxyApp === false) {
          option = 'ballot-general-proxy-v1';
        } else {
          option = 'ballot-limited-proxy';
        }
      }

      return option;
    },

    toggleGeneralProxyApp(value) {
      if (value === 'general-proxy-v2' || value === 'ballot-general-proxy-v2') {
        this.onChange('options.useGeneralProxyApp', true);
      } else if (
        value === 'limited-proxy' ||
        value === 'ballot-limited-proxy'
      ) {
        this.onChange('options.useGeneralProxyApp', undefined);
      } else {
        this.onChange('options.useGeneralProxyApp', false);
      }
    },

    toggleOverrideAndCollectBallots(value) {
      if (
        value === 'ballot-limited-proxy' ||
        value === 'ballot-general-proxy-v1' ||
        value === 'ballot-general-proxy-v2'
      ) {
        this.onChange('options.enableRtvProxyOverride', true);
        this.onChange('options.collectRtvBallots', true);
      } else {
        this.onChange('options.enableRtvProxyOverride', false);
        this.onChange('options.collectRtvBallots', false);
      }
    },
    changeUIVotingOption(value) {
      this.UIVotingOption = value;

      this.toggleGeneralProxyApp(value);
      this.toggleOverrideAndCollectBallots(value);
      switch (value) {
        case 'advance-ballot':
          this.onChange('options.votingOption', 'Ballot');
          return;
        case 'limited-proxy':
        case 'general-proxy-v1':
        case 'general-proxy-v2':
          this.onChange('options.votingOption', 'Proxy');
          return;
        case 'ballot-limited-proxy':
        case 'ballot-general-proxy-v1':
        case 'ballot-general-proxy-v2':
          this.onChange('options.votingOption', 'Proxy-Ballot');
          return;
        default:
          return;
      }
    },
    handleAdvanceVoting(value) {
      this.advanceVotingEnabled = value;
      this.onChange('options.advanceVotingEnabled', value);
    }
  }
};
</script>

<style lang="scss" scoped>
.grid-btn {
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  border: thin solid grey;
  border-radius: 4px;

  &--active {
    background: rgb(231, 242, 251);
    border: thin solid rgb(186, 201, 214);
  }

  .title {
    line-height: 24px;
    font-size: 17px !important;
  }

  .align-bottom {
    margin-top: auto;
  }
}

.cursor-pointer {
  cursor: pointer;
}
</style>
