<template>
  <v-dialog max-width="800px" :value="value" @input="emitInput">
    <v-card>
      <v-card-title>
        <span class="title">Add New Notice</span>
        <v-spacer />
        <v-flex class="text-xs-right" xs6>
          <!-- Filter by Notice Type -->

          <v-menu offset-y transition="scale-transition">
            <v-btn slot="activator" light flat color="blue">
              <v-icon>filter_list</v-icon>filter
            </v-btn>
            <v-list
              dense
              v-for="template in filterGroups"
              :key="template.group"
            >
              <v-list-tile @click="filterBy(template.group)">
                <v-list-tile-content>
                  <v-chip
                    :outline="!isTemplateFilter(template.group)"
                    label
                    small
                    class="white--text"
                    :color="getChipColor(template.group)"
                    ><strong>{{ template.group }}</strong></v-chip
                  >
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-menu>
        </v-flex>
        <v-flex xs1>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn-toggle @change="setPrimary" light class="grey lighten-3">
                <v-btn flat color="blue lighten-1" v-on="on">
                  <v-icon>stars</v-icon>
                </v-btn>
              </v-btn-toggle>
            </template>
            <span>{{ filterPrimary ? 'Primary only ' : 'All templates' }}</span>
          </v-tooltip>
        </v-flex>
      </v-card-title>

      <v-card-text>
        <v-combobox
          :items="sortedNoticeSamples"
          :value="selected.name"
          item-text="name"
          item-value="name"
          label="Select Notice Template"
          @input="setSampleNotice($event)"
        >
          <template v-slot:item="data">
            <v-layout align-center class="pa-0">
              <v-flex
                shrink
                class="pr-2"
                v-if="data.item.segment.startsWith('mc-')"
              >
                <v-chip small label>
                  multi-consent
                </v-chip>
              </v-flex>

              <v-flex shrink class="pr-2">
                <v-chip
                  v-if="hasTemplateType(data.item.meta)"
                  label
                  small
                  class="white--text"
                  :color="getChipColor(data.item.meta.templateType)"
                >
                  <strong>{{ data.item.meta.templateType }}</strong>
                </v-chip>
              </v-flex>

              <v-flex>
                {{ data.item.name }}
                <v-icon class="pl-2" small v-if="data.item.primaryNotice"
                  >stars</v-icon
                >
              </v-flex>
            </v-layout>
          </template>
        </v-combobox>

        <div v-if="isSelected">
          <div class="subject">
            <strong>Subject line:</strong> {{ selected.subject }}
          </div>
          <div class="html-preview" v-html="selected.body" />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.stop="emitClose">Go back</v-btn>
        <v-btn
          class="primary"
          @click.native="emitAdd(selected)"
          :disabled="!isSelected"
          >Add</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { findSampleNoticeTemplate } from '@/lib/make-notice-object';
import { getChecklistColor } from '@/lib/checklist-helpers';
import _ from 'lodash';
import moment from 'moment';

export default {
  name: 'AddNoticeDialog',
  props: {
    noticeSamples: {
      type: Array,
      default: function() {
        return [];
      }
    },
    currentMeeting: {
      type: Object,
      default() {
        return {};
      }
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isSelected() {
      return !_.isEmpty(this.selected);
    },
    currentNoticeSamples() {
      return this.noticeSamples;
    },
    sortedNoticeSamples() {
      let filteredTemplates = this.currentNoticeSamples;

      // Filter for primary templates only
      if (this.filterPrimary) {
        filteredTemplates = _.filter(filteredTemplates, {
          primaryNotice: true
        });
      }

      // Filter by notice type
      if (this.filterNoticeType) {
        filteredTemplates = _.filter(filteredTemplates, template => {
          if (template.meta.templateType === this.filterNoticeType) {
            return template;
          }
        });
      }
      return _.sortBy(filteredTemplates, ['meta.templateType', 'name']);
    }
  },
  data() {
    return {
      filterGroups: [
        { group: 'cs-onboard', colour: 'green' },
        { group: 'pre-consent', colour: 'orange' },
        { group: 'pre-notice', colour: 'blue' },
        { group: 'service', colour: 'red' },
        { group: 'vm', colour: 'purple' },
        { group: 'other', colour: 'grey' }
      ],
      selected: {},
      filterPrimary: false,
      filterNoticeType: ''
    };
  },
  methods: {
    resetSelected() {
      this.selected = {};
    },
    setPrimary() {
      this.filterPrimary = !this.filterPrimary;
      this.resetSelected();
    },
    isTemplateFilter(templateType) {
      return this.filterNoticeType === templateType;
    },
    replaceSubjectMergeFields(subject) {
      // Define the mergeField array structure
      let mergeFieldsArray = [
        {
          name: '[CORP_NAME]',
          value: _.get(this.currentMeeting, 'corporation.legalNameShort', '')
        },
        {
          name: '[MTG_DATE]',
          value: this.formatDate(_.get(this.currentMeeting, 'meetingDate', ''))
        }
      ];

      mergeFieldsArray.forEach(function(field) {
        subject = subject.replace(field.name, field.value);
      });
      return subject;
    },
    formatDate(date) {
      if (!date) {
        return '';
      }
      return moment(date).format('MMMM Do, YYYY');
    },
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    },
    emitAdd(selected) {
      this.$emit('add', selected);
    },
    setSampleNotice(notice) {
      if (notice) {
        let sampleNotice = findSampleNoticeTemplate(
          this.noticeSamples,
          notice.name,
          this.shortCode
        );

        sampleNotice.subject = this.replaceSubjectMergeFields(
          sampleNotice.subject
        );

        this.selected = sampleNotice;
      }
    },
    hasTemplateType(meta) {
      return _.get(meta, 'templateType', false);
    },
    getChipColor(templateType) {
      return getChecklistColor(templateType);
    },
    filterBy(noticeType) {
      if (this.filterNoticeType === noticeType) {
        this.filterNoticeType = '';
      } else {
        this.filterNoticeType = noticeType;
      }
      this.resetSelected();
    }
  }
};
</script>

<style scoped>
.html-preview {
  display: block;
  padding: 10px;
  border: none;
  border: 1px solid #ccc;
  resize: none;
  outline: none;
  background-color: #f6f6f6;
  font-size: 10px;
  font-family: 'Monaco', courier, monospace;

  overflow-y: scroll;
  max-height: 28vh;
}
.subject {
  background-color: #eceff1;
  color: #0d47a1;
  font-size: 12px;
  padding: 9px;
}
</style>
