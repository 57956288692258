<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="logs"
      class="elevation-0"
      disable-initial-sort
      :loading="loading"
    >
      <v-progress-linear color="blue" indeterminate />
      <template slot="items" slot-scope="props">
        <tr class="table-item">
          <td class="nowrap">
            {{
              props.item.lastModified
                | dateFormatReadable(true, meetingTimezone)
            }}
          </td>
          <td>
            <v-tooltip bottom>
              <span slot="activator">
                <v-icon small class="mr-1" @click="handleDownload(props.item)">
                  get_app
                </v-icon>
                <a @click="handleDownload(props.item)">{{
                  props.item.name || props.item.Key
                }}</a>
              </span>
              <span>Click to download</span>
            </v-tooltip>
          </td>
          <td class="nowrap">
            {{ props.item.size | fileSize }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';
import { dateFormatReadable } from '@/filters';
import filesize from 'filesize';

export default {
  name: 'MeetingLogsTable',
  props: {
    logs: {
      type: Array,
      default: () => {
        return [];
      }
    },
    meetingTimezone: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    shortCode: {
      type: String,
      default: ''
    }
  },
  filters: {
    dateFormatReadable,
    fileSize(size) {
      return filesize(size, { base: 10, round: 0 });
    }
  },
  data() {
    return {
      headers: [
        { text: 'Modified', value: 'lastModified' },
        { text: 'Name', value: 'name' },
        { text: 'Size', value: 'size' }
      ]
    };
  },
  methods: {
    ...mapActions('v2/meetingsFiles', ['downloadMeetingFile']),
    async handleDownload({ Key, name }) {
      try {
        await this.downloadMeetingFile({
          shortCode: this.shortCode,
          Key,
          name
        });
        this.$events.$emit('toastEvent', 'Downloading File');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    }
  }
};
</script>

<style scoped></style>
