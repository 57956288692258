<template>
  <v-card height="100%">
    <v-card-title primary-title>
      <div class="title font-weight-bold">{{ report.title }}</div>

      <!-- Chips only visible to admin -->
      <div class="ml-2" v-if="isAdmin">
        <v-chip
          v-if="isAdminReport"
          color="blue lighten-5"
          class="blue--text font-weight-bold"
          label
          small
          disabled
        >
          Admin
        </v-chip>
        <v-chip
          v-if="isClientReport"
          color="green lighten-5"
          class="green--text font-weight-bold"
          label
          small
          disabled
        >
          Client
        </v-chip>
        <v-chip
          v-if="isBetaReport"
          color="orange lighten-5"
          class="orange--text font-weight-bold"
          label
          small
          disabled
        >
          Beta
        </v-chip>
      </div>
    </v-card-title>

    <v-card-text class="pb-1">
      <div class="body-2 grey--text text--darken-3">
        {{ report.description }}
      </div>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn flat :color="buttonColor" @click.native="clickDownloadReport">
        {{ action }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ReportCard',
  props: {
    report: {
      type: Object,
      default() {
        return {
          reportType: '',
          title: '',
          description: '',
          adminOnly: false,
          background: false
        };
      }
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
    reportType() {
      return this.report.reportType;
    },
    action() {
      return this.report.background ? 'Email it to me' : 'Download';
    },
    buttonColor() {
      return 'primary';
    },
    isAdminReport() {
      return this.report?.adminOnly;
    },
    isClientReport() {
      return this.report?.client;
    },
    isBetaReport() {
      return this.report?.beta;
    }
  },
  methods: {
    clickDownloadReport() {
      const action = this.report.background ? 'export' : 'download';
      return this.$emit('handleClick', {
        action,
        reportType: this.reportType
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.v-card {
  display: flex;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow-y: auto;
}

.v-card__actions {
  margin-top: auto;
  flex-shrink: 0;
}
</style>
