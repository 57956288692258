import http from './requestType';

/**
 * Get a list of voters by search term
 * @param  {string} searchText            - search text
 * @param  {string} type                  - type
 * @param  {string} meetingDate           - upcoming | past
 * @param  {string} meetingStatus         - non-test | test
 * @param  {Boolean} includeDeletedVoters - false | true
 * @param  {Boolean} pastMonths           - 6 | 12 | 36 | all
 * @param  {Boolean} limitTo              - 5 | 20 | 50 | all: ;
 * @return {Promise}              - Promise
 */
export function getVoters({
  searchText,
  type = 'email',
  meetingDate,
  meetingStatus,
  includeDeletedVoters,
  pastMonths,
  limitTo
}) {
  const params = {
    searchText,
    type,
    meetingDate,
    meetingStatus,
    includeDeletedVoters,
    pastMonths,
    limitTo
  };
  return http._get(`Voters`, params);
}
