<template>
  <div class="mt-4">
    <div class="px-3">
      <div class="title grey--text text--darken-4 mb-1">Proxy Options</div>
      <div class="body-1 grey--text text--darken-2 mb-4">
        Displayed on proxy form home page
      </div>

      <v-layout>
        <div class="pr-3">
          <v-checkbox
            :true-value="true"
            :false-value="false"
            v-model="electionsRanking"
            @change="onChange('options.electionsRanking', $event)"
          />
        </div>
        <div>
          <h5 class="subheading">
            Allow Full Ranking for Elections
          </h5>
          <div class="grey--text text--darken-2 mb-3">
            <ul>
              <li>
                Allow an owner to rank each available candidate while filling
                out their proxy
              </li>
              <li>
                If CHECKED, owners can rank all candidates listed, regardless of
                the number of positions available
              </li>
              <li>
                If UNCHECKED, the number of choices given will be the number of
                positions available
              </li>
            </ul>
          </div>
        </div>
      </v-layout>

      <v-layout>
        <div class="pr-3">
          <v-checkbox
            v-model="blockUnverifiedVoters"
            @change="onChange('options.blockUnverifiedVoters', $event)"
          />
        </div>
        <div>
          <div class="subheading">Block Unverified Voters</div>
          <div class="grey--text text--darken-2">
            Prevents unverified voters from being able to get past the home page
            on the voting tool.
          </div>
        </div>
      </v-layout>

      <v-layout>
        <div class="pr-3">
          <v-checkbox
            v-model="blockVoteRecasting"
            @change="onChange('options.blockVoteRecasting', $event)"
          />
        </div>
        <div>
          <div class="subheading">Block Vote Re-casting</div>
          <div class="grey--text text--darken-2">
            Do not allow voters to re-cast their votes (ie. cannot change their
            minds later). If a voter has a verified submission, it must be
            revoked before they can vote again. Use this setting for e-ballots,
            avoid using for proxies.
          </div>
        </div>
      </v-layout>

      <v-layout class="mt-3">
        <div class="pr-3">
          <v-checkbox
            v-model="blockUnitVoteRecasting"
            @change="onChange('options.blockUnitVoteRecasting', $event)"
          />
        </div>
        <div>
          <div class="subheading">Block Unit Vote Re-casting</div>
          <div class="grey--text text--darken-2">
            Do not allow voters to re-cast their votes if there is a submission
            for their unit (ie. neither Voter A nor Voter B can re-cast their
            votes if one of them has a prior submission for their shared unit).
            If a unit voter has a verified submission, it must be revoked before
            either can vote again. Use this setting for e-ballots, avoid using
            for proxies.
          </div>
        </div>
      </v-layout>

      <v-layout class="mt-3">
        <div class="pr-3">
          <v-checkbox
            v-model="disableSubmissionDownloads"
            @change="
              onChange('options.disableProxySubmissionDownloads', $event)
            "
          />
        </div>
        <div>
          <div class="subheading">
            Prevent voters from downloading their proxy submissions
          </div>
          <div class="grey--text text--darken-2">
            Click this to deny voters the ability to download a copy of their
            submission, once their proxy is completed.
          </div>
        </div>
      </v-layout>

      <v-layout>
        <div class="pr-3">
          <v-checkbox
            v-model="disableProxyDeferrals"
            @change="onChange('options.disableProxyDeferrals', $event)"
          />
        </div>
        <div>
          <div class="subheading">
            Disable Proxy Deferrals
          </div>
          <div class="grey--text text--darken-2">
            Click this to remove the "My Proxy Can Choose" and/or "I give my
            proxy authority to vote" options from all matters of business.
            Recommended for Virtual Meetings.
          </div>
        </div>
      </v-layout>

      <v-layout class="mt-3">
        <div class="pr-3">
          <v-checkbox
            v-model="enablePaperProxiesAlert"
            @change="onChange('options.enablePaperProxiesAlert', $event)"
          />
        </div>
        <div>
          <div class="subheading">
            Enable Paper Proxies Upload Alert
          </div>
          <div class="grey--text text--darken-2">
            Click this option to enable email alert
          </div>
        </div>
      </v-layout>

      <v-layout class="ml-5" v-if="enablePaperProxiesAlert">
        <div class="pr-3">
          <v-checkbox
            v-model="includeAttachmentsInPaperProxiesAlert"
            @change="
              onChange('options.includeAttachmentsInPaperProxiesAlert', $event)
            "
          />
        </div>

        <div>
          <div class="subheading">
            Include attachments
          </div>
          <div class="grey--text text--darken-2">
            Toggle this to include/exclude attachments for Paper proxy alert
          </div>
        </div>
      </v-layout>
    </div>

    <options-input-settings :options="options" @update="onChange" />
  </div>
</template>

<script>
import _ from 'lodash';

import RichTextEditor from '@/components/RichTextEditor/RichTextEditor';
import OptionsInputSettings from '@/components/OptionsInputSettings';

export default {
  name: 'MeetingSettingsLimitedProxyAppOptions',
  components: {
    RichTextEditor,
    OptionsInputSettings
  },

  props: {
    template: {
      type: Object,
      default() {
        return {};
      }
    },
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    UIVotingOption: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      blockUnverifiedVoters: false,
      blockVoteRecasting: false,
      blockUnitVoteRecasting: false,
      disableSubmissionDownloads: false,
      disableProxyDeferrals: false,
      electionsRanking: false,
      enablePaperProxiesAlert: false,
      includeAttachmentsInPaperProxiesAlert: false
    };
  },

  async mounted() {
    this.blockUnverifiedVoters = this.options.blockUnverifiedVoters;
    this.blockVoteRecasting = this.options.blockVoteRecasting;
    this.blockUnitVoteRecasting = _.get(
      this.options,
      'blockUnitVoteRecasting',
      false
    );
    this.electionsRanking = this.options.electionsRanking;
    this.disableSubmissionDownloads = this.options.disableProxySubmissionDownloads;
    this.disableProxyDeferrals = this.options.disableProxyDeferrals;
    this.enablePaperProxiesAlert = this.options.enablePaperProxiesAlert;
    this.includeAttachmentsInPaperProxiesAlert = this.options.includeAttachmentsInPaperProxiesAlert;
  },

  methods: {
    onChange(key, event) {
      this.$emit('onChange', key, event);
    }
  }
};
</script>

<style scoped></style>
