var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":"","grid-list-lg":""}},[_c('v-layout',{staticClass:"mb-3",attrs:{"row":""}},[_c('v-flex',[_c('v-text-field',{attrs:{"prepend-inner-icon":"search","label":"Search reports","solo":"","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1),(_vm.isAdmin)?[_c('v-tabs',{attrs:{"slider-color":"primary"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab',{attrs:{"href":"#tab-1"}},[_vm._v(" Default (V1) ")]),_c('v-tab',{attrs:{"href":"#tab-2"}},[_vm._v(" Admin (V2) ")]),_c('v-tab',{attrs:{"href":"#tab-3"}},[_vm._v(" Client (V1/V2) ")]),_c('v-tab-item',{staticClass:"mt-3",attrs:{"value":"tab-1"}},[_c('v-layout',{attrs:{"row":"","wrap":""}},_vm._l((_vm.reportList),function(report,index){return _c('v-flex',{key:`key-${index}`,attrs:{"xs12":"","sm6":"","md4":""}},[_c('report-card-v2',{attrs:{"report":report},on:{"handleClick":_vm.handleReportClick}})],1)}),1)],1),_c('v-tab-item',{staticClass:"mt-3",attrs:{"value":"tab-2"}},[_c('v-layout',{attrs:{"row":"","wrap":""}},_vm._l((_vm.adminReportListV2),function(report,index){return _c('v-flex',{key:`key-${index}`,attrs:{"xs12":"","sm6":"","md4":""}},[_c('report-card-v2',{attrs:{"report":report},on:{"handleClick":_vm.handleReportClickV2}})],1)}),1)],1),_c('v-tab-item',{staticClass:"mt-3",attrs:{"value":"tab-3"}},[_c('v-layout',{attrs:{"row":"","wrap":""}},_vm._l((_vm.clientReportList),function(report,index){return _c('v-flex',{key:`key-${index}`,attrs:{"xs12":"","sm6":"","md4":""}},[_c('report-card-v2',{attrs:{"report":report},on:{"handleClick":$event =>
                  report.reportType.includes('v2')
                    ? _vm.handleReportClickV2($event)
                    : _vm.handleReportClick($event)}})],1)}),1)],1)],1)]:[_c('v-layout',{staticClass:"mt-3",attrs:{"row":"","wrap":""}},_vm._l((_vm.clientReportList),function(report,index){return _c('v-flex',{key:`key-${index}`,attrs:{"xs12":"","sm6":"","md4":""}},[_c('report-card-v2',{attrs:{"report":report},on:{"handleClick":$event =>
              report.reportType.includes('v2')
                ? _vm.handleReportClickV2($event)
                : _vm.handleReportClick($event)}})],1)}),1)],_c('export-files-dialog',{attrs:{"is-open":_vm.showExportDialog,"processing":_vm.isExportProcessing,"error":_vm.exportDialogError,"email-to":_vm.login.profile.email},on:{"close-dialog":_vm.closeExportDialog}}),_c('voter-registration-report-dialog',{attrs:{"is-open":_vm.showVoterRegistrationDialog},on:{"send":_vm.sendVoterRegistrationReport,"close":function($event){_vm.showVoterRegistrationDialog = false}}}),_c('user-guide-report-type-dialog',{attrs:{"is-open":_vm.showUserGuideDialog},on:{"download":_vm.downloadGuide,"close":function($event){_vm.showUserGuideDialog = false}}}),_c('format-option-dialog',{attrs:{"is-open":_vm.showFormatOptionDialog,"format-option":_vm.formatOption},on:{"close":function($event){_vm.showFormatOptionDialog = false},"download":_vm.downloadUnitOverviewReport,"send":_vm.sendConsentReport}}),_c('print-distribution-option-dialog',{attrs:{"is-open":_vm.showPrintOptionDialog,"report-type":_vm.reportTypeSelected},on:{"action":_vm.handlePrintAction,"close":function($event){_vm.showPrintOptionDialog = false}}}),_c('consent-distribution-dialog-v2',{attrs:{"is-open":_vm.dialogsV2['consent-distribution-v2'],"report-type":_vm.reportTypeSelected},on:{"action":_vm.handleReportActionV2,"close":function($event){_vm.dialogsV2['consent-distribution-v2'] = false}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }