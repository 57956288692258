<template>
  <v-dialog persistent :value="value" @input="emitInput" max-width="1100px">
    <v-card v-if="!state.success">
      <v-card-title primary-title>
        <div>
          <h3 class="headline mb-0">
            Create Multi-Notices {{ this.state.groupName }}
          </h3>
          <v-spacer />
          <div>
            Please review the scheduled dates for the notices before
            submitting/scheduling
          </div>
        </div>
        <v-spacer />
        <div>
          <v-btn icon flat @click.stop="emitClose"
            ><v-icon>close</v-icon></v-btn
          >
        </div>
      </v-card-title>
      <v-card-text class="mt-0 pt-0">
        <div class="text-xs-center">
          <v-layout row v-if="state.error">
            <v-flex xs12 class="ma-2">
              <v-icon color="red">warning</v-icon> {{ this.state.errorMsg }}
            </v-flex>
          </v-layout>
        </div>
      </v-card-text>
      <v-card-text class="mt-0 pt-0">
        <v-list class="ml-2">
          <v-list-tile>
            <v-flex xs5>
              <v-icon
                small
                class="v-icon mr-3 material-icons theme--light green--text"
                >event</v-icon
              >Start date
            </v-flex>
            <v-flex xs6>
              <date-time-pickers
                label="Start date"
                :iso-date="state.startDate"
                :timezone="meetingTimezone"
                :show-clear-btn="true"
                :autoclose="true"
                time-menu-direction="top"
                @update="updateStartDate($event)"
              />
            </v-flex>
          </v-list-tile>
          <v-list-tile
            v-for="(reminder, index) in this.state.reminders"
            :key="index"
          >
            <v-flex xs5>
              <v-icon small class="v-icon mr-3 material-icons theme--light"
                >email</v-icon
              >{{ reminder.name }}
            </v-flex>
            <v-flex xs6>
              <date-time-pickers
                label="What is the planned send date?"
                :iso-date="reminder.sendOn"
                :timezone="meetingTimezone"
                :show-clear-btn="true"
                :autoclose="true"
                time-menu-direction="top"
                @update="updateReminderDate(index, $event)"
              />
            </v-flex>
            <v-flex xs1>
              <v-list-tile-action>
                <v-spacer />
                <v-btn
                  v-if="!reminder.sent"
                  flat
                  icon
                  @click.native="removeNotice(index)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-list-tile-action>
            </v-flex>
          </v-list-tile>
          <v-list-tile>
            <v-flex xs5>
              <v-icon
                small
                class="v-icon mr-3 material-icons theme--light blue--text"
                >event</v-icon
              >End Date
            </v-flex>
            <v-flex xs6>
              <date-time-pickers
                label="End Date"
                :iso-date="state.endDate"
                :timezone="meetingTimezone"
                :show-clear-btn="true"
                time-menu-direction="top"
                :is-disabled="false"
                @update="updateEndDate($event)"
              />
            </v-flex>
          </v-list-tile>
          <v-list-tile>
            <v-flex xs5></v-flex>
            <v-flex xs6>
              <v-switch v-model="updateDirection" :label="`${dateDirection}`">
              </v-switch>
            </v-flex>
          </v-list-tile>
        </v-list>
      </v-card-text>
      <v-toolbar light class="grey lighten-4 elevation-1">
        <template class="buttons-slot">
          <div>
            <v-menu offset-y>
              <v-btn slot="activator" light flat color="blue">
                <v-icon>add</v-icon>Add Notice
              </v-btn>
              <v-list>
                <v-list-tile
                  v-for="item in state.filteredNoticeTemplateList"
                  @click="addTemplate(item.noticeTemplateKey)"
                  :key="item.noticeTemplateKey"
                >
                  <v-list-tile-content>
                    <v-list-tile-title>
                      <v-chip
                        small
                        label
                        class="mr-2"
                        v-if="item.segment.startsWith('mc-')"
                      >
                        multi-consent
                      </v-chip>

                      {{ item.name }}</v-list-tile-title
                    >
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
            </v-menu>
            <v-menu offset-y>
              <v-btn slot="activator" light flat color="blue">
                <v-icon>add</v-icon>Notice Group
              </v-btn>
              <v-list>
                <v-list-tile
                  v-for="item in currentNoticeGroupList"
                  @click="updateNoticeGroup(item.noticeTemplateGroupKey)"
                  :key="item.noticeTemplateGroupKey"
                >
                  <v-list-tile-content>
                    <v-list-tile-title>
                      <v-chip
                        small
                        label
                        class="mr-2 ml-0"
                        v-if="
                          item.meta &&
                            item.meta.consentFormat === 'multi-consent'
                        "
                      >
                        multi-consent
                      </v-chip>

                      {{ item.name }}</v-list-tile-title
                    >
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
            </v-menu>
          </div>
        </template>
        <v-spacer />
        <template class="buttons-slot">
          <v-btn @click.native="generateNoticeSchedule"
            ><v-icon>refresh</v-icon>Generate Dates
          </v-btn>
          <v-btn
            class="primary"
            @click.native="scheduleNotices"
            :disabled="isSchedulerDisabled"
          >
            <v-icon left>send</v-icon>Schedule Reminders
          </v-btn>
        </template>
      </v-toolbar>
    </v-card>
    <v-card v-if="state.success">
      <v-card-title primary-title>
        <div>
          <h3 class="headline mb-0">
            Notices have been scheduled! ({{ state.scheduledNotices.length }} of
            {{ state.reminders.length }})
          </h3>
        </div>
        <v-spacer />
        <template class="buttons-slot">
          <v-btn class="primary" @click="emitClose"
            ><v-icon left>check</v-icon>All Done
          </v-btn>
        </template>
      </v-card-title>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import * as filters from '@/filters';
import { mapActions, mapGetters } from 'vuex';
import noticeChecklist from '@/components/NoticeSendChecklist';
import DateTimePickers from '@/components/DateTimePicker';

export default {
  name: 'MeetingNoticeAutoSchedulerDialog',
  components: {
    noticeChecklist,
    DateTimePickers
  },
  filters: {
    ...filters
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    currentMeeting: {
      type: Object,
      required: true
    },
    noticeGroupList: {
      type: Array,
      default() {
        return [];
      }
    },
    noticeTemplateList: {
      type: Array,
      default() {
        return [];
      }
    },
    noticeStartDate: {
      type: String,
      default: null
    },
    noticeEndDate: {
      type: String,
      default: null
    },
    noticeTemplateGroupKey: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      updateDirection: true,
      shortCode: this.$route.params.shortcode,
      state: {
        reminders: [],
        noticeTemplateList: [],
        filteredNoticeTemplateList: [],
        startDate: null,
        endDate: null,
        success: false,
        error: false,
        errorMsg: '',
        scheduledNotices: []
      },
      schedulerConfig: {
        suggestedHourMin: 7, // 24 hour clock format
        suggestedHourMax: 19, // 24 hour clock format
        suggestedIntervalInMinutes: 5, // Must be a number from 1 through 60 minutes
        upperLimitDaySeparator: 3, // Whole numbers only 1, 2, 3... (do not allow negative or 0 days)
        lowerLimitDaySeparator: 1,
        dateForward: true,
        keepStartEnd: false,
        timeZone: 'America/Toronto'
      }
    };
  },
  computed: {
    ...mapGetters('meetings/notices', [
      'noticeListGenerated',
      'noticeListScheduled'
    ]),
    ...mapGetters('meetings/checklists', ['meetingChecklists']),
    dateDirection() {
      if (this.schedulerConfig.dateForward) {
        return 'Forward dated from Start Date';
      } else {
        return 'Back dated from End Date';
      }
    },
    currentNoticeGroupList() {
      // List to populate the add notice group dropdown
      return this.noticeGroupList;
    },
    currentNoticeList() {
      return this.noticeList;
    },
    providedStartDate() {
      return this.noticeStartDate;
    },
    providedEndDate() {
      return this.noticeEndDate;
    },
    currentNoticeTemplateList() {
      return this.noticeTemplateList;
    },
    currentGeneratedNoticeList() {
      return this.noticeListGenerated;
    },
    currentScheduledNoticeList() {
      return this.noticeListScheduled;
    },
    isSchedulerDisabled() {
      if (this.state.reminders.length > 0 && this.state.startDate) {
        // Check through all reminders to ensure that a send date has been assigned
        let dateMissing = false;
        this.state.reminders.map(reminderTemplate => {
          if (!reminderTemplate.sendOn) {
            dateMissing = true;
            return;
          }
        });
        return dateMissing;
      } else {
        return true;
      }
    },
    meetingTimezone() {
      return this.currentMeeting.meetingTimezone
        ? this.currentMeeting.meetingTimezone
        : 'America/Toronto';
    }
  },
  watch: {
    updateDirection(value) {
      this.schedulerConfig.dateForward = !this.schedulerConfig.dateForward;
      this.generateNoticeSchedule();
    },
    currentNoticeTemplateList: function() {
      this.init();
    },
    currentGeneratedNoticeList: function() {
      this.updateReminders(this.currentGeneratedNoticeList.notices);
      this.updateStartDate(this.currentGeneratedNoticeList.adjustedStartDate);
      this.updateEndDate(this.currentGeneratedNoticeList.adjustedEndDate);
    }
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('meetings/notices', [
      'generateMeetingNoticeListSchedule',
      'scheduleMeetingNoticeList'
    ]),
    init() {
      // Retrieve the array of reminder templates and notice start date if passed through props
      this.state.success = false;

      // Filter the template list for templates available to the scheduler
      this.state.noticeTemplateList = _.filter(this.currentNoticeTemplateList, {
        scheduler: true
      });

      const uniqueNames = {};
      const filteredUniqueNames = [];

      for (const obj of this.state.noticeTemplateList) {
        const name = obj.name;

        if (!uniqueNames[name]) {
          uniqueNames[name] = true;
          filteredUniqueNames.push(obj);
        }
      }

      this.state.filteredNoticeTemplateList = filteredUniqueNames;

      this.updateReminders(
        !this.currentNoticeList ? [] : this.currentNoticeList
      );

      // Given that a start date has not been provided, initialize the start
      this.updateStartDate(
        !this.providedStartDate
          ? moment()
              .utc()
              .format()
          : this.providedStartDate
      );

      // Initial the end date depending on the type of notice group selected
      this.updateEndDate(
        !this.providedEndDate
          ? this.currentMeeting.meetingDate
          : this.providedEndDate
      );
      // Following notice sends/schedules, a notice template group key is provided, update the template list by group
      if (
        this.noticeTemplateGroupKey &&
        this.providedStartDate &&
        this.providedEndDate
      ) {
        this.updateNoticeGroup(
          this.noticeTemplateGroupKey,
          this.providedStartDate,
          this.providedEndDate
        );
      }

      // Reset error messaging
      this.resetErrors();
    },
    emitClose() {
      this.state.success = false;
      this.state.startDate = new Date(moment()).toISOString();
      this.state.reminders = [];
      this.$emit('close');
    },
    emitInput() {
      this.$emit('input');
    },
    removeNotice(index) {
      this.state.reminders.splice(index, 1);
    },
    updateStartDate(value) {
      this.state.startDate = value;
      this.resetErrors();
    },
    updateEndDate(value) {
      this.state.endDate = value;
      this.resetErrors();
    },
    updateReminders(updateList) {
      this.state.reminders = updateList;
    },
    updateReminderDate(index, value) {
      this.state.reminders[index].sendOn = value;
    },
    updateNoticeGroup(
      noticeTemplateGroupKey,
      noticeStartDate = null,
      noticeEndDate = null
    ) {
      // When the notice group is selected, it's either from scratch or triggered following a notice template send out
      // 1) For from scratch requests we will use the meeting checklist items as guides (if they exist)
      // 2) For notice groups that are triggered from a send/scheduled notice, we use the start/end dates passed

      // Save the preference to keep start and end dates
      let keepStartEnd = noticeStartDate !== null ? true : false;
      this.schedulerConfig.keepStartEnd = keepStartEnd;

      // Display reminder templates belonging to the notice group template key
      let targetTemplateList = _.filter(
        this.state.noticeTemplateList,
        reminderTemplate => _.includes(reminderTemplate, noticeTemplateGroupKey)
      );

      // Ensure that each reminder has a unique index for deletion purposes
      targetTemplateList = _.map(targetTemplateList, function(reminder) {
        return _.extend({}, reminder, { uuid: _.uniqueId() });
      });

      // When a new notice group is selected, use the checklist dates as guidance for start and end dates
      const noticeGroupFound = _.find(this.noticeGroupList, {
        noticeTemplateGroupKey: noticeTemplateGroupKey
      });

      const noticeGroupName = noticeGroupFound.name;
      const noticeMeetingGroups = [
        'Notice of Meeting Group',
        'Notice of Meeting (Virtual) Group',
        'Notice of Meeting (Virtual - No RSVP) Group',
        'Notice of Meeting (Virtual - Associations) Group'
      ];
      let nextStage,
        currentStage = '';
      if (noticeGroupName === 'Pre-Notice Group') {
        currentStage = 'pre-notice';
        nextStage = 'service';
      } else if (noticeGroupName === 'Pre-Consent Group') {
        currentStage = 'pre-consent';
        nextStage = 'pre-notice';
      } else if (noticeMeetingGroups.indexOf(noticeGroupName) >= 0) {
        currentStage = 'service';
        nextStage = 'meeting';
        this.state.endDate = this.currentMeeting.meetingDate;
      }

      // Only search the checklist for Pre-Consents and Pre-Notices
      // For Notice of Service, the end date is already defaulted to the meeting date
      if (this.meetingChecklists && nextStage) {
        for (let cl in this.meetingChecklists) {
          let meetingChecklist = this.meetingChecklists[cl];

          // Given that the current stage has a checklist item, use it as the start date
          if (meetingChecklist.stage === currentStage) {
            // When checklists exist, ensure the start date has not already passed
            const today = moment();
            const checklistStart = moment(meetingChecklist.dueAt);

            // For triggered notice groups, use the start date provided
            if (checklistStart.diff(today) > 0 && !keepStartEnd) {
              this.state.startDate = meetingChecklist.dueAt;
            }
          }
          if (meetingChecklist.stage === nextStage) {
            this.state.endDate = meetingChecklist.dueAt;
          }
        }
      }

      this.schedulerConfig.timeZone = this.meetingTimezone;
      // Pass all parameters to route for calculation
      this.generateMeetingNoticeListSchedule({
        notices: targetTemplateList || [],
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        meetingDate: this.currentMeeting.meetingDate,
        schedulerConfig: this.schedulerConfig
      });

      this.resetErrors();
    },
    addTemplate(noticeTemplateKey) {
      let newTemplate = _.find(this.state.noticeTemplateList, {
        noticeTemplateKey: noticeTemplateKey
      });
      newTemplate.sendOn = null;
      // Add uniqueness for targeting notices for deletion from the list
      this.state.reminders.push(
        _.extend({}, newTemplate, { uuid: _.uniqueId() })
      );
      this.resetErrors();
    },
    resetErrors() {
      this.state.error = false;
      this.state.errorMsg = '';
    },
    async generateNoticeSchedule() {
      this.resetErrors();
      this.schedulerConfig.keepStartEnd = true;

      await this.generateMeetingNoticeListSchedule({
        notices: this.state.reminders,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        meetingDate: this.currentMeeting.meetingDate,
        schedulerConfig: this.schedulerConfig
      }).catch(error => {
        this.state.error = true;
        this.state.errorMsg = error.response.data;
      });
    },
    async scheduleNotices() {
      this.resetErrors();

      await this.scheduleMeetingNoticeList({
        notices: this.state.reminders,
        meeting: {
          id: this.currentMeeting.id,
          shortCode: this.currentMeeting.shortCode,
          contact: this.currentMeeting.contact,
          options: this.currentMeeting.options
        },
        startDate: this.state.startDate,
        endDate: this.state.endDate
      })
        .then(res => {
          this.state.scheduledNotices = _.filter(
            this.currentScheduledNoticeList,
            { success: true }
          );
          this.state.success = true;
        })
        .catch(error => {
          this.state.error = true;
          this.state.errorMsg = error.response.data;
          this.state.success = false;
        });
    }
  }
};
</script>

<style scoped>
.v-select__selections .v-list__tile__title,
.v-list__tile__title {
  height: auto !important;
}
</style>
