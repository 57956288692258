'use strict';

/**
 * @fileoverview Vuex module for meetings
 */

import _ from 'lodash';
import Vue from 'vue';

import makeMeetingObj from '@/lib/make-meeting-object';
import { removeEmptyProperty } from '@/lib/property-helpers';

// Submodules
import billing from './billing';
import business from './business';
import certificates from './certificates';
import checklists from './checklists';
import collaborators from './collaborators';
import files from './files';
import proxies from './proxies';
import dashboardProxySubmissions from './dashboard-proxy-submissions';
import nominations from './nominations';
import notices from './notices';
import reports from './reports';
import units from './units';
import printJobs from './print-jobs';
import notes from './notes';
import ballots from './ballots';
import rtvQuestions from './rtvQuestions';
import webcast from './webcast';
import liveVotes from './live-votes';
import conversations from './conversations';
import conversationTemplates from './conversation-templates';
import partners from './partners';
import consentTemplates from './consent-templates';
import approvals from './approvals';
import attendanceSnapshots from './attendance-snapshots';
import requests from './requests';
import voters from './voters';
import taggingUsers from './tagging-users';

// API Calls
import {
  getMeetings,
  postNewMeeting,
  getMeetingDetails,
  putMeetingDetails,
  updateMeetingStage,
  updateMeetingPortalRevocations,
  updateMeetingAdvanceVoting,
  postMeetingDocument,
  deleteMeetingDocument,
  updateMeetingStatus,
  updateVBMStatus,
  getReminderSettings,
  updateReminderSettings,
  getReminderTemplatePreview,
  getInviteTemplatePreview,
  getMeetingStats,
  getMeetingStatsQueue,
  getDialInInformation,
  getMeetingLogs
} from '@/api/meetings';

const state = {
  meetingList: {},
  // Used to store a copy of the initial data list
  initialMeetingList: {},
  // Used to store the timestamp of the initial data load
  dataLastFetchedAt: null,
  searchQuery: '',
  pusher: {},
  reminderSettings: {},
  automatedTemplatePreview: {
    content: '',
    subject: ''
  },
  inviteTemplatePreview: '',
  dialInInformationPreview: '',
  loadingStatuses: {
    stats: false
  },
  reloadDialog: false
};

const getters = {
  meetingListSearchQuery: state => state.searchQuery,
  // Denormalize data (see https://gist.github.com/brianboyko/91fdfb492071e743e389d84eee002342#store)
  meetingListData: state => Object.values(state.meetingList),
  meetingListDataLastFetchedAt: state => state.dataLastFetchedAt,
  meetingByShortCode: state => shortCode => state.meetingList[shortCode],
  meetingReminderSettings: state => state.reminderSettings,
  automatedTemplatePreview: state => state.automatedTemplatePreview,
  inviteTemplatePreview: state => state.inviteTemplatePreview,
  loadingStatuses: state => state.loadingStatuses,
  dialInInformationPreview: state => state.dialInInformationPreview,
  reloadDialog: state => state.reloadDialog
};

const actions = {
  /**
   * Get the latest meeting data from API and put into store
   * @param  {Object}   [params]                  - params
   * @param  {boolean}  [params.includeStats]     - whether to include stats for each mtg
   * @param  {Date}     [params.dateRangeStart]   - meeting date start range
   * @param  {Date}     [params.dateRangeEnd]     - meeting date end range
   * @param  {String}   [params.searchText]       - name or shortcode text to search on
   * @param  {Number}   [params.limit]            - limit page size to number of records
   * @param  {boolean}  [params.isInitial]        - True if this is the initial fetch
   */
  async getMeetingListData(
    { commit },
    { includeStats, dateRangeStart, dateRangeEnd, searchText, limit, isInitial }
  ) {
    try {
      const res = await getMeetings({
        includeStats,
        dateRangeStart,
        dateRangeEnd,
        searchText,
        limit
      });
      commit('SET_MEETING_LIST_DATA', res.data);

      if (isInitial) {
        commit('SET_INITIAL_MEETING_LIST_DATA', res.data);
      }
    } catch (err) {
      console.error('Error: getMeetingListData action', err);
      throw err;
    }
  },

  /**
   * Reset the meeting data list back to the data fetched in the initial
   * data load.  Used when clearing the search query to avoid the need
   * to call the API for another load.
   */
  async resetMeetingListToInitial({ commit }) {
    commit('RESET_MEETING_LIST_TO_INITIAL');
  },

  /**
   * Add a new meeting via API. Also adds meeting to list of meetings
   * @param {Object} meeting  the meeting payload
   */
  async createMeeting({ commit }, meeting) {
    try {
      const newMeetingObj = makeMeetingObj(meeting);
      const res = await postNewMeeting(newMeetingObj);
      // Update the main list with the new data
      await commit('SET_MEETING_IN_LIST', {
        shortCode: res.data.shortCode,
        data: res.data
      });
      return res.data;
    } catch (err) {
      console.error('Error: createMeeting action', err);
      throw err;
    }
  },

  /**
   * Get the latest data for this meeting
   * @param  {String} {shortCode} Meeting shortcode
   */
  async getMeeting({ commit, dispatch, rootGetters }, { shortCode }) {
    try {
      const mtgRes = await getMeetingDetails(shortCode);

      if (rootGetters.isAdmin) {
        dispatch(
          'accounts/getAccount',
          {
            key: mtgRes.data.accountKey,
            includeUsers: true,
            includeAgents: true
          },
          {
            root: true
          }
        );

        dispatch(
          'invoices/getOutstandingInvoicesForAccount',
          {
            accountKey: mtgRes.data.accountKey
          },
          { root: true }
        );
      }

      const data = {
        ...mtgRes.data
      };

      commit('SET_MEETING_IN_LIST', { shortCode, data });

      const { enableGetStatsV2 } = data.options;

      if (enableGetStatsV2) {
        dispatch('getMeetingStatsQueue', { shortCode: mtgRes.data.shortCode });
      } else {
        dispatch('getMeetingStats', { shortCode: mtgRes.data.shortCode });
      }
    } catch (err) {
      console.error('Error: getMeeting action', err);
      throw err;
    }
  },

  /**
   * Retrieves the meeting stats for a given short code.
   *
   * @param {Object} options - The options object.
   * @param {string} options.shortCode - The short code of the meeting.
   * @param {boolean} [options.clearCache=false] - Whether to clear the cache.
   * @return {Promise} A promise that resolves with the meeting stats data.
   */
  async getMeetingStats({ commit }, { shortCode, clearCache = false }) {
    try {
      commit('SET_LOADING_STATUS', { type: 'stats', status: true });

      const stats = await getMeetingStats(shortCode, clearCache);
      commit('SET_MEETING_STAT', { shortCode, data: stats.data });
    } catch (err) {
      console.error('Error: getMeetingStats action', err);
      throw err;
    } finally {
      commit('SET_LOADING_STATUS', { type: 'stats', status: false });
    }
  },

  /**
   * Retrieves the meeting stats for a given short code using subscription process.
   *
   * @param {Object} options - The options object.
   * @param {string} options.shortCode - The short code of the meeting.
   * @return {Promise} A promise that resolves with the meeting stats data.
   */
  async getMeetingStatsQueue({ commit }, { shortCode }) {
    try {
      commit('SET_LOADING_STATUS', { type: 'stats', status: true });
      const response = await getMeetingStatsQueue(shortCode);

      const { data } = response;
      if (data) {
        commit('SET_MEETING_STAT', { shortCode, data });
        commit('SET_LOADING_STATUS', { type: 'stats', status: false });
      }
    } catch (err) {
      console.error('Error: getMeetingStatsQueue action', err);
      throw err;
    }
  },

  /**
   * Update a meeting
   * @param  {String} {shortCode} Meeting shortcode
   * @param  {Object} {meeting}   Meeting payload
   */
  async updateMeeting({ dispatch }, { shortCode, meeting }) {
    try {
      const meetingUpdateObj = removeEmptyProperty(meeting);
      await putMeetingDetails(shortCode, meetingUpdateObj);
      return dispatch('getMeeting', { shortCode });
    } catch (err) {
      console.error('Error: updateMeeting action', err);
      throw err;
    }
  },

  /**
   * Get reminder settings for a meeting.
   *
   * @param  {String}  shortCode                  - meeting shortcode
   */
  async getMeetingReminderSettings({ commit }, { shortCode }) {
    try {
      const res = await getReminderSettings(shortCode);
      commit('SET_REMINDER_SETTINGS', res.data);
    } catch (err) {
      console.error('Error: getMeetingReminderSettings action', err);
      throw err;
    }
  },

  /**
   * Update reminder settings for a meeting.
   *
   * @param  {String}  shortCode                  - meeting shortcode
   * @param  {Object}  reminderSettings           - updated meeting reminder settings
   */
  async updateMeetingReminderSettings(
    { dispatch },
    { shortCode, reminderSettings }
  ) {
    try {
      await updateReminderSettings(shortCode, reminderSettings);
      return dispatch('getMeetingReminderSettings', { shortCode });
    } catch (err) {
      console.error('Error: updateMeetingReminderSettings action', err);
      throw err;
    }
  },

  /**
   * Get preview of either the automated Reminder template
   * or automated Join Portal template.
   *
   * @param  {String} shortCode         - meeting shortcode
   * @param  {String} type              - template type ('reminder' or 'join')
   * @param  {String} voterKey          - optional voter key
   */
  async getReminderTemplatePreview(
    { commit },
    { shortCode, type, voterKey = '' }
  ) {
    try {
      const res = await getReminderTemplatePreview({
        shortCode,
        type,
        voterKey
      });
      commit('SET_AUTOMATED_TEMPLATE_PREVIEW', res.data);
    } catch (err) {
      console.error('ERROR: getReminderTemplatePreview action', err, shortCode);
      throw err;
    }
  },

  /**
   * Get preview of the meeting invite template.
   *
   * @param  {String} shortCode         - meeting shortcode
   */
  async getInviteTemplatePreview(
    { commit },
    { shortCode, troubleshooting = false }
  ) {
    try {
      const res = await getInviteTemplatePreview({
        shortCode,
        troubleshooting
      });

      commit('SET_INVITE_TEMPLATE_PREVIEW', res.data);
    } catch (err) {
      console.error('ERROR: getInviteTemplatPreview action', err, shortCode);
      throw err;
    }
  },

  /**
   * Get preview of the meeting invite template.
   *
   * @param  {String} shortCode         - meeting shortcode
   */
  async getDialInTemplatePreview({ commit }, { shortCode }) {
    try {
      const res = await getDialInInformation({
        shortCode
      });

      commit('SET_DIAL_IN_INFORMATION_PREVIEW', res.data);
    } catch (err) {
      console.error('ERROR: getDialInTemplatePreview action', err, shortCode);
      throw err;
    }
  },

  /**
   * Update a meeting's stage
   * @param  {String} {shortCode} Meeting shortcode
   * @param  {String} {stage}     The state to set the meeting stage to
   */
  async updateMeetingStageOption({ dispatch }, { shortCode, stage }) {
    try {
      await updateMeetingStage(shortCode, stage);
      return dispatch('getMeeting', { shortCode });
    } catch (err) {
      console.error('Error: updateMeetingStageOption action', err);
      throw err;
    }
  },

  /**
   * Update portal revocations for a meeting (either open or closed)
   * @param  {String} shortCode          - Meeting shortcode
   * @param  {String} revocationStatus   - Whether portal revocations are being opened or closed
   */
  async toggleMeetingPortalRevocations(
    { dispatch },
    { shortCode, revocationStatus }
  ) {
    try {
      await updateMeetingPortalRevocations(shortCode, revocationStatus);
      return dispatch('getMeeting', { shortCode });
    } catch (err) {
      console.error('Error: toggleMeetingPortalRevocations action', err);
      throw err;
    }
  },

  /**
   * Update portal revocations for a meeting (either open or closed)
   * @param  {String} shortCode          - Meeting shortcode
   * @param  {String} status             - which status to update it to
   */
  async setMeetingStatus({ dispatch }, { shortCode, status }) {
    try {
      await updateMeetingStatus(shortCode, status);
      return dispatch('getMeeting', { shortCode });
    } catch (err) {
      console.error('Error: setMeetingStatus action', err);
      throw err;
    }
  },

  /**
   * Update portal revocations for a meeting (either open or closed)
   * @param  {String} shortCode          - Meeting shortcode
   * @param  {String} status             - which status to update it to
   */
  async setVBMStatus({ dispatch }, { shortCode, status }) {
    try {
      return await updateVBMStatus(shortCode, status);
    } catch (err) {
      console.error('Error: setVBMStatus action', err);
      throw err;
    }
  },

  /**
   * Update advance voting for a meeting (either opened or closed)
   * @param {String} shortCode            - Meeting shortcode
   * @param {String} advanceVotingStatus  - whether advance voting is being opened or closed
   */
  async toggleMeetingAdvanceVoting(
    { dispatch },
    { shortCode, advanceVotingStatus }
  ) {
    try {
      await updateMeetingAdvanceVoting(shortCode, advanceVotingStatus);
      dispatch('getMeeting', { shortCode });
    } catch (err) {
      console.error('Error: toggleMeetingAdvanceVoting action', err);
      throw err;
    }
  },

  /**
   * Add a document (i.e., package) to the meeting.
   * @param  {String} shortCode    - Meeting shortcode
   * @param  {Object} document     - Document to add
   */
  async addMeetingDocument({ dispatch }, { shortCode, document }) {
    try {
      await postMeetingDocument(shortCode, document);
      dispatch('getMeeting', { shortCode });
    } catch (err) {
      console.error('Error: addMeetingDocument action', err);
      throw err;
    }
  },

  /**
   * Remove a document (i.e., package) from the meeting.
   * @param  {String} shortCode    - Meeting shortcode
   * @param  {String} documentKey  - Document key, to remove
   */
  async removeMeetingDocument({ dispatch }, { shortCode, documentKey }) {
    try {
      await deleteMeetingDocument(shortCode, documentKey);
      dispatch('getMeeting', { shortCode });
    } catch (err) {
      console.error('Error: removeMeetingDocument action', err);
      throw err;
    }
  },

  /**
   * get meeting logs
   * @param  {String} shortCode    - Meeting shortcode
   *
   */
  async getMeetingLogsAction(_, { shortCode }) {
    try {
      const response = await getMeetingLogs({ shortCode });
      return response.data;
    } catch (err) {
      console.error('Error: getMeetingLogs action', err);
      throw err;
    }
  },

  /**
   * Clear all meeting submodule states. Used when transitioning between
   * meetings so that old state is removed.
   */
  clearMeetingState({ commit }) {
    commit('meetings/approvals/CLEAR_STATE', null, { root: true });
    commit('meetings/business/CLEAR_STATE', null, { root: true });
    commit('meetings/billing/CLEAR_STATE', null, { root: true });
    commit('meetings/checklists/CLEAR_STATE', null, { root: true });
    commit('meetings/certificates/CLEAR_STATE', null, { root: true });
    commit('meetings/notices/CLEAR_STATE', null, { root: true });
    commit('meetings/proxies/CLEAR_STATE', null, { root: true });
    commit('meetings/units/CLEAR_STATE', null, { root: true });
    commit('meetings/printJobs/CLEAR_STATE', null, { root: true });
    commit('meetings/printJobs/CLEAR_STATE', null, { root: true });
    commit('meetings/nominations/CLEAR_STATE', null, { root: true });
    commit('meetings/ballots/CLEAR_STATE', null, { root: true });
    commit('meetings/rtvQuestions/CLEAR_STATE', null, { root: true });
    commit('meetings/webcast/CLEAR_STATE', null, { root: true });
    commit('meetings/collaborators/CLEAR_STATE', null, { root: true });
    commit('meetings/liveVotes/CLEAR_STATE', null, { root: true });
    commit('meetings/files/CLEAR_STATE', null, { root: true });
    commit('meetings/conversations/CLEAR_STATE', null, { root: true });
    commit('meetings/voters/CLEAR_STATE', null, { root: true });
    commit('meetings/taggingUsers/CLEAR_STATE', null, { root: true });
  },

  // User Preferences
  setSearchQuery({ commit }, searchQuery) {
    return commit('SET_MEETING_LIST_SEARCH_QUERY', searchQuery);
  },
  clearState({ commit }) {
    return commit('CLEAR_STATE');
  },
  setReloadDialog({ commit }, { isOpen }) {
    return commit('SET_RELOAD_DIALOG', { isOpen });
  }
};

const mutations = {
  // Normalize storage of data as best practice. See...
  // https://gist.github.com/brianboyko/91fdfb492071e743e389d84eee002342#store
  SET_MEETING_LIST_DATA(state, data) {
    state.meetingList = _.keyBy(data, 'shortCode');
  },

  SET_INITIAL_MEETING_LIST_DATA(state, data) {
    state.initialMeetingList = _.keyBy(data, 'shortCode');
    state.dataLastFetchedAt = new Date();
  },

  // Reset the meeting list to the initial data load
  RESET_MEETING_LIST_TO_INITIAL(state) {
    state.meetingList = _.cloneDeep(state.initialMeetingList);
  },

  SET_MEETING_LIST_DATA_STATS_ONLY(state, data) {
    // Only adds a stats object to existing meeting list
    // Prevents overwrite of meeting business
    data.forEach(function(el) {
      // Only set stats object if shortcode exists
      if (state.meetingList.hasOwnProperty(el.shortCode)) {
        Vue.set(state.meetingList[el.shortCode], 'stats', el.stats);
      }
    });
  },

  // Adds or replaces an existing meeting object into the current list
  // of meetings
  SET_MEETING_IN_LIST(state, { shortCode, data }) {
    Vue.set(state.meetingList, shortCode, data);
  },

  SET_MEETING_STAT(state, { shortCode, data }) {
    const meetingList = _.cloneDeep(state.meetingList);

    meetingList[shortCode].stats = data;

    state.meetingList = meetingList;
  },

  SET_MEETING_LIST_SEARCH_QUERY(state, searchQuery) {
    state.searchQuery = searchQuery;
  },

  SET_REMINDER_SETTINGS(state, reminderSettings) {
    state.reminderSettings = reminderSettings ? reminderSettings : {};
  },

  SET_AUTOMATED_TEMPLATE_PREVIEW(state, template) {
    state.automatedTemplatePreview = template;
  },

  SET_INVITE_TEMPLATE_PREVIEW(state, template) {
    state.inviteTemplatePreview = template;
  },

  SET_LOADING_STATUS(state, { type, status }) {
    state.loadingStatuses[type] = status;
  },

  SET_DIAL_IN_INFORMATION_PREVIEW(state, template) {
    state.dialInInformationPreview = template;
  },
  // Clear the meeting list
  CLEAR_STATE(state) {
    state.meetingList = {};
    state.dataLastFetchedAt = null;
  },

  SET_RELOAD_DIALOG(state, { isOpen }) {
    state.reloadDialog = isOpen;
  }
};

export default {
  namespaced: true,
  state,
  modules: {
    approvals,
    billing,
    business,
    certificates,
    checklists,
    collaborators,
    conversations,
    conversationTemplates,
    nominations,
    notices,
    proxies,
    dashboardProxySubmissions,
    reports,
    units,
    printJobs,
    notes,
    ballots,
    rtvQuestions,
    webcast,
    liveVotes,
    files,
    partners,
    consentTemplates,
    attendanceSnapshots,
    requests,
    voters,
    taggingUsers
  },
  getters,
  actions,
  mutations
};
