<template>
  <v-dialog max-width="450px" persistent :value="value">
    <v-card>
      <v-card-title>
        <span class="title">Revoke Proxy?</span>
      </v-card-title>
      <v-card-text>
        <p>You are about to revoke this Proxy.</p>
        <p>Click REVOKE to continue or CLOSE to return to Unit List.</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.stop="emitClose">close</v-btn>
        <v-btn color="error" @click.native="emitDelete">revoke</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'MeetingUnitsRevokeProxyDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    unitSelected: {
      type: Object,
      default() {
        return {};
      }
    },
    data: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    currentProxy() {
      let currentUnit = this.data.find(
        unit => parseInt(unit.id) === this.unitSelected.unitId
      );
      return currentUnit ? currentUnit.proxy : {};
    }
  },
  methods: {
    emitClose() {
      this.$emit('close');
    },
    emitDelete() {
      this.$emit('delete', this.currentProxy);
    }
  }
};
</script>

<style scoped></style>
