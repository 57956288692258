<template>
  <v-layout class="ma-0 pa-0">
    <v-flex xs6>
      <v-menu
        lazy
        :close-on-content-click="false"
        v-model="menu"
        transition="scale-transition"
        offset-y
        full-width
        :nudge-right="40"
        :disabled="isDisabled"
        max-width="460px"
      >
        <v-text-field
          :class="{ 'pr-1': hideIcons }"
          :hide-details="hideDetails"
          slot="activator"
          :label="label ? label : ''"
          v-model="dateObj.date"
          :prepend-icon="dateIcon"
          :disabled="isDisabled"
          :hint="hint"
          persistent-hint
          readonly
          :box="box"
        />
        <v-date-picker
          v-model="dateObj.date"
          scrollable
          landscape
          actions
          :min="min"
          :max="max"
        >
          <v-btn v-if="showClear" flat @click="emitClear">
            <v-icon>close</v-icon> Clear Date
          </v-btn>
          <v-spacer />
          <v-btn color="primary" @click="menu = false">OK</v-btn>
        </v-date-picker>
      </v-menu>
    </v-flex>
    <v-flex xs6>
      <v-text-field
        :class="{ 'pl-1': hideIcons }"
        :hide-details="hideDetails"
        :prepend-icon="timeIcon"
        :label="timeLabel ? timeLabel : ''"
        type="time"
        v-model="dateObj.time"
        :disabled="isDisabled"
        :suffix="timezoneSuffix"
        :box="box"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import moment from 'moment-timezone';

export default {
  props: {
    isoDate: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    timeLabel: {
      type: String,
      default: ''
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default() {
        return '';
      }
    },
    box: {
      type: Boolean,
      default: false
    },
    hideIcons: {
      type: Boolean,
      default: false
    },
    hideTimezone: {
      type: Boolean,
      default: false
    },
    timezone: {
      type: String,
      default: 'America/Toronto'
    },
    showClear: {
      type: Boolean,
      default: true
    },
    min: {
      type: String,
      default: ''
    },
    max: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dateObj: {
        date: '',
        time: ''
      },
      menu: false
    };
  },
  watch: {
    isoDate: function(value) {
      this.setDate(value);
    },
    dateObj: {
      deep: true,
      handler: function() {
        this.emitDatetime();
      }
    },
    timezone: function() {
      this.setDate(this.isoDate);
    }
  },
  created() {
    this.setDate(this.isoDate);
  },
  computed: {
    dateIcon() {
      return this.hideIcons ? '' : 'event';
    },
    timeIcon() {
      return this.hideIcons ? '' : 'access_time';
    },
    timezoneSuffix() {
      return this.hideTimezone
        ? ''
        : moment.tz(this.validatedTimezone).format('z');
    },
    validatedTimezone() {
      return this.timezone ? this.timezone : 'America/Toronto';
    }
  },
  methods: {
    setDate(isoDate) {
      if (isoDate !== null) {
        const momentDate = moment.tz(isoDate, this.validatedTimezone);
        this.dateObj.date = momentDate.format('YYYY-MM-DD');
        this.dateObj.time = momentDate.format('HH:mm');
      }
    },
    returnCombinedIsoString(date, time) {
      // Required default value of 19:00 to prevent inaccurate timezone conversions
      const defaultTime = time ? time : date && '19:00';
      this.dateObj.time = defaultTime;
      let combinedDatetime = moment
        .tz(date + ' ' + defaultTime, this.validatedTimezone)
        .toISOString();
      return combinedDatetime;
    },
    emitDatetime() {
      let dateTime = this.returnCombinedIsoString(
        this.dateObj.date,
        this.dateObj.time
      );
      this.$emit('update', dateTime);
    },
    emitClear() {
      this.$emit('update', null);
      this.dateObj.date = '';
      this.dateObj.time = '';
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
