<template>
  <v-container>
    <v-layout align-center>
      <div class="title text--darken-4">
        Live voting
      </div>
      <v-btn
        v-if="liveVotingEnabled"
        color="success"
        small
        @click="handleLiveVoting(false)"
        >Enabled</v-btn
      >
      <v-btn v-else color="error" small @click="handleLiveVoting(true)"
        >Disabled</v-btn
      >
    </v-layout>

    <template v-if="liveVotingEnabled">
      <div class="ml-2">
        <div class="my-4 title">
          General
        </div>

        <v-layout class="mt-3">
          <div class="pr-3">
            <v-checkbox
              v-model="allowPortalRevocations"
              @change="onChange('options.allowPortalRevocations', $event)"
            />
          </div>
          <div>
            <div class="subheading">
              Allow voters to revoke their Advance Vote inside the Meeting
              Portal
            </div>
            <div class="grey--text text--darken-2">
              Click this to allow voters to revoke their unit's Advance Vote
              inside the Meeting Portal.<br />If enabled, Advance Vote
              revocations have to be manually CLOSED (via toggle on the
              Questions tab) before live voting can be started.
            </div>
          </div>
        </v-layout>

        <v-layout class="mt-3">
          <div class="pr-3">
            <v-checkbox
              v-model="enableRtvProxyOverride"
              @change="onChange('options.enableRtvProxyOverride', $event)"
            />
          </div>
          <div>
            <div class="subheading">
              Enable Live Questions to configure an Advance Vote Override
            </div>
            <div class="grey--text text--darken-2">
              Live voting questions can be individually configured to
              allow/block voters that have submitted advance votes
            </div>
          </div>
        </v-layout>

        <v-layout>
          <div class="pr-3">
            <v-checkbox
              v-model="displayAdvanceToggle"
              @change="onChange('options.displayAdvanceToggle', $event)"
            />
          </div>
          <div>
            <div class="subheading">
              Display Advance Voting Toggle
            </div>
            <div class="grey--text text--darken-2">
              Click this to show the toggle to open or close Advance Voting.
            </div>
          </div>
        </v-layout>

        <v-layout>
          <div class="pr-3">
            <v-checkbox
              v-model="enableNotificationEngine"
              @change="onChange('options.enableNotificationEngine', $event)"
            />
          </div>
          <div>
            <div class="subheading">
              Enable Live Voting Notification Engine
            </div>
            <div class="grey--text text--darken-2">
              Send voters a notification (via email) with meeting portal link
              whenever a live voting period is opened.
            </div>
          </div>
        </v-layout>

        <v-layout v-if="enableNotificationEngine">
          <div class="pr-3">
            <v-checkbox
              v-model="enableCommonEmailTemplateNotification"
              @change="
                onChange(
                  'options.enableCommonEmailTemplateNotification',
                  $event
                )
              "
            />
          </div>
          <div>
            <div class="subheading">
              Enable common email templates
            </div>
            <div class="grey--text text--darken-2">
              This will use common email templates for high volume campaigns
            </div>
          </div>
        </v-layout>

        <v-layout>
          <div class="pr-3">
            <v-checkbox
              :true-value="true"
              :false-value="false"
              v-model="enableRsvpEmails"
              @change="onChange('options.enableRsvpEmails', $event)"
            />
          </div>
          <div>
            <div class="subheading">Enable RSVP registration emails</div>
            <div class="grey--text text--darken-2">
              <li>
                If enabled, a confirmation of registration email will be sent to
                voters when they RSVP (from our servers). This can happen via
                the Meeting Portal or if a voter is set to attending in the
                Membership. This setting does NOT affect the zoom registration
                emails.
              </li>
            </div>
          </div>
        </v-layout>

        <div class="my-4 title">
          Experimental
        </div>

        <v-layout class="mt-1">
          <div class="pr-3">
            <v-checkbox
              :true-value="true"
              :false-value="false"
              v-model="disableIntervalPolling"
              @change="onChange('options.disableIntervalPolling', $event)"
            />
          </div>
          <div>
            <div class="subheading">Disable interval refresh on RTV</div>
            <div class="grey--text text--darken-2">
              <li>
                This option will disable interval polling (getBallot refresh) on
                the RTV app. This is a periodic refresh of the RTV app state
                that occurs every 45 seconds.
              </li>
            </div>
          </div>
        </v-layout>

        <v-layout class="mt-3">
          <div class="pr-3">
            <v-checkbox
              v-model="enableRtvCustomMaxDelay"
              @change="onChange('options.enableRtvCustomMaxDelay', $event)"
            />
          </div>
          <div>
            <div class="subheading">
              Enable custom maximum delay for RTV pusher events
            </div>
            <div class="grey--text text--darken-2">
              Sets a custom maximum delay range for pusher event triggered
              requests (ex: add-question, update-question). If not set, default
              delay range is 2-5 seconds.
            </div>
            <v-text-field
              v-if="enableRtvCustomMaxDelay"
              v-model="rtvCustomMaxDelay"
              @change="onChange('options.rtvCustomMaxDelay', $event)"
              suffix="seconds"
              type="number"
              clearable
            />
          </div>
        </v-layout>

        <v-layout class="mt-3">
          <div class="pr-3">
            <v-checkbox
              v-model="enableRtvRetryMode"
              @change="onChange('options.enableRtvRetryMode', $event)"
            />
          </div>
          <div>
            <div class="subheading">
              Enable retry mode for RTV error handling
            </div>
            <div class="grey--text text--darken-2">
              For unresponsive server errors (500, 503), RTV will display an
              alert on the vote tab indicating their connection has been lost,
              and will retry requests at a set interval until restored.
            </div>
          </div>
        </v-layout>

        <v-layout class="mt-3">
          <div class="pr-3">
            <v-checkbox
              v-model="enableRtvPanelists"
              @change="onChange('options.enableRtvPanelists', $event)"
            />
          </div>
          <div>
            <div class="subheading">
              Enable RTV for Panelists
            </div>
            <div class="grey--text text--darken-2">
              Click this to enable the RTV app to the panelists
            </div>
          </div>
        </v-layout>
      </div>
    </template>
  </v-container>
</template>

<script>
export default {
  name: 'LiveVotingV2',

  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  data() {
    return {
      liveVotingEnabled: false,
      enableRtvProxyOverride: false,
      disableIntervalPolling: false,
      enableRsvpEmails: false,
      enableNotificationEngine: false,
      enableRtvCustomMaxDelay: false,
      rtvCustomMaxDelay: null,
      enableRtvRetryMode: false,
      enableRtvPanelists: false,
      allowPortalRevocations: false,
      displayAdvanceToggle: false,
      enableCommonEmailTemplateNotification: false
    };
  },

  mounted() {
    this.liveVotingEnabled =
      this.options.liveVotingEnabled === false ? false : true;
    this.enableRtvProxyOverride = this.options.enableRtvProxyOverride;
    this.disableIntervalPolling = this.options.disableIntervalPolling;
    this.enableRsvpEmails = this.options.enableRsvpEmails;
    this.enableNotificationEngine = this.options.enableNotificationEngine;
    this.enableRtvCustomMaxDelay = this.options.enableRtvCustomMaxDelay;
    this.rtvCustomMaxDelay = this.options.rtvCustomMaxDelay;
    this.enableRtvRetryMode = this.options.enableRtvRetryMode;
    this.enableRtvPanelists = this.options.enableRtvPanelists;
    this.allowPortalRevocations = this.options.allowPortalRevocations;
    this.displayAdvanceToggle = this.options.displayAdvanceToggle;
    this.enableCommonEmailTemplateNotification = this.options.enableCommonEmailTemplateNotification;
  },

  methods: {
    handleLiveVoting(value) {
      this.liveVotingEnabled = value;
      this.onChange('options.liveVotingEnabled', value);
    },
    onChange(key, value) {
      this.$emit('update', { key, value });
    }
  }
};
</script>

<style lang="scss" scoped></style>
