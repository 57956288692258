<template>
  <div class="text-xs-center">
    <v-dialog :value="dialog" width="500" @input="handleClose">
      <v-card>
        <v-card-title class="headline lighten-2 font-weight-bold" primary-title>
          Open Ballot is unchecked
        </v-card-title>

        <v-card-text>
          <h3>These fields will be reset:</h3>
          <div>Proxy template: Bill-106</div>
          <div>Custom fields: empty</div>
          <div>Block unverified voters: Yes</div>
          <div>Block Vote Re-casting: No</div>
          <div>Proxy display options</div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <!-- If close text is provided, it will hide other buttons -->
          <v-layout justify-end>
            <v-btn flat text @click="handleClose">
              Cancel
            </v-btn>
            <v-btn color="primary" @click="handleConfirm">
              Confirm
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'OpenBallotCheckedDialog',
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleConfirm() {
      this.$emit('confirm', true);
    },
    handleClose() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped></style>
