<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        :disabled="isButtonDisabled"
        :color="isGeneralButtonSelected ? 'primary' : ''"
        class="mx-0"
      >
        General {{ isGeneralButtonSelected ? `(V${version})` : '' }}
        <v-icon>arrow_drop_down</v-icon>
      </v-btn>
    </template>
    <div
      style="width: 800px; height: 310px; background: white; border-radius: 8px;"
      class="d-flex py-3"
    >
      <div style="width: 50%; height: 100%">
        <div
          class="cursor-pointer justify-center align-center mb-3"
          style="display: flex;"
          @click="changeUIVotingOption(`${optionType}-v1`)"
        >
          <v-icon :color="option === `${optionType}-v1` ? 'blue' : 'gray'">{{
            option === `${optionType}-v1`
              ? 'radio_button_checked'
              : 'radio_button_unchecked'
          }}</v-icon>
          <div class="ml-2">
            General Proxy (V1)
            <v-tooltip top>
              <span slot="activator">
                <v-icon small>info</v-icon>
              </span>
              <span
                >Customize the General Proxy using the same app for Limited
                Proxy</span
              >
            </v-tooltip>
          </div>
        </div>

        <div class="px-2">
          <v-img
            src="https://i.ibb.co/mBL3xf3/Screenshot-2024-11-25-at-3-02-40-PM.png"
            alt="Proxy app"
            max-height="300"
          />
        </div>
      </div>
      <div style="width: 50%; height: 100%">
        <div
          class="cursor-pointer justify-center align-center mb-3"
          style="display: flex;"
          @click="changeUIVotingOption(`${optionType}-v2`)"
        >
          <v-icon :color="option === `${optionType}-v2` ? 'blue' : 'gray'">{{
            option === `${optionType}-v2`
              ? 'radio_button_checked'
              : 'radio_button_unchecked'
          }}</v-icon>
          <div class="ml-2">
            General Proxy (V2)
            <v-tooltip top>
              <span slot="activator">
                <v-icon small>info</v-icon>
              </span>
              <span
                >Includes the option for members to provide a custom proxy
                holder + email notification <br />No custom options
                available</span
              >
            </v-tooltip>
          </div>
        </div>
        <div class="px-2">
          <v-img
            src="https://i.ibb.co/7J4PbYF/Screenshot-2024-11-25-at-3-18-43-PM.png"
            alt="General proxy app"
            max-height="300"
          />
        </div>
      </div>
    </div>
  </v-menu>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'GeneralProxyDropdownButton',
  components: {},
  props: {
    option: {
      type: String,
      default: ''
    },
    isButtonDisabled: {
      type: Boolean,
      default: false
    },
    optionType: {
      type: String,
      default: ''
    }
  },
  computed: {
    isGeneralButtonSelected() {
      if (
        this.option === 'general-proxy-v1' ||
        this.option === 'general-proxy-v2' ||
        this.option === 'ballot-general-proxy-v1' ||
        this.option === 'ballot-general-proxy-v2'
      ) {
        return true;
      }
      return false;
    },
    version() {
      if (
        this.option === 'general-proxy-v1' ||
        this.option === 'ballot-general-proxy-v1'
      ) {
        return 1;
      } else if (
        this.option === 'general-proxy-v2' ||
        this.option === 'ballot-general-proxy-v2'
      ) {
        return 2;
      }
    }
  },
  methods: {
    changeUIVotingOption(value) {
      this.$emit('change', value);
    }
  }
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
