<template>
  <v-dialog max-width="450px" :value="value" @input="emitInput">
    <v-card>
      <v-card-title>
        <span class="title">Delete Units?</span>
      </v-card-title>

      <v-card-text>
        You are about to delete
        <b>{{ selected.length }} unit(s)</b>.Click DELETE to continue.

        <br />
        <span v-if="syncEnabled" class="caption"
          >Units added via integration won't be updated in the next sync.</span
        >
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.stop="emitClose">Close</v-btn>
        <v-btn color="error" @click.native="emitDelete">DELETE</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'MeetingUnitsDeleteUnitsDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default() {
        return [];
      }
    },
    syncEnabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    },
    emitDelete() {
      this.$emit('delete');
    }
  }
};
</script>

<style scoped></style>
