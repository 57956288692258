<template>
  <div>
    <v-layout row>
      <v-flex xs12>
        <v-toolbar dark dense color="grey lighten-4 elevation-1">
          <v-text-field
            light
            prepend-icon="search"
            label="Search by agent name"
            single-line
            hide-details
            clearable
            v-model="search"
          />
          <v-spacer />
          <v-btn color="primary" @click="dialog.addAgent = true"
            >Add Agent</v-btn
          >
        </v-toolbar>
        <v-data-table
          :items="agents"
          :headers="headers"
          :search="search"
          :pagination.sync="config.pagination"
          :rows-per-page-items="config.rowsPerPageItems"
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td class="nowrap name-cell">
                <span slot="activator">
                  <router-link
                    :to="{
                      name: 'editAgent',
                      params: { agentKey: props.item.key }
                    }"
                  >
                    {{ props.item.name }}
                  </router-link>

                  <v-chip
                    v-if="isPartner(props.item)"
                    small
                    color="primary lighten-1"
                    class="white--text"
                  >
                    Partner
                  </v-chip>

                  <v-tooltip bottom v-if="props.item.integrationAvailable">
                    <v-chip
                      slot="activator"
                      small
                      color="orange lighten-1"
                      class="white--text"
                    >
                      Integration
                    </v-chip>
                    <div>
                      An integration may be available for this customer
                    </div>
                  </v-tooltip>
                </span>
              </td>
              <td class="nowrap">
                {{ props.item.agentType }}
              </td>
              <td>{{ props.item.discount }}%</td>
              <td>
                <v-btn
                  flat
                  icon
                  color="grey"
                  @click.native="deleteMembership(props.item)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <add-membership-dialog
      :is-open="dialog.addAgent"
      :current-data-list="agents"
      data-set="agents"
      @close-dialog="dialog.addAgent = false"
      @add-agents="addMembership"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { dateFormatReadable } from '@/filters';
import AddMembershipDialog from '@/components/dialogs/AddMembershipDialog';
import { mapActions } from 'vuex';
export default {
  name: 'AdminAgentsListView',
  props: {
    agents: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  components: {
    AddMembershipDialog
  },
  filters: {
    dateFormatReadable
  },
  data() {
    return {
      accountKey: this.$route.params.accountKey,
      search: '',
      headers: [
        { text: 'Agent Name', value: 'name' },
        { text: 'Type', sortable: false },
        { text: 'Discount', sortable: false }
      ],
      config: {
        rowsPerPageItems: [10, 25, 50, { text: 'All', value: -1 }],
        pagination: {
          sortBy: 'createdAt',
          descending: true
        }
      },
      dialog: {
        addAgent: false
      }
    };
  },
  methods: {
    ...mapActions('accounts', ['createAgentAccount', 'removeAgentAccount']),
    async deleteMembership(agentAccount) {
      try {
        const { key } = agentAccount;
        await this.removeAgentAccount({
          agentKey: key,
          accountKey: this.accountKey
        });
        this.$events.$emit('toastEvent', 'Agent removed from account');
      } catch (err) {
        console.log(err);
      }
    },
    async addMembership(agentPayloadList) {
      try {
        await this.createAgentAccount({
          agentAccountList: agentPayloadList,
          accountKey: this.accountKey
        });
        this.$events.$emit('toastEvent', 'Agent membership created');
      } catch (err) {
        console.log(err);
      }
    },
    isPartner(item) {
      if (item.partnerType) {
        return item.partnerType.toLowerCase() === 'partner';
      }
      return false;
    }
  }
};
</script>

<style scoped>
.name-cell {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.fade {
  opacity: 0.74;
}
</style>
