<template>
  <v-container fluid grid-list-lg>
    <!-- Controls Section -->

    <v-layout row class="mb-3">
      <!-- Search -->
      <v-flex>
        <v-text-field
          v-model="searchQuery"
          prepend-inner-icon="search"
          label="Search reports"
          solo
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-flex>
    </v-layout>

    <!-- Admin Tab View -->
    <template v-if="isAdmin">
      <v-tabs v-model="activeTab" slider-color="primary">
        <v-tab href="#tab-1">
          Default (V1)
        </v-tab>

        <v-tab href="#tab-2">
          Admin (V2)
        </v-tab>

        <v-tab href="#tab-3">
          Client (V1/V2)
        </v-tab>

        <!-- Original Report List -->
        <v-tab-item value="tab-1" class="mt-3">
          <v-layout row wrap>
            <v-flex
              xs12
              sm6
              md4
              v-for="(report, index) in reportList"
              :key="`key-${index}`"
            >
              <report-card-v2
                :report="report"
                @handleClick="handleReportClick"
              />
            </v-flex>
          </v-layout>
        </v-tab-item>

        <!-- V2 Admin Report List (beta) -->
        <v-tab-item value="tab-2" class="mt-3">
          <v-layout row wrap>
            <v-flex
              xs12
              sm6
              md4
              v-for="(report, index) in adminReportListV2"
              :key="`key-${index}`"
            >
              <report-card-v2
                :report="report"
                @handleClick="handleReportClickV2"
              />
            </v-flex>
          </v-layout>
        </v-tab-item>

        <!-- V2 Client Report List (beta) -->
        <v-tab-item value="tab-3" class="mt-3">
          <v-layout row wrap>
            <v-flex
              xs12
              sm6
              md4
              v-for="(report, index) in clientReportList"
              :key="`key-${index}`"
            >
              <report-card-v2
                :report="report"
                @handleClick="
                  $event =>
                    report.reportType.includes('v2')
                      ? handleReportClickV2($event)
                      : handleReportClick($event)
                "
              />
            </v-flex>
          </v-layout>
        </v-tab-item>
      </v-tabs>
    </template>

    <!-- Non-admin view -->
    <template v-else>
      <v-layout row wrap class="mt-3">
        <v-flex
          xs12
          sm6
          md4
          v-for="(report, index) in clientReportList"
          :key="`key-${index}`"
        >
          <report-card-v2
            :report="report"
            @handleClick="
              $event =>
                report.reportType.includes('v2')
                  ? handleReportClickV2($event)
                  : handleReportClick($event)
            "
          />
        </v-flex>
      </v-layout>
    </template>

    <!-- V1 Dialogs -->
    <export-files-dialog
      :is-open="showExportDialog"
      :processing="isExportProcessing"
      :error="exportDialogError"
      :email-to="login.profile.email"
      @close-dialog="closeExportDialog"
    />
    <voter-registration-report-dialog
      :is-open="showVoterRegistrationDialog"
      @send="sendVoterRegistrationReport"
      @close="showVoterRegistrationDialog = false"
    />

    <user-guide-report-type-dialog
      :is-open="showUserGuideDialog"
      @download="downloadGuide"
      @close="showUserGuideDialog = false"
    />

    <format-option-dialog
      :is-open="showFormatOptionDialog"
      :format-option="formatOption"
      @close="showFormatOptionDialog = false"
      @download="downloadUnitOverviewReport"
      @send="sendConsentReport"
    />

    <print-distribution-option-dialog
      :is-open="showPrintOptionDialog"
      :report-type="reportTypeSelected"
      @action="handlePrintAction"
      @close="showPrintOptionDialog = false"
    />

    <!-- V2 Dialogs -->
    <!-- This dialog is multi-consent specific -->
    <consent-distribution-dialog-v2
      :is-open="dialogsV2['consent-distribution-v2']"
      :report-type="reportTypeSelected"
      @action="handleReportActionV2"
      @close="dialogsV2['consent-distribution-v2'] = false"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ReportCard from '@/components/ReportCard';
import ReportCardV2 from '@/components/ReportCardV2';
import ExportFilesDialog from '@/components/dialogs/ExportFilesDialog';
import VoterRegistrationReportDialog from '@/components/dialogs/VoterRegistrationReportDialog';
import UserGuideReportTypeDialog from '@/components/dialogs/UserGuideReportTypeDialog';
import FormatOptionDialog from '@/components/dialogs/formatOptionDialog';
import PrintDistributionOptionDialog from '@/components/dialogs/PrintDistributionOptionDialog.vue';
import ConsentDistributionDialogV2 from '@/components/dialogs/ConsentDistributionDialogV2.vue';

export default {
  name: 'MeetingReportsView',
  components: {
    ReportCard,
    ReportCardV2,
    ExportFilesDialog,
    VoterRegistrationReportDialog,
    UserGuideReportTypeDialog,
    FormatOptionDialog,
    PrintDistributionOptionDialog,
    ConsentDistributionDialogV2
  },
  props: {
    currentMeeting: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      showVoterRegistrationDialog: false,
      showUserGuideDialog: false,
      showFormatOptionDialog: false,
      showPrintOptionDialog: false,
      showPrintOptionDialogV2: false,
      formatOption: '',
      reportTypeSelected: null,
      // V2 Dialogs
      dialogsV2: {
        'consent-distribution-v2': false,
        'consent-export-v2': false,
        'consent-export-v2-client': false
      },

      // Search and tab
      searchQuery: '',
      activeTab: 'tab-1' // Only applicable to admin view
    };
  },
  computed: {
    ...mapGetters('meetings/reports', [
      'meetingReportListData',
      'showExportDialog',
      'isExportProcessing',
      'exportDialogError'
    ]),
    ...mapGetters(['isAdmin', 'login']),
    reportList() {
      // Retrieve all non-beta reports with search filter
      return this.meetingReportListData
        .filter(report => !report?.beta)
        .filter(this.filterBySearch);
    },
    adminReportListV2() {
      // Retrieve all admin V2 reports with search filter (admin only)
      const betaReports = this.meetingReportListData
        .filter(report => report?.beta)
        .filter(report => !report?.client)
        .filter(this.filterBySearch);

      // If not multi-consent format, exclude multiConsentOnly reports
      if (!this.isMultiConsent) {
        return betaReports.filter(report => !report?.multiConsentOnly);
      }

      // Return all beta reports for multi-consent format
      return betaReports;
    },
    clientReportList() {
      // List of client reports to return only a specific list of reports for display
      const clientReportType = [
        'unit-overview-v2-client',
        'rsvp',
        'part-notice-events',
        'consent-export-v2-client',
        'eligible-rsvp',
        'live-ballots',
        'print-distribution-v2-client'
      ];

      // Retrieve all client V2 reports with search filter
      const clientReports = this.meetingReportListData
        .filter(report => clientReportType.includes(report?.reportType))
        .sort((a, b) => {
          // Sort based on the index in clientReportType array
          return (
            clientReportType.indexOf(a.reportType) -
            clientReportType.indexOf(b.reportType)
          );
        })
        .filter(this.filterBySearch);

      // If not multi-consent format, exclude multiConsentOnly reports
      if (!this.isMultiConsent) {
        return clientReports.filter(report => !report?.multiConsentOnly);
      }

      // Return all beta reports for multi-consent format
      return clientReports;
    },
    // Helper function to filter by search query
    filterBySearch() {
      return report => {
        if (!this.searchQuery) return true;

        // Convert to lower case
        const searchLower = this.searchQuery.toLowerCase();

        // List the relevant fields to search
        const searchableFields = [report.title, report.description].filter(
          Boolean
        ); // Remove any undefined fields

        // Convert field value to string, use lower-case comparison, check if search term exists in searchable fields
        return searchableFields.some(field =>
          String(field)
            .toLowerCase()
            .includes(searchLower)
        );
      };
    },
    isMultiConsent() {
      return this.currentMeeting.options?.consentFormat === 'multi-consent';
    }
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('meetings/reports', [
      'getMeetingReportList',
      'downloadReport',
      'exportReport',
      'openExportDialog',
      'closeExportDialog'
    ]),
    init() {
      this.getMeetingReportList({ shortCode: this.shortCode }).catch(err =>
        this.$events.$emit('showErrorDialog', err.response)
      );
    },
    handleReportClick({ action, reportType }) {
      let region = 'default';
      if (reportType === 'voter-registration') {
        this.showVoterRegistrationDialog = true;
        return;
      }

      if (
        this.currentMeeting?.region === 'FL' &&
        (reportType === 'unit-overview' || reportType === 'consents')
      ) {
        if (this.isAdmin) {
          if (reportType === 'unit-overview') {
            this.formatOption = 'download';
          } else {
            this.formatOption = 'send';
          }
          this.showFormatOptionDialog = true;
          return;
        } else {
          region = 'florida';
        }
      }

      if (['print-distribution', 'print-files-export'].includes(reportType)) {
        this.reportTypeSelected = reportType;
        this.showPrintOptionDialog = true;
        return;
      }

      if (action === 'export') {
        return this.exportReport({
          shortCode: this.shortCode,
          type: reportType,
          region
        });
      }

      if (reportType === 'user-guide') {
        this.showUserGuideDialog = true;
        return;
      }

      this.clickDownloadReport(reportType, region);
    },
    clickDownloadReport(reportType, region) {
      this.downloadReport({
        shortCode: this.shortCode,
        reportType,
        $events: this.$events,
        region
      });
    },
    sendVoterRegistrationReport(mode = 'all') {
      this.showVoterRegistrationDialog = false;
      return this.exportReport({
        shortCode: this.shortCode,
        type: 'voter-registration',
        mode
      });
    },
    downloadGuide({ guide }) {
      this.clickDownloadReport(guide);
    },
    downloadUnitOverviewReport({ format }) {
      this.downloadReport({
        shortCode: this.shortCode,
        reportType: 'unit-overview',
        $events: this.$events,
        region: format
      });
      this.showFormatOptionDialog = false;
    },
    sendConsentReport({ format }) {
      this.exportReport({
        shortCode: this.shortCode,
        type: 'consents',
        region: format
      });
      this.showFormatOptionDialog = false;
    },
    handlePrintAction({ option, reportType, action }) {
      try {
        const region = 'default';

        if (action === 'download') {
          return this.downloadReport({
            shortCode: this.shortCode,
            reportType,
            $events: this.$events,
            region,
            filters: { [option]: true }
          });
        }

        this.exportReport({
          shortCode: this.shortCode,
          type: reportType,
          region,
          filters: { [option]: true }
        });
      } catch (error) {
        this.$events.$emit('showErrorDialog', error.response);
      } finally {
        this.showPrintOptionDialog = false;
      }
    },

    // Handle a V2 report click
    handleReportClickV2({ action, reportType }) {
      // Check if the report type requires checkbox dialogs
      const checkboxDialogReports = [
        'consent-distribution-v2',
        'consent-export-v2',
        'consent-export-v2-client'
      ];

      // For multi-consent and print type reports, open V2 print dialog
      if (this.isMultiConsent && checkboxDialogReports.includes(reportType)) {
        this.reportTypeSelected = reportType;
        this.dialogsV2['consent-distribution-v2'] = true;
        return;
      }

      // Handle report action without filters
      this.handleReportActionV2({ action, reportType });
    },
    // Handle report action and pass report params
    handleReportActionV2({
      action,
      reportType,
      options = [],
      region = 'default'
    }) {
      // If applicable to report, return filters that evaluate to "true"
      const filters = options.reduce((acc, option) => {
        acc[option] = true;
        return acc;
      }, {});

      // Check if the action type is valid
      if (!['download', 'export'].includes(action)) {
        throw new Error('Invalid action');
      }

      // Define report parameters
      const reportParams = {
        shortCode: this.shortCode,
        reportType,
        $events: this.$events,
        region,
        filters,
        version: 2
      };

      try {
        if (action === 'download') {
          return this.downloadReport({
            ...reportParams
          });
        } else if (action === 'export') {
          this.exportReport({
            ...reportParams,
            type: reportType
          });
        }
      } catch (error) {
        this.$events.$emit('showErrorDialog', error.response);
      } finally {
        this.dialogsV2['consent-distribution-v2'] = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.gq-divider {
  border-top: 2px solid #3874cb !important;
  margin: 8px 0 8px 0;
}
</style>
