<template>
  <div class="mt-4 pl-3">
    <template
      v-if="
        UIVotingOption === 'limited-proxy' ||
          UIVotingOption === 'ballot-limited-proxy' ||
          UIVotingOption === 'advance-ballot'
      "
    >
      <div class="title grey--text text--darken-4">
        Template
      </div>
      <div class="body-1 grey--text text--darken-2 mb-3">
        Define the
        {{ UIVotingOption === 'advance-ballot' ? 'ballot' : 'proxy' }}
        form template, by default is Bill-106 compliant
      </div>

      <v-layout row justify-space-between>
        <v-flex xs4>
          <v-select
            :label="templateTypeLabel"
            :items="templateType"
            item-text="name"
            :value="proxyTemplateValue"
            @input="selectChange($event)"
          />
        </v-flex>
        <v-flex xs4>
          <v-select
            v-if="proxyTemplateValue === 'alberta'"
            label="Sub-Template"
            :items="subTemplateTypes"
            item-text="name"
            :value="proxySubTemplateValue"
            @input="updateTemplate($event)"
          />
        </v-flex>
        <v-btn
          v-if="proxyTemplateValue === 'custom'"
          flat
          @click.native="openConfirmDialog"
          >Fill Sample Form <v-icon right>filter_none</v-icon></v-btn
        >
      </v-layout>

      <div v-if="proxyTemplateValue !== 'on-bill-106' || showEditor">
        <rich-text-editor
          v-if="showEditor"
          :body="templateContent"
          :key="renderKey"
          :hide-merge-menu="true"
          :show-custom-fields="true"
          custom-label="Proxy Merge Variables"
          :custom-fields="customFields"
          @update="onChange('proxyTemplate.content', $event)"
        ></rich-text-editor>
      </div>
      <div v-else>
        <v-alert outline color="info" icon="info" :value="true">
          <strong>Bill-106 template</strong> uses the default proxy form and
          ignores custom legal text on the business tab.<br />With the exception
          to define an optional
          <strong>Custom Revocation Text</strong> below.<br />This will replace
          the text <i>"I (we) revoke all proxies previously given."</i> on the
          proxy form.
        </v-alert>

        <div class="pr-3 mt-3">
          <v-textarea
            label="Custom Revocation Text"
            :value="template.custom_revocation"
            box
            @input="onChange('proxyTemplate.custom_revocation', $event)"
          />
        </div>
      </div>
    </template>
    <template v-else>
      <div>
        <v-layout>
          <div class="pr-3">
            <v-checkbox
              :true-value="true"
              :false-value="false"
              v-model="displayCustomGeneralProxyContent"
              @change="
                onChange('options.displayCustomGeneralProxyContent', $event)
              "
            />
          </div>

          <div>
            <h5 class="subheading">
              Enable custom content for General Proxy
            </h5>
            <p class="grey--text text--darken-2">
              Check this to add a custom content for general proxy in the proxy
              page.
            </p>
          </div>
        </v-layout>

        <v-layout v-if="displayCustomGeneralProxyContent">
          <v-flex xs12>
            <rich-text-editor
              :hide-merge-menu="true"
              :body="customContent"
              @update="onChange('options.customGeneralProxyContent', $event)"
            />
          </v-flex>
        </v-layout>
      </div>
    </template>
  </div>
</template>

<script>
import _ from 'lodash';

import { getRecipe } from '@/api/recipes';

import RichTextEditor from '@/components/RichTextEditor/RichTextEditor';

export default {
  name: 'MeetingSettingsTemplateOptions',

  components: {
    RichTextEditor
  },

  props: {
    template: {
      type: Object,
      default() {
        return {};
      }
    },
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    UIVotingOption: {
      type: String,
      default: ''
    },
    proxyTemplateValue: {
      type: String,
      default: ''
    },
    proxySubTemplateValue: {
      type: String,
      default: ''
    },
    showEditor: {
      type: Boolean,
      default: false
    },
    templateContent: {
      type: String,
      default: ''
    },
    renderKey: {
      type: Number,
      default: 0
    },
    customContent: {
      type: String,
      default: ''
    }
  },

  computed: {
    templateTypeLabel() {
      return this.UIVotingOption === 'advance-ballot'
        ? 'Ballot Template'
        : 'Proxy Template';
    }
  },

  data() {
    return {
      templateType: [
        { name: 'Bill-106', value: 'on-bill-106' },
        { name: 'Alberta', value: 'alberta' },
        { name: 'Custom', value: 'custom' }
      ],
      subTemplateTypes: [
        { name: 'General', value: 'general' },
        { name: 'Limited', value: 'limited' }
      ],
      displayCustomGeneralProxyContent: false,
      customFields: []
    };
  },

  async mounted() {
    await this.setProxyMergeFields();

    this.displayCustomGeneralProxyContent = _.get(
      this.options,
      'displayCustomGeneralProxyContent',
      false
    );
  },

  methods: {
    selectChange(event) {
      this.$emit('selectChange', event);
    },
    updateTemplate(event) {
      this.$emit('updateTemplate', event);
    },
    onChange(key, event) {
      this.$emit('onChange', key, event);
    },
    openConfirmDialog() {
      this.$emit('openConfirmDialog');
    },
    async setProxyMergeFields() {
      try {
        const recipe = await getRecipe('meetings.settings.proxy_merge_fields');
        const recipeData = recipe.data.data;

        this.customFields = recipeData.fields;
      } catch (err) {
        console.error('Error getting merge fields', err);
      }
    }
  }
};
</script>

<style scoped></style>
