<template>
  <v-container fluid>
    <h1 class="headline">Global Membership Search</h1>

    <p class="subheading">Search for a voter or member by name or email</p>

    <p>Note: at least 3 characters required to search</p>

    <v-toolbar light class="grey lighten-4 elevation-1" style="height: 73px;">
      <v-text-field
        prepend-icon="search"
        label="Start typing to search..."
        single-line
        hide-details
        class="shrink"
        clearable
        :value="searchQuery"
        @input="updateSearchQuery($event)"
      />
      <div class="column-flex ml-5 mr-2">
        <span class="ml-4 mr-2">Search By:</span>
        <v-btn-toggle v-model="searchToggle" mandatory class="elevation-1 mt-1">
          <v-btn :disabled="isLoading" flat color="primary" value="email">
            <span class="caption">Email</span>
          </v-btn>
          <v-btn :disabled="isLoading" flat color="primary" value="name">
            <span class="caption">Name</span>
          </v-btn>
        </v-btn-toggle>
      </div>

      <div class="column-flex ml-5 mr-2">
        <span class="ml-4 mr-2">Meeting Date:</span>
        <v-btn-toggle v-model="meetingDate" mandatory class="elevation-1 mt-1">
          <v-btn :disabled="isLoading" flat color="primary" value="upcoming">
            <span class="caption">Upcoming</span>
          </v-btn>

          <v-menu offset-y>
            <v-btn
              :disabled="isLoading"
              slot="activator"
              light
              flat
              color="blue"
              value="past"
            >
              Past
              <v-icon>arrow_drop_down</v-icon>
            </v-btn>

            <v-list>
              <v-list-tile
                @click="pastMonths = '6'"
                :class="pastMonths === '6' ? 'active' : ''"
              >
                <v-list-tile-content>
                  <v-list-tile-title>6 months</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>

              <v-list-tile
                @click="pastMonths = '12'"
                :class="pastMonths === '12' ? 'active' : ''"
              >
                <v-list-tile-content>
                  <v-list-tile-title>1 year</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>

              <v-list-tile
                @click="pastMonths = '36'"
                :class="pastMonths === '36' ? 'active' : ''"
              >
                <v-list-tile-content>
                  <v-list-tile-title>3 years</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>

              <v-list-tile
                @click="pastMonths = 'all'"
                :class="pastMonths === 'all' ? 'active' : ''"
              >
                <v-list-tile-content>
                  <v-list-tile-title>All</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-menu>
        </v-btn-toggle>
      </div>

      <div class="column-flex ml-5 mr-2">
        <span class="ml-4 mr-2"
          >Meeting Status:
          <v-tooltip top>
            <span slot="activator">
              <v-icon small>info</v-icon>
            </span>
            <span
              >OFFICIAL: no status, extra-care, hybrid, reconvene, requisition,
              adjourned, postponed, cancelled <br />
              UNOFFICIAL: demo, test, pending deleted, denied</span
            >
          </v-tooltip>
        </span>
        <v-btn-toggle
          v-model="meetingStatus"
          mandatory
          class="elevation-1 mt-1"
        >
          <v-btn :disabled="isLoading" flat color="primary" value="official">
            <span class="caption">Official</span>
          </v-btn>
          <v-btn :disabled="isLoading" flat color="primary" value="unofficial">
            <span class="caption">Unofficial</span>
          </v-btn>
        </v-btn-toggle>
      </div>

      <div class="column-flex ml-5 mr-2">
        <span class="ml-4 mr-2">Deleted voters:</span>
        <v-btn-toggle
          v-model="includeDeletedVoters"
          mandatory
          class="elevation-1 mt-1"
        >
          <v-btn :disabled="isLoading" flat color="primary" :value="false">
            <span class="caption">Exclude</span>
          </v-btn>
          <v-btn :disabled="isLoading" flat color="primary" :value="true">
            <span class="caption">Include</span>
          </v-btn>
        </v-btn-toggle>
      </div>

      <div class="column-flex ml-5 mr-2">
        <span class="ml-4 mr-2">Limit to:</span>
        <v-menu offset-y>
          <v-btn slot="activator" color="primary" class="mt-1 mb-0 py-0">
            {{ limitTo }}
            <v-icon class="ml-1">arrow_drop_down</v-icon>
          </v-btn>
          <v-list>
            <v-list-tile @click="limitTo = '5'">
              <v-list-tile-content>
                <v-list-tile-title>5</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>

            <v-list-tile @click="limitTo = '20'">
              <v-list-tile-content>
                <v-list-tile-title>20</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>

            <v-list-tile @click="limitTo = '50'">
              <v-list-tile-content>
                <v-list-tile-title>50</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>

            <v-list-tile @click="limitTo = 'all'">
              <v-list-tile-content>
                <v-list-tile-title>All</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-menu>
      </div>
    </v-toolbar>
    <v-data-table
      :loading="isLoading"
      v-if="searchQuery && searchQuery.length > 2"
      :headers="headers"
      :items="items"
      :search="searchQuery"
      :rows-per-page-items="config.rowsPerPageItems"
      :pagination.sync="defaultPagination"
      class="elevation-1"
    >
      <template slot="items" slot-scope="props">
        <tr
          :key="props.index"
          :class="props.item.deletedAt ? 'deleted-voter' : ''"
        >
          <td
            class="text-xs-left text-highlight name-overflow"
            :class="props.item.deletedAt ? '' : 'pointer link-style'"
            @click="onClickVoter(props.item, props.item.name)"
          >
            {{ props.item.name }}
            <v-tooltip bottom>
              <v-icon small slot="activator" v-if="props.item.deletedAt"
                >info</v-icon
              >
              <span>
                Deleted at:
                {{ props.item.deletedDate }}
              </span>
            </v-tooltip>
          </td>
          <td
            class="text-xs-left"
            :class="props.item.deletedAt ? '' : 'pointer link-style'"
            @click="onClickVoter(props.item, props.item.email)"
          >
            {{ props.item.email }}
          </td>
          <td class="text-xs-left">
            {{ props.item.unit }}
          </td>
          <td class="text-xs-left">
            {{ props.item.meetingName }}
          </td>
          <td class="text-xs-left">
            {{ props.item.date }}
          </td>
          <td class="text-xs-left">
            <v-tooltip
              bottom
              v-if="
                props.item.status.consent &&
                  props.item.status.consent !== 'rejected'
              "
            >
              <v-icon
                slot="activator"
                color="green lighten-1"
                v-show="
                  props.item.status.consent &&
                    props.item.status.consent !== 'rejected' &&
                    props.item.email !== 'noemail@getquorum.com'
                "
                >email</v-icon
              >
              <span>
                Owner consented on:
                {{
                  props.item.status.consent
                    ? formatDateReadable(
                        props.item.status.consentTimestamp,
                        props.item.meetingTimezone
                      )
                    : 'Not Available'
                }}
              </span>
            </v-tooltip>
            <v-tooltip bottom>
              <v-icon
                slot="activator"
                color="blue lighten-1"
                v-show="props.item.status.rsvp > 0"
                >how_to_reg</v-icon
              >
              <span>Owner has RSVP'd</span>
            </v-tooltip>
            <v-tooltip bottom>
              <v-icon
                slot="activator"
                color="purple lighten-1"
                v-show="props.item.status.attendance > 0"
                >person_pin_circle</v-icon
              >
              <span>Owner is attending event</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { formatDate, formatDateHumanReadable } from '@/helpers';
import { handleError } from '@/helpers';

export default {
  name: 'VotersHomeView',
  components: {},
  data() {
    return {
      defaultPagination: {
        descending: true,
        sortBy: 'meetingDate'
      },
      searchToggle: 'email',
      meetingDate: 'upcoming',
      meetingStatus: 'official',
      includeDeletedVoters: false,
      pastMonths: '6',
      limitTo: '5',
      isLoading: false,
      headers: [
        { text: 'Voter Name', value: 'name', align: 'left' },
        { text: 'Email', value: 'email', align: 'left' },
        { text: 'Unit', value: 'email', align: 'left' },
        { text: 'Meeting Name', value: 'meetingName', align: 'left' },
        { text: 'Meeting Date', value: 'meetingDate', align: 'left' },
        { text: 'Status', value: 'status', align: 'left' }
      ],
      config: {
        rowsPerPageItems: [10, 25, 50, { text: 'All', value: -1 }]
      }
    };
  },

  watch: {
    searchToggle: function() {
      const value = this.searchQuery;
      if (!value || (value && value.length < 3)) {
        this.cleanItems();
        return;
      }
      this.fetchVotersData();
    },
    meetingDate: function() {
      this.fetchVotersData();
    },
    meetingStatus: function() {
      this.fetchVotersData();
    },
    includeDeletedVoters: function() {
      this.fetchVotersData();
    },
    pastMonths: function() {
      this.fetchVotersData();
    },
    limitTo: function() {
      this.fetchVotersData();
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapGetters('voters', ['searchQuery', 'votersListData']),
    items() {
      return this.votersListData.map(voter => {
        voter.date = this.formatMeetingDate(
          voter.meetingDate,
          voter.meetingTimezone
        );
        voter.status = {
          consent: voter.consent.status,
          consentTimestamp: voter.consent.timestamp,
          rsvp: voter.attending,
          attendance: voter.inAttendance
        };
        if (voter.deletedAt) {
          voter.deletedDate = this.formatMeetingDate(
            voter.deletedAt,
            voter.meetingTimezone
          );
        }
        return voter;
      });
    }
  },
  methods: {
    ...mapActions('voters', ['setSearchQuery', 'getVoters', 'cleanVoters']),
    async fetchVotersData() {
      try {
        this.isLoading = true;
        await this.getVoters({
          searchText: this.searchQuery,
          type: this.searchToggle,
          meetingDate: this.meetingDate,
          meetingStatus: this.meetingStatus,
          includeDeletedVoters: this.includeDeletedVoters,
          pastMonths: this.pastMonths,
          limitTo: this.limitTo
        });
      } catch (err) {
        console.error('Error getting voters list data', err);
        handleError(err);
        throw err;
      } finally {
        this.isLoading = false;
      }
    },
    onClickVoter(voter, searchValue) {
      if (voter.deletedAt || !voter.name) {
        return;
      }
      this.$router.push({
        name: 'meetingUnits',
        params: {
          shortcode: voter.shortCode
        },
        query: { search: searchValue }
      });
    },
    formatMeetingDate(meetingDate, meetingTimezone) {
      return formatDate(
        meetingDate,
        meetingTimezone ? meetingTimezone : 'America/Toronto',
        'MMMM Do YYYY, h:mmA zz'
      );
    },
    cleanItems() {
      this.cleanVoters();
    },

    formatDateReadable(date, meetingTimezone) {
      return formatDateHumanReadable(date, false, meetingTimezone);
    },
    updateSearchQuery: _.debounce(function(value) {
      this.setSearchQuery(value);
      if (!value || (value && value.length < 3)) {
        this.cleanItems();
        return;
      }
      this.fetchVotersData();
    }, 800)
  }
};
</script>

<style scoped>
.name-overflow {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pointer {
  cursor: pointer;
}
.deleted-voter {
  text-decoration: line-through;
}
.link-style {
  text-decoration: underline;
  color: #0077cc;
}
.column-flex {
  display: flex;
  flex-direction: column;
}
.active {
  background-color: #e7f2fb;
  color: #0f64a9;
}
</style>
