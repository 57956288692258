<template>
  <section class="meeting-settings">
    <v-container>
      <div class="actions right-align">
        <div class="caption">Last saved {{ lastUpdate }}</div>
        <v-spacer> </v-spacer>
        <div v-show="isChanged" class="caption">You have unsaved changes</div>
        <v-btn class="error" @click="onSaveClick"
          >Save
          <v-icon right>save</v-icon>
        </v-btn>
      </div>
      <div class="setting-container elevation-1">
        <div class="list-container">
          <div class="list-header body-2 grey--text">Meeting Settings</div>
          <div v-for="item in settingList" :key="item.name">
            <template v-if="item.group">
              <div class="list-item" @click="handleDropDownClick(item)">
                <span>{{ item.label }}</span>
                <v-icon v-if="!dropdownSelected[item.name]"
                  >keyboard_arrow_down</v-icon
                >
                <v-icon v-else>keyboard_arrow_up</v-icon>
              </div>

              <template v-if="dropdownSelected[item.name]">
                <div v-for="sItem in item.items" :key="sItem.name">
                  <div
                    class="list-item"
                    :class="{ 'active-list-item': isActiveListItem(sItem) }"
                    @click="handleListClick(sItem)"
                  >
                    <span v-if="sItem.class" :class="sItem.class">{{
                      sItem.label
                    }}</span>
                    <span v-else>{{ sItem.label }}</span>
                  </div>
                </div>
              </template>
            </template>

            <div
              v-else
              class="list-item"
              :class="{ 'active-list-item': isActiveListItem(item) }"
              @click="handleListClick(item)"
            >
              <span v-if="item.class" :class="item.class">{{
                item.label
              }}</span>
              <span v-else>{{ item.label }}</span>
            </div>
            <v-divider v-if="item.groupEnd"></v-divider>
          </div>

          <div class="list-item">
            <div>
              <v-switch
                label="Use V2"
                :input-value="meetingSettingVersion"
                @change="toggleVersion"
              />
            </div>

            <v-tooltip right class="ml-2 pb-2">
              <span slot="activator">
                <v-layout class="mt-1">
                  <v-icon>info</v-icon>
                </v-layout>
              </span>
              <span
                >(Experimental) Toggle this button, then save, then refresh to
                see the changes</span
              >
            </v-tooltip>
          </div>
        </div>

        <div class="form-container">
          <!--
            Keep alive allows components to keep state event when switching
          -->
          <keep-alive>
            <component
              :is="selected"
              v-bind="currentProperties"
              @update="handleUpdate"
              @delete="handleDelete"
            />
          </keep-alive>
        </div>
      </div>
    </v-container>

    <!-- Update Dialog -->
    <v-dialog v-model="dialog.update" max-width="400px">
      <v-card>
        <v-card-title> <div class="title">Are you sure?</div> </v-card-title>
        <v-card-text v-if="updateWebcast"
          >The Webcast Date and duration for this meeting will also be updated.
          Please 'Confirm' before proceeding. A notification will be sent to
          other admins on this page</v-card-text
        >
        <v-card-text v-if="updateSubmissionDeadline"
          >The Submission Deadline for this meeting is being updated. Please
          check/update the Enable Vote Breakdown date after
          proceeding.</v-card-text
        >
        <v-card-text v-else
          >Click Save to confirm changes. A notification will be sent to other
          admins on this page.</v-card-text
        >
        <v-card-actions>
          <v-spacer />
          <v-btn flat @click.native="dialog.update = false">close</v-btn>
          <v-btn
            v-if="updateWebcast"
            class="primary"
            @click="submitUpdate"
            :loading="saving"
            >Confirm</v-btn
          >
          <v-btn v-else class="primary" @click="submitUpdate" :loading="saving"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirm-dialog
      :dialog="dialog.confirmChanges"
      title="Leave page?"
      text="There are unsaved changes. Click 'Save and Leave' to confirm the changes. This action will send a notification to other admins on this page."
      confirmText="Save and Leave"
      additionalText="Discard and Leave"
      width="600"
      :showDeclineBtn="false"
      @confirm="submitUpdate"
      @extra="redirectControl.nextAction()"
    />

    <confirm-dialog
      :dialog="reloadDialog"
      title="Meeting Settings Changed"
      text="Meeting settings have been changed. Click reload to load the new settings."
      confirmText="Reload Page"
      :showDeclineBtn="false"
      @confirm="reloadSettings"
    />
  </section>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment-timezone';
import { getRecipe } from '@/api/recipes';

import { toggleOpenBallot } from '@/lib/toggle-open-ballot';

import GeneralInput from './MeetingSettingsGeneralInput';
import CondoInput from './MeetingSettingsCondoInput';
import ProxyInput from './MeetingSettingsProxyInput';
import VotingOptionsInput from './MeetingSettingsVotingOptionsInput';
import AdvanceVotingV2 from './MeetingSettingsAdvanceVotingV2';
import LiveVotingV2 from './MeetingSettingsLiveVotingV2';
import CustomData from './MeetingSettingsCustomDataInput';
import DateInput from './MeetingSettingsDateInput';
import DocumentsInput from './MeetingSettingsDocumentsInput';
import OptionsInput from './MeetingSettingsOptionsInput';
import VirtualMeetingInput from './MeetingSettingsVirtualMeetingInput';
import VoterRegistrationInput from './MeetingSettingsVoterRegistrationInput';
import ConsentOptionsInput from './MeetingSettingsConsentOptionsInput';
import InviteTemplateInput from './MeetingSettingsInviteTemplateInput';
import PartnerTemplateInput from './MeetingSettingsPartnerTemplateInput';
import SecurityInput from './MeetingSettingsSecurityInput';
import ReminderSystemInput from './MeetingSettingsReminderSystemInput';
import AdvancedInput from './MeetingSettingsAdvancedInput';
import AdvancedInputV2 from './MeetingSettingsAdvancedInputV2';
import ProxyOptionsInput from './MeetingSettingsProxyOptionsInput';
import IntegrationInput from './MeetingSettingsIntegrationInput';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog';

export default {
  name: 'MeetingSettings',
  components: {
    GeneralInput,
    CondoInput,
    ProxyInput,
    VotingOptionsInput,
    AdvanceVotingV2,
    LiveVotingV2,
    CustomData,
    DateInput,
    DocumentsInput,
    OptionsInput,
    VirtualMeetingInput,
    VoterRegistrationInput,
    ConsentOptionsInput,
    InviteTemplateInput,
    PartnerTemplateInput,
    SecurityInput,
    ReminderSystemInput,
    AdvancedInput,
    AdvancedInputV2,
    ProxyOptionsInput,
    IntegrationInput,
    ConfirmDialog
  },
  props: {
    currentMeeting: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      state: {},
      reminderSettingsState: {},
      shortCode: this.$route.params.shortcode,
      settingList: [
        { label: 'General', name: 'GeneralInput' },
        { label: 'Condo & Contact', name: 'CondoInput' },
        { label: 'Dates', name: 'DateInput' },
        { label: 'Meeting Documents', name: 'DocumentsInput' },
        {
          label: 'Proxy / Ballot',
          name: 'ProxyBallot',
          group: true,
          items: [
            {
              label: 'Template',
              name: 'VotingOptionsInput',
              class: 'ml-4'
            },
            {
              label: 'Voting Settings',
              name: 'ProxyOptionsInput',
              class: 'ml-4'
            },
            { label: 'Proxy Holders', name: 'ProxyInput', class: 'ml-4' },
            {
              label: 'Custom Options',
              name: 'OptionsInput',
              class: 'ml-4'
            }
          ]
        },

        {
          label: 'Custom Field',
          name: 'CustomData'
        },

        { label: 'Virtual Meeting', name: 'VirtualMeetingInput' },
        { label: 'Voter Registration', name: 'VoterRegistrationInput' },
        { label: 'Consent Options', name: 'ConsentOptionsInput' },
        {
          label: 'Meeting Invite Template',
          name: 'InviteTemplateInput'
        },
        { label: 'Partner Options', name: 'PartnerTemplateInput' },
        { label: 'Security', name: 'SecurityInput' },
        {
          label: 'Reminder System',
          name: 'ReminderSystemInput'
        },
        { label: 'Advanced / Experimental', name: 'AdvancedInput' },
        { label: 'Integration', name: 'IntegrationInput' }
      ],
      selected: 'GeneralInput',
      updateWebcast: false,
      updateSubmissionDeadline: false,
      updateReminderSettings: false,
      saving: false,
      integratedPortalUpdate: false,
      dialog: {
        update: false,
        confirmChanges: false
      },
      commonCustomFieldsProp: [],
      dropdownSelected: {},
      redirectControl: {
        redirect: false,
        nextAction: ''
      }
    };
  },
  computed: {
    ...mapGetters('meetings', ['meetingReminderSettings', 'reloadDialog']),
    ...mapGetters('meetings/partners', ['partnerData', 'initialPartnerData']),
    ...mapGetters('accounts', ['accountByKey']),
    currentAccount() {
      const accountKey = this.currentMeeting.accountKey;
      return this.accountByKey(accountKey);
    },
    // Used as prop for inserting custom data fields
    customUnitFields() {
      if (_.has(this.state.options, 'customUnitFields')) {
        return this.state.options.customUnitFields;
      }
    },
    isChanged() {
      return (
        !_.isEqual(this.state, this.currentMeeting) ||
        !_.isEqual(this.reminderSettingsState, this.meetingReminderSettings) ||
        !this.isSamePartner
      );
    },
    isSamePartner() {
      const newPartnerKey = this.partnerData ? this.partnerData.key : null;
      const originalPartnerKey = this.initialPartnerData
        ? this.initialPartnerData.key
        : null;

      return newPartnerKey === originalPartnerKey;
    },
    currentProperties() {
      switch (this.selected) {
        case 'GeneralInput':
          return {
            url: this.currentMeeting.url,
            name: this.currentMeeting.name,
            location: this.currentMeeting.location,
            description: this.currentMeeting.description,
            meetingAbout: this.currentMeeting.meetingAbout,
            meetingDate: this.currentMeeting.meetingDate,
            quorumMinimum: this.currentMeeting.quorumMinimum,
            options: this.currentMeeting.options,
            meetingStatus: this.currentMeeting.meetingStatus
          };
        case 'DateInput':
          return {
            meetingDate: this.currentMeeting.meetingDate,
            meetingTimezone: this.currentMeeting.meetingTimezone,
            duration: this.currentMeeting.duration,
            expiryDate: this.currentMeeting.expiryDate,
            nominationExpiryDate: this.currentMeeting.nominationExpiryDate,
            revocationDeadlineDate: this.currentMeeting.revocationDeadlineDate,
            consentDeadlineDate: this.currentMeeting.consentDeadlineDate,
            corpShortname: this.currentMeeting.corporation.legalNameShort,
            disableNominations: this.currentMeeting.options.disableNominations,
            enableVoteBreakdown: this.currentMeeting.options
              .enableVoteBreakdown,
            enableVoteBreakdownDate: this.currentMeeting.options
              .enableVoteBreakdownDate
              ? this.currentMeeting.options.enableVoteBreakdownDate
              : this.currentMeeting.expiryDate
          };
        case 'CondoInput':
          return {
            corporation: this.currentMeeting.corporation,
            contact: this.currentMeeting.contact,
            addresses: this.currentMeeting.addresses,
            signature: this.currentMeeting.options.signature,
            proxyNotification: this.currentMeeting.options
              .proxyNotificationEmail,
            replyTo: this.currentMeeting.options.replyToEmail,
            options: this.currentMeeting.options,
            logoUrl: this.currentMeeting.logoUrl,
            region: this.currentMeeting.region
          };
        case 'ProxyInput':
          return {
            defaultHolders: this.currentMeeting.defaultHolders,
            options: this.currentMeeting.options
          };
        case 'CustomData':
          return {
            fieldArray: this.customUnitFields,
            commonFields: this.commonCustomFieldsProp
          };
        case 'DocumentsInput':
          return {
            attachment: this.state.documents,
            shortCode: this.shortCode
          };
        case 'VotingOptionsInput':
          return {
            options: this.currentMeeting.options,
            template: this.currentMeeting.proxyTemplate
          };
        case 'AdvanceVotingV2':
          return {
            options: this.currentMeeting.options,
            template: this.currentMeeting.proxyTemplate,
            defaultHolders: this.currentMeeting.defaultHolders,
            quorumMinimum: this.currentMeeting.quorumMinimum
          };
        case 'LiveVotingV2':
          return {
            options: this.currentMeeting.options
          };
        case 'OptionsInput':
          return {
            options: this.currentMeeting.options,
            template: this.currentMeeting.proxyTemplate
          };
        case 'VirtualMeetingInput':
          return {
            options: this.currentMeeting.options
          };
        case 'VoterRegistrationInput':
          return {
            options: this.currentMeeting.options
          };
        case 'ConsentOptionsInput':
          return {
            options: this.currentMeeting.options,
            // Consent template is used to store custom html templates
            template: this.currentMeeting.consentTemplate,
            shortCode: this.shortCode
          };
        case 'InviteTemplateInput':
          return {
            options: this.currentMeeting.options,
            template: this.currentMeeting.inviteTemplate,
            shortCode: this.shortCode
          };
        case 'PartnerTemplateInput':
          return {};
        case 'SecurityInput':
          return {
            options: this.currentMeeting.options
          };
        case 'ReminderSystemInput':
          return {
            meetingDate: this.currentMeeting.meetingDate,
            meetingTimezone: this.currentMeeting.meetingTimezone,
            options: this.currentMeeting.options,
            reminderSettings: this.meetingReminderSettings
          };
        case 'AdvancedInput':
          return {
            options: this.currentMeeting.options
          };
        case 'AdvancedInputV2':
          return {
            options: this.currentMeeting.options
          };
        case 'ProxyOptionsInput':
          return {
            options: this.currentMeeting.options
          };
        case 'IntegrationInput':
          return {
            currentAccount: this.currentAccount,
            options: this.currentMeeting.options,
            meetingTimezone: this.currentMeeting.meetingTimezone,
            meetingDate: this.currentMeeting.meetingDate,
            shortCode: this.shortCode,
            consentDeadlineDate: this.currentMeeting.consentDeadlineDate
          };
        default:
          throw new Error('Invalid selected type');
      }
    },
    lastUpdate() {
      return moment(this.currentMeeting.updatedAt).fromNow();
    },
    meetingSettingVersion() {
      if (
        this.currentMeeting &&
        this.currentMeeting.options &&
        this.currentMeeting.options.useV2Setting
      )
        return true;
      else return false;
    }
  },
  mounted() {
    if (this.currentMeeting.options.useV2Setting) {
      this.settingList = [
        { label: 'General', name: 'GeneralInput' },
        { label: 'Condo & Contact', name: 'CondoInput' },
        { label: 'Dates', name: 'DateInput' },
        { label: 'Meeting Documents', name: 'DocumentsInput' },
        {
          label: 'Voting Options',
          name: 'ProxyBallot',
          group: true,
          items: [
            {
              label: 'Advance Voting',
              name: 'AdvanceVotingV2',
              class: 'ml-4'
            },
            {
              label: 'Live Voting',
              name: 'LiveVotingV2',
              class: 'ml-4'
            }
          ]
        },

        {
          label: 'Custom Field',
          name: 'CustomData'
        },

        { label: 'Virtual Meeting', name: 'VirtualMeetingInput' },
        { label: 'Voter Registration', name: 'VoterRegistrationInput' },
        { label: 'Consent Options', name: 'ConsentOptionsInput' },
        {
          label: 'Meeting Invite Template',
          name: 'InviteTemplateInput'
        },
        { label: 'Partner Options', name: 'PartnerTemplateInput' },
        { label: 'Security', name: 'SecurityInput' },
        {
          label: 'Reminder System',
          name: 'ReminderSystemInput'
        },
        { label: 'Advanced / Experimental', name: 'AdvancedInputV2' },
        { label: 'Integration', name: 'IntegrationInput' }
      ];
    }

    // check for route query
    const { view = null } = this.$route.query;

    if (view) {
      this.selected = view;
    }
  },
  watch: {
    currentMeeting: function() {
      this.setState();
    }
  },
  created() {
    this.setState();
    this.setReminderState();
    this.getCommonFields();

    this.dropdownSelected = {
      ProxyBallot: false
    };
  },
  beforeRouteLeave(_, __, next) {
    if (this.isChanged) {
      this.dialog.confirmChanges = true;
      // save the next action in an state
      this.redirectControl = { redirect: true, nextAction: next };
      return;
    }

    // otherwise if not changes, just continue
    next();
  },
  methods: {
    ...mapActions('meetings', [
      'updateMeeting',
      'getMeetingReminderSettings',
      'updateMeetingReminderSettings',
      'getMeeting',
      'setReloadDialog'
    ]),
    ...mapActions('meetings/partners', ['changePartner', 'getPartner']),
    ...mapActions('meetings/webcast', [
      'createWebcast',
      'updateWebcastWebinar'
    ]),
    async onSaveClick() {
      const isSame = moment(this.currentMeeting.meetingDate).isSame(
        moment(this.state.meetingDate)
      );
      const isExpiryDateSame = moment(this.currentMeeting.expiryDate).isSame(
        moment(this.state.expiryDate)
      );

      const isSameDuration =
        this.currentMeeting.duration === this.state.duration;

      this.dialog.update = true;

      if (this.currentMeeting.castId && (!isSame || !isSameDuration)) {
        this.updateWebcast = true;
      }

      if (
        this.currentMeeting.expiryDate &&
        !isExpiryDateSame &&
        this.currentMeeting.options.enableVoteBreakdown
      ) {
        this.updateSubmissionDeadline = true;
      }
    },
    async submitUpdate() {
      try {
        this.saving = true;

        if (!this.isSamePartner) {
          await this.changePartner({ shortCode: this.shortCode });
        }

        // Grab reminder settings before updating meeting
        // Local state will be overwritten by this.updateMeeting below
        const reminderSettings = this.updateReminderSettings
          ? {
              enableReminders: this.reminderSettingsState.enableReminders,
              reminderStartDate: this.reminderSettingsState.reminderStartDate,
              reminderFrequency: this.reminderSettingsState.reminderFrequency,
              joinReminder: this.reminderSettingsState.joinReminder,
              reminderTemplate: this.reminderSettingsState.reminderTemplate,
              reminderSubject: this.reminderSettingsState.reminderSubject,
              joinTemplate: this.reminderSettingsState.joinTemplate,
              joinSubject: this.reminderSettingsState.joinSubject,
              reminderReplyTo: this.reminderSettingsState.reminderReplyTo
            }
          : null;

        await this.updateMeeting({
          shortCode: this.shortCode,
          meeting: { ...this.state }
        });

        if (reminderSettings) {
          await this.updateMeetingReminderSettings({
            shortCode: this.state.shortCode,
            reminderSettings
          });
        }

        if (this.updateWebcast || this.integratedPortalUpdate) {
          const isIntegratedPortalEnabled = _.get(
            this.currentMeeting,
            'options.enableRtvZoom',
            false
          );
          await this.updateWebCast(isIntegratedPortalEnabled);
        }

        this.saving = false;
        this.updateSubmissionDeadline = false;
        this.$events.$emit('toastEvent', 'Meeting Updated');
        this.dialog.update = false;

        if (this.redirectControl.redirect) {
          this.redirectControl.nextAction();
        }
      } catch (err) {
        // Reset dialog and saving indicator
        this.dialog.update = false;
        this.saving = false;
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async updateWebCast(isIntegratedPortalEnabled) {
      const momentDate = moment(this.state.meetingDate).tz(
        this.state.meetingTimezone
      );

      await this.updateWebcastWebinar({
        shortCode: this.state.shortCode,
        payload: {
          startTime: momentDate.format('YYYY-MM-DD[T]HH:mm:ss'),
          timezone: this.state.meetingTimezone,
          duration: this.state.duration,
          settings: {
            allowMultipleDevices: isIntegratedPortalEnabled
          }
        }
      });

      this.dialog.confirm = false;
      this.$events.$emit('toastEvent', 'Webcast Updated');
    },
    handleListClick(item) {
      this.selected = item.name;
    },
    handleDropDownClick(item) {
      this.dropdownSelected[item.name] = !this.dropdownSelected[item.name];
    },
    isActiveListItem(item) {
      return item.name === this.selected;
    },
    handleUpdate(obj) {
      let newState = _.cloneDeep(this.state);

      const reminderSettingKeys = [
        'enableReminders',
        'reminderStartDate',
        'reminderFrequency',
        'joinReminder',
        'reminderReplyTo',
        'reminderTemplate',
        'reminderSubject',
        'joinTemplate',
        'joinSubject'
      ];

      if (reminderSettingKeys.includes(obj.key)) {
        this.updateReminderSettings = true;
        _.set(this.reminderSettingsState, obj.key, obj.value);
      } else {
        this.state = _.set(newState, obj.key, obj.value);
      }

      // additional processing, if we're dealing with the Open Ballot checkbox
      if (obj.key === 'options.openBallot') {
        this.handleOpenBallot();
      }

      if (this.currentMeeting.castId && obj.key === 'options.enableRtvZoom') {
        this.integratedPortalUpdate = true;
      }
    },
    handleDelete(obj) {
      _.unset(this.state, obj.key, obj.value);
    },
    async handleOpenBallot() {
      // toggle Open Ballot settings on/off, and update local state
      try {
        const newMeetingObj = await toggleOpenBallot({ ...this.state });
        this.state = newMeetingObj;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    setState() {
      // Clone deep required for creating a copy of sub-properties.
      // If not cloned, then changes to subprops will also mutate the original
      // object which causes unintended actions.
      this.state = _.cloneDeep(this.currentMeeting);
    },
    async setReminderState() {
      try {
        await this.getMeetingReminderSettings({ shortCode: this.shortCode });
        this.reminderSettingsState = _.cloneDeep(this.meetingReminderSettings);
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async getCommonFields() {
      try {
        // Retrieve common Custom Fields recipe, to be passed as prop to
        // CustomData component
        const res = await getRecipe('meetings.settings.common_custom_fields');
        const commonFields = res.data;

        this.commonCustomFieldsProp = commonFields.data.fields;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async reloadSettings() {
      try {
        // reload the page to start from clean state
        window.location.reload();
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        // close the dialog
        this.setReloadDialog({ isOpen: false });
      }
    },

    toggleVersion(value) {
      this.handleUpdate({
        key: 'options.useV2Setting',
        value
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/common.scss';

.actions {
  margin-bottom: 10px;
}
.right-align {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.setting-container {
  display: flex;
}
.list-container {
  min-width: 215px;
  cursor: pointer;
  border-right: 1px solid #e0e0e0;
}
.list-header {
  padding: 10px 18px 10px 18px;
}
.list-item {
  display: flex;
  align-items: center;
  height: 45px;
  padding-left: 18px;
  font-size: 14px;
}
.list-container > .list-item:hover {
  background-color: #e0e0e0;
}
.form-container {
  width: 100%;
  background-color: #ffffff;
}
.active-list-item {
  padding-left: 18px;
  background-color: #039be5 !important;
  color: #ffffff !important;
}
</style>

<style scoped>
.container-padding {
  padding: 20px;
}
</style>
