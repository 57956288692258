var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('v-divider'),_c('v-expansion-panel',{staticClass:"elevation-0"},[_c('v-expansion-panel-content',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('span',{staticClass:"body-2"},[_vm._v("Home")])]),_c('v-card',[_c('v-card-text',[_c('v-layout',[_c('div',{staticClass:"pr-3"},[_c('v-checkbox',{attrs:{"true-value":true,"false-value":false},on:{"change":function($event){return _vm.onChange(
                    'options.proxyAppDisplayOptions.home.hideMeetingDocuments',
                    $event
                  )}},model:{value:(
                  _vm.meeting.options.proxyAppDisplayOptions.home
                    .hideMeetingDocuments
                ),callback:function ($$v) {_vm.$set(_vm.meeting.options.proxyAppDisplayOptions.home
                    , "hideMeetingDocuments", $$v)},expression:"\n                  meeting.options.proxyAppDisplayOptions.home\n                    .hideMeetingDocuments\n                "}})],1),_c('div',[_c('div',{staticClass:"subheading"},[_vm._v("Hide Meeting Documents")]),_c('div',{staticClass:"grey--text text--darken-2"},[_vm._v(" Whether to hide the meeting documents from being downloaded (regardless of verified user) ")])])]),_c('v-layout',[_c('div',{staticClass:"pr-3"},[_c('v-checkbox',{attrs:{"true-value":'simple',"false-value":'default'},on:{"change":function($event){return _vm.onChange('options.proxyAppDisplayOptions.home.mode', $event)}},model:{value:(_vm.meeting.options.proxyAppDisplayOptions.home.mode),callback:function ($$v) {_vm.$set(_vm.meeting.options.proxyAppDisplayOptions.home, "mode", $$v)},expression:"meeting.options.proxyAppDisplayOptions.home.mode"}})],1),_c('div',[_c('div',{staticClass:"subheading"},[_vm._v("Enable Simple Home Page Mode")]),_c('div',{staticClass:"grey--text text--darken-2"},[_vm._v(" Simple mode hides all the meeting details, documents download area, and the text above the start button ")])])]),_c('v-layout',[_c('div',{staticClass:"pr-3"},[_c('v-checkbox',{on:{"change":function($event){return _vm.onChange(
                    'options.proxyAppDisplayOptions.home.simpleRsvp',
                    $event
                  )}},model:{value:(
                  _vm.meeting.options.proxyAppDisplayOptions.home.simpleRsvp
                ),callback:function ($$v) {_vm.$set(_vm.meeting.options.proxyAppDisplayOptions.home, "simpleRsvp", $$v)},expression:"\n                  meeting.options.proxyAppDisplayOptions.home.simpleRsvp\n                "}})],1),_c('div',[_c('div',{staticClass:"subheading"},[_vm._v("Enable Simple RSVP Popup")]),_c('div',{staticClass:"grey--text text--darken-2"},[_vm._v(" Use a simplier RSVP modal popup that does not mention anything about voting and uses a close button instead of a \"Vote Now\" button. ")])])]),_c('v-text-field',{attrs:{"label":"Customize Start Proxy Text","placeholder":"(Default) 'Cast Your Vote Online Now!'"},on:{"input":function($event){return _vm.onChange(
                'options.proxyAppDisplayOptions.home.customizeStartProxyText',
                $event
              )}},model:{value:(
              _vm.meeting.options.proxyAppDisplayOptions.home
                .customizeStartProxyText
            ),callback:function ($$v) {_vm.$set(_vm.meeting.options.proxyAppDisplayOptions.home
                , "customizeStartProxyText", $$v)},expression:"\n              meeting.options.proxyAppDisplayOptions.home\n                .customizeStartProxyText\n            "}}),_c('v-text-field',{attrs:{"label":"Customize submissions closed description text","placeholder":"(Default) 'Please attend the event to cast your vote.'"},on:{"input":function($event){return _vm.onChange(
                'options.proxyAppDisplayOptions.home.customizeSubmissionsClosedDescText',
                $event
              )}},model:{value:(
              _vm.meeting.options.proxyAppDisplayOptions.home
                .customizeSubmissionsClosedDescText
            ),callback:function ($$v) {_vm.$set(_vm.meeting.options.proxyAppDisplayOptions.home
                , "customizeSubmissionsClosedDescText", $$v)},expression:"\n              meeting.options.proxyAppDisplayOptions.home\n                .customizeSubmissionsClosedDescText\n            "}}),_c('v-layout',[_c('v-text-field',{attrs:{"label":"Timezone for displaying meeting date/time","placeholder":"(Default) America/Toronto"},on:{"input":function($event){return _vm.onChange(
                  'options.proxyAppDisplayOptions.home.dateFormat.timezone',
                  $event
                )}},model:{value:(
                _vm.meeting.options.proxyAppDisplayOptions.home.dateFormat
                  .timezone
              ),callback:function ($$v) {_vm.$set(_vm.meeting.options.proxyAppDisplayOptions.home.dateFormat
                  , "timezone", $$v)},expression:"\n                meeting.options.proxyAppDisplayOptions.home.dateFormat\n                  .timezone\n              "}})],1)],1)],1)],1),_c('v-expansion-panel-content',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('span',{staticClass:"body-2"},[_vm._v("Ownership")])]),_c('v-card',[_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Header Text"},on:{"input":function($event){return _vm.onChange(
                'options.proxyAppDisplayOptions.ownership.headerText',
                $event
              )}},model:{value:(
              _vm.meeting.options.proxyAppDisplayOptions.ownership.headerText
            ),callback:function ($$v) {_vm.$set(_vm.meeting.options.proxyAppDisplayOptions.ownership, "headerText", $$v)},expression:"\n              meeting.options.proxyAppDisplayOptions.ownership.headerText\n            "}}),_c('v-text-field',{attrs:{"label":"Snippet"},on:{"input":function($event){return _vm.onChange(
                'options.proxyAppDisplayOptions.ownership.snippet',
                $event
              )}},model:{value:(_vm.meeting.options.proxyAppDisplayOptions.ownership.snippet),callback:function ($$v) {_vm.$set(_vm.meeting.options.proxyAppDisplayOptions.ownership, "snippet", $$v)},expression:"meeting.options.proxyAppDisplayOptions.ownership.snippet"}}),_c('v-text-field',{attrs:{"label":"Address Label"},on:{"input":function($event){return _vm.onChange(
                'options.proxyAppDisplayOptions.ownership.addressLabelText',
                $event
              )}},model:{value:(
              _vm.meeting.options.proxyAppDisplayOptions.ownership
                .addressLabelText
            ),callback:function ($$v) {_vm.$set(_vm.meeting.options.proxyAppDisplayOptions.ownership
                , "addressLabelText", $$v)},expression:"\n              meeting.options.proxyAppDisplayOptions.ownership\n                .addressLabelText\n            "}}),_c('v-text-field',{attrs:{"label":"Unit Label"},on:{"input":function($event){return _vm.onChange(
                'options.proxyAppDisplayOptions.ownership.unitLabelText',
                $event
              )}},model:{value:(
              _vm.meeting.options.proxyAppDisplayOptions.ownership.unitLabelText
            ),callback:function ($$v) {_vm.$set(_vm.meeting.options.proxyAppDisplayOptions.ownership, "unitLabelText", $$v)},expression:"\n              meeting.options.proxyAppDisplayOptions.ownership.unitLabelText\n            "}}),_c('v-text-field',{attrs:{"label":"Name Label"},on:{"input":function($event){return _vm.onChange(
                'options.proxyAppDisplayOptions.contact.nameLabelText',
                $event
              )}},model:{value:(
              _vm.meeting.options.proxyAppDisplayOptions.contact.nameLabelText
            ),callback:function ($$v) {_vm.$set(_vm.meeting.options.proxyAppDisplayOptions.contact, "nameLabelText", $$v)},expression:"\n              meeting.options.proxyAppDisplayOptions.contact.nameLabelText\n            "}}),_c('v-text-field',{attrs:{"label":"Email Label"},on:{"input":function($event){return _vm.onChange(
                'options.proxyAppDisplayOptions.contact.emailLabelText',
                $event
              )}},model:{value:(
              _vm.meeting.options.proxyAppDisplayOptions.contact.emailLabelText
            ),callback:function ($$v) {_vm.$set(_vm.meeting.options.proxyAppDisplayOptions.contact, "emailLabelText", $$v)},expression:"\n              meeting.options.proxyAppDisplayOptions.contact.emailLabelText\n            "}}),_c('v-layout',[_c('div',{staticClass:"pr-3"},[_c('v-checkbox',{attrs:{"true-value":true,"false-value":false},on:{"change":function($event){return _vm.toggleUnverifiedVoting($event)}},model:{value:(
                  _vm.meeting.options.proxyAppDisplayOptions.ownership
                    .disableInputs
                ),callback:function ($$v) {_vm.$set(_vm.meeting.options.proxyAppDisplayOptions.ownership
                    , "disableInputs", $$v)},expression:"\n                  meeting.options.proxyAppDisplayOptions.ownership\n                    .disableInputs\n                "}})],1),_c('div',[_c('div',{staticClass:"subheading"},[_vm._v("Disable Inputs")]),_c('div',{staticClass:"grey--text text--darken-2"},[_vm._v(" Prevents the changing of the ownership input fields thereby disallowing unverified voters from proceeding. ")])])]),_c('v-layout',[_c('div',{staticClass:"pr-3"},[_c('v-checkbox',{attrs:{"true-value":"text","false-value":""},on:{"change":function($event){return _vm.onChange(
                    'options.proxyAppDisplayOptions.ownership.addressInputType',
                    $event
                  )}},model:{value:(
                  _vm.meeting.options.proxyAppDisplayOptions.ownership
                    .addressInputType
                ),callback:function ($$v) {_vm.$set(_vm.meeting.options.proxyAppDisplayOptions.ownership
                    , "addressInputType", $$v)},expression:"\n                  meeting.options.proxyAppDisplayOptions.ownership\n                    .addressInputType\n                "}})],1),_c('div',[_c('div',{staticClass:"subheading"},[_vm._v("Enable text field for addresses")]),_c('div',{staticClass:"grey--text text--darken-2"},[_vm._v(" Customize the input type for addresses. Recommended when there are a lot of addresses. ")])])]),_c('v-layout',[_c('div',{staticClass:"pr-3"},[_c('v-checkbox',{attrs:{"true-value":"text","false-value":""},on:{"change":function($event){return _vm.onChange(
                    'options.proxyAppDisplayOptions.ownership.unitInputType',
                    $event
                  )}},model:{value:(
                  _vm.meeting.options.proxyAppDisplayOptions.ownership
                    .unitInputType
                ),callback:function ($$v) {_vm.$set(_vm.meeting.options.proxyAppDisplayOptions.ownership
                    , "unitInputType", $$v)},expression:"\n                  meeting.options.proxyAppDisplayOptions.ownership\n                    .unitInputType\n                "}})],1),_c('div',[_c('div',{staticClass:"subheading"},[_vm._v("Enable text field for unit field")]),_c('div',{staticClass:"grey--text text--darken-2"},[_vm._v(" Customize the input type for unit field. ")])])])],1)],1)],1),_c('v-expansion-panel-content',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('span',{staticClass:"body-2"},[_vm._v("Is Resident")])]),_c('v-card',[_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Header Text"},on:{"input":function($event){return _vm.onChange(
                'options.proxyAppDisplayOptions.isresident.headerText',
                $event
              )}},model:{value:(
              _vm.meeting.options.proxyAppDisplayOptions.isresident.headerText
            ),callback:function ($$v) {_vm.$set(_vm.meeting.options.proxyAppDisplayOptions.isresident, "headerText", $$v)},expression:"\n              meeting.options.proxyAppDisplayOptions.isresident.headerText\n            "}}),_c('v-text-field',{attrs:{"label":"Snippet"},on:{"input":function($event){return _vm.onChange(
                'options.proxyAppDisplayOptions.isresident.snippet',
                $event
              )}},model:{value:(
              _vm.meeting.options.proxyAppDisplayOptions.isresident.snippet
            ),callback:function ($$v) {_vm.$set(_vm.meeting.options.proxyAppDisplayOptions.isresident, "snippet", $$v)},expression:"\n              meeting.options.proxyAppDisplayOptions.isresident.snippet\n            "}})],1)],1)],1),_c('v-expansion-panel-content',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('span',{staticClass:"body-2"},[_vm._v("Communications")])]),_c('v-card',[_c('v-card-text',[_c('v-layout',[_c('div',{staticClass:"pr-3"},[_c('v-checkbox',{attrs:{"true-value":true,"false-value":false},on:{"change":function($event){return _vm.onChange('options.disableComms', $event)}},model:{value:(_vm.meeting.options.disableComms),callback:function ($$v) {_vm.$set(_vm.meeting.options, "disableComms", $$v)},expression:"meeting.options.disableComms"}})],1),_c('div',[_c('div',{staticClass:"subheading"},[_vm._v("Disable Comms")]),_c('div',{staticClass:"grey--text text--darken-2"},[_vm._v(" If checked, owners will not be asked for their e-consent ")])])])],1)],1)],1),_c('v-expansion-panel-content',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('span',{staticClass:"body-2"},[_vm._v("Review")])]),_c('v-card',[_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Ownership Label"},on:{"input":function($event){return _vm.onChange(
                'options.proxyAppDisplayOptions.review.ownershipLabelText',
                $event
              )}},model:{value:(
              _vm.meeting.options.proxyAppDisplayOptions.review.ownershipLabelText
            ),callback:function ($$v) {_vm.$set(_vm.meeting.options.proxyAppDisplayOptions.review, "ownershipLabelText", $$v)},expression:"\n              meeting.options.proxyAppDisplayOptions.review.ownershipLabelText\n            "}}),_c('v-layout',{attrs:{"row":""}},[_c('div',{staticClass:"pr-3"},[_c('v-checkbox',{attrs:{"true-value":true,"false-value":false},on:{"change":function($event){return _vm.onChange(
                    'options.proxyAppDisplayOptions.review.hideChangeLinks',
                    $event
                  )}},model:{value:(
                  _vm.meeting.options.proxyAppDisplayOptions.review
                    .hideChangeLinks
                ),callback:function ($$v) {_vm.$set(_vm.meeting.options.proxyAppDisplayOptions.review
                    , "hideChangeLinks", $$v)},expression:"\n                  meeting.options.proxyAppDisplayOptions.review\n                    .hideChangeLinks\n                "}})],1),_c('div',[_c('div',{staticClass:"subheading"},[_vm._v("Hide Change Links")]),_c('div',{staticClass:"grey--text text--darken-2"},[_vm._v(" Determines whether the \"change\" link beside each review question is shown. This link takes the voter back to the question to change their answer. Default is FALSE. ")])])])],1)],1)],1),_c('v-expansion-panel-content',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('span',{staticClass:"body-2"},[_vm._v("Legal / Confirm")])]),_c('v-card',[_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Header Text"},on:{"input":function($event){return _vm.onChange(
                'options.proxyAppDisplayOptions.confirm.headerText',
                $event
              )}},model:{value:(
              _vm.meeting.options.proxyAppDisplayOptions.confirm.headerText
            ),callback:function ($$v) {_vm.$set(_vm.meeting.options.proxyAppDisplayOptions.confirm, "headerText", $$v)},expression:"\n              meeting.options.proxyAppDisplayOptions.confirm.headerText\n            "}}),_c('v-text-field',{attrs:{"label":"Snippet"},on:{"input":function($event){return _vm.onChange(
                'options.proxyAppDisplayOptions.confirm.snippet',
                $event
              )}},model:{value:(_vm.meeting.options.proxyAppDisplayOptions.confirm.snippet),callback:function ($$v) {_vm.$set(_vm.meeting.options.proxyAppDisplayOptions.confirm, "snippet", $$v)},expression:"meeting.options.proxyAppDisplayOptions.confirm.snippet"}})],1)],1)],1),_c('v-expansion-panel-content',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('span',{staticClass:"body-2"},[_vm._v("Done")])]),_c('v-card',[_c('v-card-text',[_c('v-layout',{attrs:{"row":""}},[_c('div',{staticClass:"pr-3"},[_c('v-checkbox',{attrs:{"true-value":true,"false-value":false},on:{"change":function($event){return _vm.onChange(
                    'options.proxyAppDisplayOptions.done.showStartAgain',
                    $event
                  )}},model:{value:(
                  _vm.meeting.options.proxyAppDisplayOptions.done.showStartAgain
                ),callback:function ($$v) {_vm.$set(_vm.meeting.options.proxyAppDisplayOptions.done, "showStartAgain", $$v)},expression:"\n                  meeting.options.proxyAppDisplayOptions.done.showStartAgain\n                "}})],1),_c('div',[_c('div',{staticClass:"subheading"},[_vm._v("Show Start Again Button")]),_c('div',{staticClass:"grey--text text--darken-2"},[_vm._v(" Toggle hiding of the \"Start again\" content and button. Defaults to TRUE. ")])])])],1)],1)],1),_c('v-expansion-panel-content',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('span',{staticClass:"body-2"},[_vm._v("Other")])]),_c('v-card',[_c('v-card-text',[_c('v-layout',[_c('div',{staticClass:"pr-3"},[_c('v-checkbox',{attrs:{"true-value":true,"false-value":false},on:{"change":function($event){return _vm.onChange('options.allowNoInstructions', $event)}},model:{value:(_vm.meeting.options.allowNoInstructions),callback:function ($$v) {_vm.$set(_vm.meeting.options, "allowNoInstructions", $$v)},expression:"meeting.options.allowNoInstructions"}})],1),_c('div',[_c('div',{staticClass:"subheading"},[_vm._v("Allow No Instructions")]),_c('div',{staticClass:"grey--text text--darken-2"},[_c('li',[_vm._v(" Allow an owner to set the `No Instructions for proxy` option for Bill 106 proxies ")]),_c('li',[_vm._v(" If TRUE, the \"No Instructions\" option will be set if the voter abstains on all matters ")]),_c('li',[_vm._v(" If FALSE, the \"Yes, Instructions\" option will be set (ie. business as usual). ")])])])])],1)],1)],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-3 my-4"},[_c('div',{staticClass:"title grey--text text--darken-4 mb-1"},[_vm._v("Custom Options")]),_c('div',{staticClass:"body-1 grey--text text--darken-2 mb-2"},[_vm._v(" Specifies custom display options for the Proxy App. Each property corresponds to a \"page\" in the Proxy App. ")])])
}]

export { render, staticRenderFns }